import auth0 from "auth0-js";
import { Component } from "react";
import CommonValues from "../Common/Util";

//***************** Auth0  *************/
export class Auth extends Component {
  auth0 = new auth0.WebAuth({
    domain: "participicqa.us.auth0.com",
    clientID: "72kcE7CC1NGBo2GMsm7KxO04TwQcFQPT",
    redirectUri: "https://www.qa.participic.com/loggingin",
    responseType: "token",
    scope: "openid profile email",
    audience: "https://participicqa.us.auth0.com/api/v2/",
  });

  // To authorize the user to Auth0 page
  login = () => {
    this.auth0.authorize((err, res) => {});
  };

  //To handle authentication when user logged in or sign up
  handleAuth = () => {
    var access_token = localStorage.getItem("access_token");
    if (
      (access_token != null && access_token != undefined) ||
      window.location.href.includes("appstore")
    ) {
    } else {
      this.login();
    }
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken) {
        this.setSession(authResult);
        this.getCustomClaims(authResult.accessToken);
      } else if (err) {
        console.log("Its failing at HandleAuth" + err.code);
      }
    });
  };

  getCustomClaims = (authToken) => {
    var axios = require("axios");
    var config = {
      method: "post",
      url: process.env.REACT_APP_API + `User/Auth0Authenticate`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios(config)
      .then((response) => {
        CommonValues.SetValues(
          response.data.UserId,
          response.data.FirstName,
          response.data.LastName,
          response.data.RoleId,
          response.data.RoleName,
          response.data.ImageURL
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Setting up a session when user logged in
  setSession = (authResult) => {
    //set the time the access token will expire
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem("access_token", authResult.accessToken);
    //localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  };

  //Authenticate user by time
  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };

  //To logout from the session and remove the local storage values
  logout = () => {
    this.auth0.logout({
      clientID: "72kcE7CC1NGBo2GMsm7KxO04TwQcFQPT",
      returnTo: "https://www.qa.participic.com",
    });
  };

  //To get the access token sent by Auth0
  getAccessToken = () => {
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      throw new Error("No access token found");
    }
    return accessToken;
  };
}

export default new Auth();
