import React, { Component } from "react";
import { withRouter } from "react-router";
// import { Checkbox } from "primereact/checkbox";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";
import Auth from "../Auth/Auth";
import CommonValues from "../Common/Util";
import UserInformation from "../User/UserInformation";
class Login1 extends Component {
  constructor(props) {
    super(props);
    this.auth = Auth;
    this.state = {};
  }
  componentDidMount() {
    console.log("login");
    document.body.classList.remove("dashboard-page");
    document.body.classList.add("login-page");

    var access_token = localStorage.getItem("access_token");
    if (access_token !== null && access_token !== undefined) {
      this.getCustomClaims(access_token);

      //this.props.history.push("/dashboard");
    } else {
      this.auth.login();
    }
  }
  editClickHandler = (id, val) => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetUserDetails?id=${id}`,
    };

    axios(config)
      .then((response) => {
        this.setState({
          UserData: response.data,
          Loading: false,
          Mode: "E",
          showModal: val,
          EditClickUserId: id,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while getting users detail by id",
        });
        if ((error.response.status = 401)) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  getCustomClaims = (authToken) => {
    var axios = require("axios");
    var config = {
      method: "post",
      url: process.env.REACT_APP_API + `User/Auth0Authenticate`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    axios(config)
      .then((response) => {
        CommonValues.SetValues(
          response.data.UserId,
          response.data.FirstName,
          response.data.LastName,
          response.data.RoleId,
          response.data.RoleName,
          response.data.ImageURL
        );

        let id = response.data.UserId;
        if (response.data.MobileNo === null || response.data.DOB === null || response.data.StateId == null || response.data.CountryId === null) {
          this.editClickHandler(id, true);
        } else {
          window.location.pathname = "/dashboard";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <>
        <main class="main-content">
          <div
            className="table-loading"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              transform: "translate(-50%, -50%)",
              fontFamily: "primeicon",
              fontSize: "16px",
              fontWeight: "700px",
              color: "black",
            }}
          >
            <Spinner animation="border" variant="primary" />
            <b> Logging in</b>
            <Modal show={this.state.showModal} dialogClassName="modal-dialog modal-dialog-centered">
              <UserInformation
                EditClickUserId={this.state.EditClickUserId}
                onCloseClick={this.onCloseClick}
                onRefreshClick={this.onRefreshClick}
                Mode={this.state.Mode}
                UserData={this.state.UserData}
              />
            </Modal>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(Login1);
