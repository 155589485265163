import axios from "axios";
import { ContentState, convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { isIOS } from "react-device-detect";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactFileReader from "react-file-reader";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import validator from "validator";
import CommonValues from "../Common/Util";
import ScannerCode from "./ScannerCode";

import BlotFormatter from "quill-blot-formatter";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module-react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
var Font = Quill.import("formats/font");
Font.whitelist = ["Roboto", "Raleway", "Montserrat", "Lato", "Rubik", "Arial", "Helvetica", "sans-serif", "serif", "Monospace"];
Quill.register(Font, true);
Quill.register("modules/imageDrop", ImageDrop);

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register("modules/imageResize", ImageResize);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
    [{ size: [] }],
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
      {
        background: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    [{ script: "super" }, { script: "sub" }],
    ["link", "image"],
    ["clean"],
  ],
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize"],
  },
  blotFormatter: {},
  imageDrop: true,
};
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "color",
  "background",
  "link",
  "image",
  "video",
  "clean",
];
class SendEmail extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      entityMap: {
        0: {
          type: "IMAGE",
          mutability: "IMMUTABLE",
          data: {
            src: `https://api.qrserver.com/v1/create-qr-code/?data=${localStorage
              .getItem("PromoCode")
              .replace(/^"|"$/g, "")}&size=200x200&bgcolor=ffffff`,
          },
        },
      },
      blocks: [
        {
          key: "9gm3s",
          text: `Welcome to Participic , ${localStorage
            .getItem("UserName")
            .replace(
              /^"|"$/g,
              ""
            )}&nbsp; would like you to join and share photo’s exclusive to this event on Participic. Only guests at the event can share photos and videos. Download the app at the Link below and welcome to the Party.Event Code :- ${localStorage
            .getItem("PromoCode")
            .replace(/^"|"$/g, "")}.<a href=${process.env.SEND_EMAIL_LINK}>Eventlink</a>
          
          <br/>
          ${this.qrcodeTemplate}`,
          type: "styled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "ov7r",
          text: " ",
          type: "atomic",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: 1,
              key: 0,
            },
          ],
          data: {},
        },
      ],
    };
    this.state = {
      EventId: 0,
      EmailAddress: "",
      Subject: `${localStorage.getItem("UserName").replace(/^"|"$/g, "")} invites you to join us on ${localStorage
        .getItem("EventName")
        .replace(/^"|"$/g, "")}`,
      body: `<h1 style='text-align:center'><img src=${
        process.env.REACT_APP_PARTICIPIC_IMAGE
      } alt='' style='margin: 0 auto;display: flex;max-width:100%'/> </h1>
      <h1 style='text-align:center;'>&nbsp;<span style='color: rgb(252,144,5);background-color: rgb(255,255,255);text-align:center;display:flex;font-size: 26px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Welcome To Participic</span></h1> 
      <p style='text-align:left;'>&nbsp;</p> 
      <p style='text-align:left; line-height:2.9px',margin-top><span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px; text-align:center; font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;line-height:2px !important'>${localStorage
        .getItem("UserName")
        .replace(
          /^"|"$/g,
          ""
        )}&nbsp; would like you to join and share photo’s exclusive to this event on Participic. Only guests at the event can share photos and videos. Download the app at the Link below and welcome to the Party.Event Code :- ${localStorage
        .getItem("PromoCode")
        .replace(/^"|"$/g, "")}.<a href=${process.env.SEND_EMAIL_LINK}>Eventlink</a>
        
        <br/>
        ${this.qrcodeTemplate}`,
      ModelTitle: "",
      ToggleSideBar: true,
      EditorDescriptionState: EditorState.createWithContent(convertFromRaw(initialState)),
      editorState: EditorState.createEmpty(),
      bodyData: "",
      EventDashboardValue: 5,
      Loading: false,
      Loadingpdf: false,
      ErrorMessage: "",
      SuccessMessage: "",
      displaySuccessMessage: false,
      tags: [],
      tag: "",
      placeholder: "Add email",
      headers: [],
      CSVData: [],
      qrCode: "",
      showScannerCodeModal: false,
      uploadedImages: [],
      shortLink: "",
    };
    this.csvLinkEl = React.createRef();
    this.importCSV = this.importCSV.bind(this);
    this.tagsElement = React.createRef();
    this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
    this.handlebodyChange = this.handlebodyChange.bind(this);
  }

  // GenerateDeepLink = (promoCode, eventId) => {
  //   let config = {
  //     method: "post",
  //     url: `${process.env.REACT_APP_EVENT_API}Event/GenerateDynamicLink?PromoCode=${promoCode}&EventId=${eventId}`,
  //     headers: {},
  //   };

  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log(JSON.stringify(response.data.shortLink));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  componentDidMount = () => {
    // this.GenerateDeepLink(
    //   localStorage.getItem("PromoCode"),
    //   localStorage.getItem("EventId")
    // );
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_EVENT_API}Event/GenerateDynamicLink?PromoCode=${localStorage.getItem("PromoCode")}&EventId=${localStorage.getItem(
        "EventId"
      )}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        this.setState({ shortLink: response.data.shortLink }, () => {
          const eventLink = `${process.env.REACT_APP_SEND_EMAIL_LINK}${localStorage.getItem("EventId").replace(/^"|"$/g, "")}`;
          // const androidPromocodeRedirectURL =
          //   process.env.REACT_APP_PROMOCODEURL +
          //   `${localStorage.getItem("PromoCode")}/${localStorage.getItem("EventId")}`;

          // const androidPromocodeRedirectURL = `https://mediaparticipic.page.link/qrscan?promocode=${localStorage.getItem(
          //   "PromoCode"
          // )}/eventid=${localStorage.getItem("EventId")}`;
          //let androidAppLink = shortLink;
          // const iosAppLink = `https://mediaparticipic.page.link/qrscan?promocode=${localStorage.getItem(
          //   "PromoCode"
          // )}/eventid=${localStorage.getItem("EventId")}`;
          //let iosAppLink = shortLink;
          const eventname = `${localStorage.getItem("EventName").replace(/^"|"$/g, "")}`;

          // var NewPromoCode = `${localStorage.getItem("PromoCode")}`;
          // NewPromoCode = NewPromoCode.replace(/\&/g, "%26");
          // let newQRValue = `https://mediaparticipic.page.link/qrscan?promocode=${NewPromoCode}/eventid=${localStorage.getItem(
          //   "EventId"
          // )}`;

          const qrvalue = `https://api.qrserver.com/v1/create-qr-code/?data=${this.state.shortLink}`;
          //   `https://api.qrserver.com/v1/create-qr-code/?data=` +
          //   process.env.REACT_APP_PROMOCODEURL +
          //   `${localStorage.getItem("PromoCode")}/${localStorage.getItem("EventId")}`;

          // const thanks = "Thanks,<br/>Participic Team";
          const html1 = `
           <h1 style='text-align:center'><img src=${
             process.env.REACT_APP_PARTICIPIC_IMAGE
           } alt='' style='margin: 0 auto;display: flex;max-width:100%'/> </h1>
           <h1 style='text-align:center;'>&nbsp;<span style='color: rgb(252,144,5);text-align:center;display:flex;font-size: 26px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Welcome To Participic</span></h1> 
           <p style='text-align:left;'>&nbsp;</p> 
           <p style='text-align:left; line-height:2px'><span style='color: rgb(0,0,0);text-align:center; font-size: 18px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;line-height:2px !important'>${localStorage
             .getItem("UserName")
             .replace(/^"|"$/g, "")} &nbsp; would like you to join and share photo’s exclusive to <strong>${localStorage
            .getItem("EventName")
            .replace(
              /^"|"$/g,
              ""
            )}</strong> on Participic. Only guests at the event can share photos and videos. Download the app at the Link below and welcome to the Party.</span><span style='font-size: 18px;'> </span></p> <p><span style='background-color: rgb(255,255,255); text-align:center;font-size: 16px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Event Code : </span><span style='background-color: rgb(255,255,255); text-align:center;font-size: 16px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>${localStorage
            .getItem("PromoCode")
            .replace(
              /^"|"$/g,
              ""
            )}</span>&nbsp;</p> <h3><a href='#' target='_self'><span style='color: rgb(0,123,255);background-color: rgb(255,255,255);font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'><a href='${eventLink}'>${eventname}</a></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3> <p style='text-align:center;'>&nbsp;</p> <center><p><img src='${qrvalue}' alt='undefined' style='height: auto;width: auto;margin:0 auto;display:flex;'/></p></center> <p style='text-align:left;'>&nbsp;</p> <p style='margin-left:0px;'><span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Thanks,                          </span>&nbsp;</p> <p style='margin-left:0px;'><span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'><strong>Participic Team</strong></span><strong>   </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> 
            <span></span>`;
          // const html =
          //   `Welcome to Participic , ${localStorage
          //     .getItem("UserName")
          //     .replace(
          //       /^"|"$/g,
          //       ""
          //     )} &nbsp;would like you to join and share photo’s exclusive to ${eventname} on Participic. Only guests at the event can share photos and videos. Download the app at the Link below and welcome to the Party.Event Code :- ${localStorage
          //     .getItem("PromoCode")
          //     .replace(/^"|"$/g, "")}.<br><a href='${eventLink}'>${eventname}</a>` +
          //   `<p></p><img src="${qrvalue}" alt="undefined" style="float:left;height: auto;width: auto"/><p></p>` +
          //   `<p></p>${thanks}<p></p>`;
          const contentBlock = htmlToDraft(html1);
          if (contentBlock) {
            // const blocksFromHTML = convertFromHTML(html1);
            // const text = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
              EditorDescriptionState: editorState,
            });
          }

          this.setState({
            ModelTitle: `Event invitation - ${localStorage.getItem("EventName").replace(/^"|"$/g, "")}`,
            body: `<h1 style='text-align:center !important;'><img src=${
              process.env.REACT_APP_PARTICIPIC_IMAGE
            } alt='' data-align="center" style="text-align:center;"/></h1>
<h1 style="text-align:center !important; width:100% !important; display:block !important;">
      <span style="color: rgb(252,144,5);text-align:center !important;display:block !important;width:100% !important;font-size:26px;font-family: Arial,
       'Helvetica Neue', Helvetica, sans-serif;">Welcome To Participic</span></h1>
        <p style='text-align:left;'>&nbsp;</p>
         <p style='text-align:left;line-height: 2px,margin-bottom:2px;><span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;line-height:2px'>${localStorage
           .getItem("UserName")
           .replace(/^"|"$/g, "")}&nbsp;would like you to join and share photo’s exclusive to <strong>${localStorage
              .getItem("EventName")
              .replace(
                /^"|"$/g,
                ""
              )}</strong> on Participic. Only guests at the event can share photos and videos. Download the app at the Link below and welcome to the Party.</span><span style='font-size: 18px;'> </span></p> <p><span style='background-color: rgb(255,255,255);font-size: 16px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Event Code : </span><span style='background-color: rgb(255,255,255);font-size: 16px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>${localStorage
              .getItem("PromoCode")
              .replace(
                /^"|"$/g,
                ""
              )}</span>&nbsp;</p> <h3><a href='#' target='_self'><span style='color: rgb(0,123,255);background-color: rgb(255,255,255);font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'><a href='${
              this.state.shortLink
            }'>Android App</a></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
              <h3><a href='#' target='_self'><span style='color: rgb(0,123,255);background-color: rgb(255,255,255);font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'><a href='${
                this.state.shortLink
              }'>IOS App</a></span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h3>
      <p style='text-align:center;'> <center><p style='text-align:center;'><h5 class="qr-center"><img src='${qrvalue}' alt='undefined' style='height:auto; width: auto ;margin:0 auto;display:flex;'/></h5></p></center> </p> 
      <p style='text-align:left;'>&nbsp;</p> <p style='margin-left:0px;'>
      <span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'>Thanks,</span>&nbsp;</p> 
      <p style='margin-left:0px;'><span style='color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 14px;font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;'><strong>Participic Team</strong></span><strong>   
      </strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> 
      <span></span>`,
            qrCode:
              `http://api.qrserver.com/v1/create-qr-code/?data=${this.state.shortLink}` +
              // ${localStorage
              //   .getItem("UserName")
              //   .replace(/^"|"$/g, "")}&size=${this.state.size}x${
              //   this.state.size
              // }&bgcolor=${this.state.bgColor}`
              `<br/><P>Thanks, <br/>Participic Team</P>`,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      const inputElement = document.getElementsByClassName("react-tagsinput-input")[0];
      inputElement.placeholder = "Enter email";
    }, 0);
  };
  onEditorStateChange = (EditorState) => {
    this.setState({
      EditorState,
    });
  };
  onEditorkeyDown = (e) => {
    if (e.keyCode === 8) {
      console.log("delete");
    }
  };
  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };
  importCSV(e) {
    // const file = e.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result;
      const data = csv.split("\n");

      // Prepare DataTable
      // const cols = data[0].replace(/['"]+/g, "").split(",");
      data.shift();

      // let importedCols = cols.map((col) => ({
      //   field: col,
      //   header: this.toCapitalize(col.replace(/['"]+/g, "")),
      // }));
      // let importedData = data.map((d) => {
      //   d = d.split(",");
      //   return cols.reduce((obj, c, i) => {
      //     obj[c] = d[i].replace(/['"]+/g, "");
      //     return obj;
      //   }, {});
      // });
      this.setState({});
    };
  }
  handleChange = (e) => {
    this.setState({ tags: e });
  };
  EmailValidate = (emailList) => {
    let ismail = true;
    emailList.forEach((element) => {
      if (!validator.isEmail(element)) {
        this.setState({
          ErrorMessage: "Please enter valid email",
          SuccessMessage: "",
        });
        ismail = false;
      }
    });
    return ismail;
  };

  validate = () => {
    // let valid = true;
    let EditorDescription = draftToHtml(convertToRaw(this.state.EditorDescriptionState.getCurrentContent()));
    if (this.state.EmailAddress === "" && this.state.Subject === "" && this.state.EditorDescriptionState === "") {
      this.setState({
        ErrorMessage: "Please enter all details",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.tags.length === 0) {
      this.setState({
        ErrorMessage: "Please specify at least one recipient.",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.Subject === "") {
      this.setState({
        ErrorMessage: "Please enter subject",
        SuccessMessage: "",
      });
      return false;
    }
    if (EditorDescription.trim() === "<p></p>") {
      this.setState({
        ErrorMessage: "Please enter body",
        SuccessMessage: "",
      });
      return false;
    }
    if (this.state.tags.length > 0) {
      let ismail = true;
      this.state.tags.forEach((element) => {
        if (!validator.isEmail(element)) {
          this.setState({
            ErrorMessage: "Please enter valid email",
            SuccessMessage: "",
          });
          ismail = false;
        }
      });
      return ismail;
    }
  };

  // onClick() {
  //   this.setState({
  //     displaySuccessMessage: true,
  //   });
  // }

  onSendEmailInvitation = () => {
    if (this.validate()) {
      this.setState({
        Loading: true,
      });
      this.setState({
        ErrorMessage: "",
      });
      const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
      if (this.validate()) {
        this.setState({
          Loading: true,
          LoadingMessage: "Saving Event",
        });
        let htmlBoady = this.state.body;
        let htmlBoadyNew = htmlBoady.replace("<h1>", '<h1 style="text-align:center !important;">');
        var re = /<img[^>]+src=\/\/([^">]+)/g;
        var m;
        while ((m = re.exec(htmlBoadyNew))) {
          for (let i = 0; i < htmlBoadyNew.split("<img").length - 1; i++) {
            let position = htmlBoady.indexOf("<img");

            if (position > 0) {
              let s = htmlBoady.substring(position);
              let position1 = s.indexOf("/>") + position + 2;
              let Replaceimg = htmlBoady.substring(position, position1);
              htmlBoadyNew = htmlBoady.replace(Replaceimg, `<div style="text-align:center;">${Replaceimg} </div>`);
              htmlBoady = htmlBoady.replace(Replaceimg, "");
            }
          }
        }
        let EventId = localStorage.getItem("EventId").replace(/^"|"$/g, "");
        var data = JSON.stringify({
          EventId: parseInt(EventId),
          EmailAddress: this.state.tags.join(","),
          Subject: this.state.Subject,
          body: htmlBoadyNew,
        });
        var config = {
          method: "post",
          url: process.env.REACT_APP_EVENT_API + `Event/SendEmailInvitation`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            this.onClick();
            this.setState({
              Loading: false,
              SuccessMessage: "Event invitation sent successfully..!!!",
              ErrorMessage: "",
              EmailAddress: "",
              Subject: "",
            });
          })
          .catch((error) => {
            if (error.response.status === 401) {
              window.location.pathname = "/logout";
            } else {
              this.setState({
                Loading: false,
              });
            }
          });
      }
    }
  };
  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }
  onHide() {
    this.onemailcloseclick();
  }

  onemailcloseclick = () => {
    this.props.onCloseClick();

    this.setState({
      displaySuccessMessage: false,
    });
  };

  renderFooter() {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => this.onHide()} className="btn myProfile-btn text-16 font-semibold" />
      </div>
    );
  }

  onCloseClick = (val) => {
    if (val === 1) {
      this.props.onCloseClick();
    }
    this.setState({
      showScannerCodeModal: false,
    });
  };

  onCloseClickScannerCodeModal = () => {
    this.setState({
      showScannerCodeModal: false,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      EditorState: editorState,
    });
  };

  onDescriptionEditorStateChange = (editorState) => {
    this.setState({
      EditorDescriptionState: editorState,
    });
  };
  handleFiles = (files) => {
    var tagVal = [];
    var reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = function (e) {
      reader.result
        .toString()
        .split(",")
        .forEach((val) => {
          val = val.replace(/(?:\\[rn]|[\r\n]+)+/g, "");
          tagVal.push(val.toString());
        });
    };

    this.setState({
      tags: tagVal,
    });
  };

  handleFiles1 = (files) => {
    var reader = new FileReader();
    var csvtaglist = [];
    reader.readAsText(files[0]);
    reader.onload = (e) => {
      var csv = reader.result;
      var lines = csv.split("\n");
      var result = [];
      const demoList = [];
      var headers = lines[0].split(",");
      headers[0] = headers[0].replace(/(?:\\[rn]|[\r\n]+)+/g, "");
      if (headers[0] === "Email") {
        for (var i = 1; i < lines.length; i++) {
          var obj = {};
          var currentline = lines[i].split(",");
          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }
          result.push(obj);
        }

        result = JSON.stringify(result);

        var newresult = JSON.parse(result); //JSON
        newresult.forEach((element) => {
          if (element.Email !== "") {
            demoList.push(element);
          }
        });
        csvtaglist = demoList.map((data) => {
          return data.Email.replace("\r", "");
        });

        this.setState({
          tags: csvtaglist,
          ErrorMessage: "",
        });
      } else {
        this.setState({
          ErrorMessage: "Sample file format does not matched",
        });
      }
    };
  };

  uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", "Client-ID 2b04d8d06a0d541");
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }
  onPDFClick = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    this.setState({
      Loadingpdf: true,
    });
    let htmlBoady = this.state.body;
    this.setState({
      Loadingpdf: true,
    });
    let htmlBoadyNew = htmlBoady.replace("<h1>", '<h1 style="text-align:center !important;">');
    let EventId = localStorage.getItem("EventId").replace(/^"|"$/g, "");
    var data = JSON.stringify({
      EventId: parseInt(EventId),
      EmailAddress: this.state.tags.join(","),
      Subject: this.state.Subject,
      body: htmlBoadyNew,
      FileName: localStorage.getItem("EventName") + ".pdf",
    });
    let ChangingURL = `${process.env.REACT_APP_MEDIA_API}`;
    var config = {
      method: "post",
      url: `${ChangingURL}api/Event/ExportToEmailInvitation`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.setState({
          Loadingpdf: false,
        });
        if (isIOS) {
          window.location.href = response.data;
          window.location.target = "_blank";
        } else {
          const url = response.data;
          const link = document.createElement("a");
          link.href = url;
          const FileName = localStorage.getItem("EventName") + ".pdf";
          link.setAttribute("download", `${FileName}`);
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();

          setTimeout(() => {
            link.parentNode.removeChild(link);
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            Loadingpdf: false,
          });
        }
      });
  };

  _uploadImageCallBack(file) {
    let uploadedImages = this.state.uploadedImages;

    const imageObject = {
      file: file,
      localSrc: URL.createObjectURL(file),
    };

    uploadedImages.push(imageObject);

    this.setState({ uploadedImages: uploadedImages });
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  }
  renderHeader() {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  }
  handlebodyChange(html) {
    this.setState({ body: html });
  }

  render() {
    // const header = this.renderHeader();
    // const decorator = new CompositeDecorator([]);

    return (
      <div>
        <Dialog
          header="Confirmation"
          draggable={false}
          resizable={false}
          visible={this.state.displaySuccessMessage}
          footer={this.renderFooter()}
          onHide={() => this.onHide()}
        >
          <p>{this.state.SuccessMessage}</p>
        </Dialog>

        <Modal.Header>
          <Modal.Title>{this.state.ModelTitle}</Modal.Title>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              this.onCloseClick(1);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <div className="">
          <div className="create-event-wrapper send-email-popup mt-1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 ">
                  <div className=" pb-4">
                    <div className="create-event-details d-flex"></div>
                    <div className="col-lg-12 mt-3 d-flex justify-content-end align-items-center ">
                      <>
                        <a className="download-link-csv" href={process.env.REACT_APP_IMPORT_CSV_URL} download="importemail.csv">
                          {" "}
                          Sample CSV{" "}
                        </a>
                        <ReactFileReader fileTypes={[".csv", ".zip"]} handleFiles={this.handleFiles1}>
                          <button className="btn p-button-primary text-11">Import CSV</button>
                        </ReactFileReader>
                      </>
                    </div>
                    <div className="mb-3 col-lg-12">
                      <label className="form-label text-14 font-semibold text-grey">
                        To <span style={{ color: "red" }}>*</span>
                      </label>
                      <TagsInput
                        ref={this.tagsElement}
                        value={this.state.tags}
                        onChange={this.handleChange}
                        inputValue={this.state.tag}
                        addOnBlur={true}
                      />
                    </div>
                    <div className="mb-3 col-lg-12">
                      <label className="form-label text-14 font-semibold text-grey ">
                        Subject <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control text-14 text-dark font-semibold subject-shadow"
                        id="Subject"
                        name="Subject"
                        placeholder="Enter subject"
                        onChange={this.onChange}
                        value={this.state.Subject}
                      />
                    </div>
                    <div>
                      <div className="mb-3 col-lg-12 editor">
                        <label className="form-label text-14 font-semibold text-grey">
                          Body <span style={{ color: "red" }}>*</span>
                        </label>
                        <ReactQuill
                          theme={"snow"}
                          onChange={this.handlebodyChange}
                          value={this.state.body}
                          modules={modules}
                          formats={formats}
                          bounds={".app"}
                        />
                      </div>
                      <br />
                    </div>

                    <div className="row">
                      <div className="col-md-3 col-12 mobileexport">
                        <Button
                          label="Export to PDF"
                          id="CreateButton"
                          loading={this.state.Loadingpdf}
                          icon="pi pi-file text-14"
                          className="p-button p-component p-button-primary mr-1 text-11"
                          onClick={this.onPDFClick}
                        ></Button>
                      </div>

                      <div className="col-md-9 col-12 d-flex justify-content-end">
                        <Button
                          label="Send"
                          loading={this.state.Loading}
                          className="btn btn-primary add-event-btn text-13 font-semibold mr-2"
                          onClick={this.onSendEmailInvitation}
                        ></Button>
                        <Button
                          label="Close"
                          className="btn Create-user-cancle-btn text-13 font-semibold bg-lightblue text-button-grey"
                          onClick={() => {
                            this.onCloseClick(1);
                          }}
                        ></Button>
                      </div>
                      <div className="col-0 col-md-8 mt-3"></div>
                      <div className="col-12 col-md-4 mt-3">
                        <div className="">
                          {this.state.ErrorMessage !== "" ? (
                            <>
                              <div
                                class="alert alert-danger d-flex align-items-center usermanagementalertmessage justify-content-center"
                                role="alert"
                              >
                                <div className="text-14">
                                  <span className="ml-1">{this.state.ErrorMessage}</span>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1"></div>
            </div>
            <div className="col-lg-4 "></div>
          </div>
        </div>
        <Modal className="event-modal" show={this.state.showScannerCodeModal} onHide={this.onCloseClick}>
          <ScannerCode
            className="barcode-popup"
            html1={localStorage.getItem("html1")}
            PromoCode={localStorage.getItem("PromoCode")}
            EventName={localStorage.getItem("EventName")}
            bodyData={this.state.bodyData}
            onCloseClick={this.onCloseClickScannerCodeModal}
          />
        </Modal>
      </div>
    );
  }
}
export default SendEmail;
