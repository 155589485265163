import axios from "axios";
import React, { Component } from "react";
import "react-slideshow-image/dist/styles.css";
import CommonValues from "../Common/Util";

class PhotoViewer extends Component {
  constructor(props) {
    super(props);
    this.sliderInterval = null;
    this.state = {
      EventPhotoDetails: [],
      PhotoObject: {},
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const eventId = params.eventid;
    const EventPhotoId = params.id;
    this.getAllApprovedPhotosByEventId(eventId, EventPhotoId);
  }

  getAllApprovedPhotosByEventId = (eventId, EventPhotoId) => {
    //
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetEventPhotoByEventIdAndEventPhotoId?eventid=${eventId}&&EventPhotoId=${EventPhotoId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          PhotoObject: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  tooglePhotoViewerPopup = (val) => {
    this.props.tooglePhotoViewerPopup(val);
  };

  render() {
    // let showOnlyImage = this.state.showOnlyImage;
    // const slideprops = {
    //   autoplay: true,
    //   duration: 5000,
    //   pauseOnHover: false,
    // };
    // if (this.state.EventPhotoDetails.length === 1) {
    //   showOnlyImage = true;
    // }
    return (
      <div className="popupmain-slider" style={{ backgroundColor: "black" }}>
        <div class="fullscreen-btn"></div>
        <div className="each-slide each-pop-slider">
          <div className="sliderpopup">
            <div className="each-slide each-pop-slider">
              <img class="img-responsive approved-photo popup-slider" src={this.state.PhotoObject.highresolution} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PhotoViewer;
