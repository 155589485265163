import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import "../src/assets/css/responsive.css";
// import ChangePassword from "./AccountManagement/ChangePassword";
import ConfirmRegistration from "./AccountManagement/ConfirmRegistration";
import ForgotPassword from "./AccountManagement/ForgotPassword";
import Login from "./AccountManagement/Login";
import UpdatePassword from "./AccountManagement/UpdatePassword";
import UserRegistration from "./AccountManagement/UserRegistration";
import PageNotFound from "./Common/PageNotFound";
import ProtectedRoute from "./Common/ProtectedRoute";
import Dashboard from "./Dashboard/Dashboard";
import UserInformation from "./User/UserInformation"
import AddUserEvent from "./Event/AddUserEvent";
import AddUserEventMobile from "./Event/AddUserEventMobile";
import appstore from "./Event/appstore";
import PhotoSlider from "./Event/PhotoSlider";
import PhotoViewer from "./Event/PhotoViewer";
import ScannerCode from "./Event/ScannerCode";
import SendEmail from "./Event/SendEmail";
import UserEvent from "./Event/UserEvent";
import MyProfile from "./MyProfile/MyProfile";
import User from "./User/User";
import Header from "./Header/Header";
import Auth from "./Auth/Auth";
import { useEffect } from "react";
import Logout from "./AccountManagement/Logout";
import LoggingIn from "./AccountManagement/LoggingIn";
import "primereact/resources/primereact.min.css";
import Install from "./AccountManagement/Install.js";

function App() {
  useEffect(() => {
    Auth.handleAuth();
  }, []);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route path="/login/:return?" component={Login} exact />
          <Route path="/Install" component={Install} exact />
          <ProtectedRoute path="/" component={Dashboard} exact />
          <Route path="/dashboard" component={Dashboard} exact />
          <ProtectedRoute path="/users" component={User} exact />
          {/* <ProtectedRoute
            path="/changepassword"
            component={ChangePassword}
            exact
          /> */}
          <Route path="/forgotpassword" component={ForgotPassword} exact />
          <Route
            path="/updatepassword/:index"
            component={UpdatePassword}
            exact
          />
          <ProtectedRoute path="/userevent" component={UserEvent} exact />
          <ProtectedRoute
            path="/adduserevent/:mode/:id"
            component={AddUserEvent}
            exact
          />
          <Route
            path="/addusereventmobile"
            component={AddUserEventMobile}
            exact
          />
          <Route
            path="/appstore/:promocode/:eventid"
            component={appstore}
            exact
          />
          <Route path="/photoslider" component={PhotoSlider} exact />
          <Route path="/sendmail" component={SendEmail} exact />
          <Route path="/userregistration" component={UserRegistration} exact />
          <Route path="/photoslider/:eventid" component={PhotoSlider} exact />
          <Route
            path="/photoviewer/:eventid/:id"
            component={PhotoViewer}
            exact
          />
          <ProtectedRoute path="/myprofile" component={MyProfile} exact />
          <Route
            path="/confirmregistration/:index"
            component={ConfirmRegistration}
            exact
          />
          <Route path="/scannercode" component={ScannerCode} exact />
          <Route path="/logout" component={Logout} exact />
          <Route path="/Header" component={Header} exact />
          <Route path="/loggingin" component={LoggingIn} exact />
          {/* <Route path="/userinformation" component={UserInformation} exact/> */}
          <Route component={PageNotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
