import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import validator from "validator";
import Footer from "../Common/Footer";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
import "./UserRegistration.css";
class UserRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      DOB: null,
      CountryList: [
        // {
        //   Id: 0,
        //   ShortName: "NN",
        //   Name: "--Select the country--",
        // },
      ],
      CountryId: 0,
      StateList: [
        // {
        //   Id: 0,
        //   ShortName: "NN",
        //   Name: "--Select the State--",
        // },
      ],
      StateId: 0,
      ErrorMessage: "",
      Loading: false,
      SuccessMessage: "",
      LoadingMessage: "",
      CountryArray: [],
      StateArray: [],
      CityArray: [],
      stateedit: true,
      show: false,
      setShow: false,
      handleClose: false,
      handleShow: false,
      today1:new Date()
    };
    this.onTagsChangeForCountry = this.onTagsChangeForCountry.bind(this);
    this.onTagsChangeForState = this.onTagsChangeForState.bind(this);
    this.GetCountries();
  }

  componentDidMount() {
    this.GetCountries();
  }

  onTagsChangeForCountry = (event, values) => {
    if (values != null) {
      this.setState(
        {
          CountryArray: values,
          StateArray: [],
          CityArray: [],
        },
        () => {
          this.setState({
            StateList: [],
            CityList: [],
          });
          this.GetStatesByCountryId(this.state.CountryArray.Id);
          if (this.state.CountryArray.Id == 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              countryCss: 1,
              StateId: 0,
              CityId: 0,
              stateedit: false,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],
        StateList: [],
        StateArray: [],
        CityList: [],
        CityArray: [],
        CountryId: 0,
        StateId: 0,
        CityId: 0,
        stateedit: true,
      });
    }
  };
  onTagsChangeForState = (event, values) => {
    if (values != null) {
      this.setState({
        StateArray: values,
        CityArray: [],
        StateId: values.Id,
      });
    } else {
      this.setState({
        StateArray: [],
        CityList: [],
        CityArray: [],
        StateId: 0,
        CityId: 0,
      });
    }
  };

  GetCountries = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        const country = [];
        const countryList = [];
        response.data.forEach((element) => {
          countryList.push(element);
        });
        const finalCountryList = [...country, ...countryList];
        this.setState({
          CountryList: finalCountryList,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while get all countries",
          SuccessMessage: "",
        });
      });
  };
  GetStatesByCountryId = (id) => {
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        const obj = {
          // Id: 0,
          // Name: "--Select the State--",
          // CountryId: 0,
        };

        const data = response.data;

        data.push(obj);

        this.setState({
          StateList: data,

          CountryId: id,
        });
      })

      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while gettting event get states by countryid",
        });
      });
  };

  onSaveClick = () => {
    if (this.validate()) {
      const newDate = moment(this.state.DOB).format("YYYY-MM-DD");
      this.setState({ SuccessMessage: "" });
      var data = JSON.stringify({
        FirstName: this.state.FirstName,
        LastName: this.state.LastName,
        Email: this.state.Email,
        DOB: newDate,
        CountryId: parseInt(this.state.CountryId),
        StateId: parseInt(this.state.StateId),
        MobileNo: this.state.MobileNo,
        Password: this.state.Password,
      });

      var config = {
        method: "post",
        url: process.env.REACT_APP_API + `User/UserRegistration`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      this.setState({
        Loading: true,
        LoadingMessage: "please wait",
      });

      axios(config)
        .then((response) => {
          this.setState({
            FirstName: "",
            LastName: "",
            Email: "",
            DOB: null,
            CountryId: 0,
            StateList: [],
            StateArray: [],
            CountryArray: [],
            StateId: 0,
            MobileNo: "",
            Password: "",
            ConfirmPassword: "",
            Loading: false,
            LoadingMessage: "",
            SuccessMessage: "Please check your email for confirmation.",
          });
        })
        .catch((error) => {
          if (error.response != null) {
            if (error.response.status == 409) {
              this.setState({
                Loading: false,
                ErrorMessage: error.response.data,
              });
            } else {
              console.log(error);
              this.setState({
                ErrorMessage: "Error while creating user",
              });
            }
          }
          console.log(error);
        });
    }
  };
  validate = () => {
    let valid = true;
    this.setState({
      ErrorMessage: "",
      SuccessMessage: "",
    });

    if (this.state.FirstName == "") {
      this.setState({
        ErrorMessage: "Please enter first name",
      });
      return false;
    }

    if (this.state.LastName == "") {
      this.setState({
        ErrorMessage: "Please enter last name",
      });
      return false;
    }
    if (this.state.DOB == "" || this.state.DOB == null) {
      this.setState({
        ErrorMessage: "Please select DOB",
        SuccessMessage: "",
      });
      return false;
    }
     if (moment(this.state.today1).diff(moment(this.state.DOB), "years") < 13) {
       this.setState({
         ErrorMessage: "Age under 13 is not allowed.",
         SuccessMessage: "",
       });
       return false;
     }
    // if (this.state.CountryId == 0) {
    //   this.setState({
    //     ErrorMessage: "Please select a Country",
    //     SuccessMessage: "",
    //   });
    //   return false;
    // }
    // if (this.state.StateId == 0) {
    //   this.setState({
    //     ErrorMessage: "Please select a state",
    //     SuccessMessage: "",
    //   });
    //   return false;
    // }
    if (this.state.Email == "") {
      this.setState({
        ErrorMessage: "Please enter email address",
      });
      return false;
    }
    if (!validator.isEmail(this.state.Email)) {
      this.setState({
        ErrorMessage: "Please enter valid email address",
      });
      return false;
    }
    if (this.state.MobileNo.length != 10) {
      this.setState({
        ErrorMessage: "Please enter a valid mobile number",
      });
      return false;
    }
    if (this.state.Password == "") {
      this.setState({
        ErrorMessage: "Please enter password",
      });
      return false;
    }
    if (
      validator.isStrongPassword(this.state.Password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      this.setState({
        ErrorMessage:
          "Note: Please enter a valid password (minimum length 8 characters, 1 special character, 1 capital letter,1 lower case letter, and 1 number)",
      });
    } else {
      this.setState({
        ErrorMessage:
          "Note: Please enter a valid password (minimum length 8 characters, 1 special character, 1 capital letter,1 lower case letter, and 1 number)",
      });
      return false;
    }
    if (
      this.state.ConfirmPassword == "" ||
      this.state.ConfirmPassword == null
    ) {
      this.setState({
        ErrorMessage: "Please enter confirm password",
      });
      return false;
    }

    if (this.state.Password != this.state.ConfirmPassword) {
      this.setState({
        ErrorMessage: "Password do not match",
      });
      return false;
    }

    this.setState({
      ErrorMessage: "",
    });
    return valid;
  };

  onChangeDate = (date) => {
    this.setState({
      DOB: date,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  handleChange = (e) => {
    this.setState({
      StateList: [
        {
          Id: 0,
          ShortName: "NN",
          Name: "--Select the State--",
        },
      ],
    });
    this.GetStatesByCountryId(e.target.value);
    if (e.target.value == 0) {
    } else {
      this.setState({
        StateId: 0,
      });
      this.stateInput.focus();
    }
  };
  onHide() {
    this.props.history.push("/Login");
  }
  handleStateChange = (e) => {
    this.setState({
      StateId: e.target.value,
    });
  };

  renderInput = (props) => (
    <div class="form-group form-group-design text-left">
      <input
        type="text"
        className="form-input"
        placeholder="Enter DOB"
        onClick={props.onClick}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
  renderFooter() {
    return (
      <div>
        <Button
          label="OK"
          icon="pi pi-check"
          onClick={() => this.onHide()}
    
          className="btn myProfile-btn text-16 font-semibold"
        />
      </div>
    );
  }
  render() {
    const today = new Date();
    return (
      <>
        <main class="main-content">
          <div class="login-form-wrapper">
            <div class="login-form-radius">
              <div class="login-form-content">
                <a href="/" title="Participic">
                  <img
                    src={Logo}
                    width="320"
                    height="51"
                    alt="Participic"
                    class="logo-fit mt-0"
                  />
                </a>
                <h2 class="text-navyblue text-16 font-semibold">
                  Sign Up For Participic Here.
                </h2>
                <div class="login-form">
                  {this.state.Loading === true ? (
                    <div
                      style={{ verticalAlign: "center", textAlign: "center" ,fontFamily: "primeicon",}}
                    >
                      <Spinner animation="border" variant="secondary" />{" "}
                      <b>{this.state.LoadingMessage}</b>
                    </div>
                  ) : (
                    <>
                      <div class="form-group form-group-design text-left">
                        <label for="firstname">
                          First Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          aria-describedby="firstname"
                          className="form-input"
                          placeholder="Enter First Name"
                          id="firstname"
                          name="FirstName"
                          onChange={this.onChange}
                          onKeyPress={this.handleKeypress}
                          value={this.state.FirstName}
                          style={{ cursor: "pointer" }}
                          maxLength={150}
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="lastname">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          aria-describedby="lastname"
                          className="form-input"
                          placeholder="Enter Last Name"
                          id="LastName"
                          name="LastName"
                          value={this.state.LastName}
                          onChange={this.onChange}
                          onKeyPress={this.handleKeypress}
                          style={{ cursor: "pointer" }}
                          maxLength={150}
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="DOB">
                          DOB <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="form-group">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              TextFieldComponent={this.renderInput}
                              aria-describedby="DOB"
                              openTo="year"
                              format="dd/MM/yyyy"
                              views={["year", "month", "date"]}
                              value={this.state.DOB}
                              onChange={this.onChangeDate}
                              className="input-group-date form-input "
                              maxDate={today}
                              placeholder="Enter DOB"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="Country">
                          Country 
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>

                        <Autocomplete
                          key={new Date()}
                          style={{ width: "100%" }}
                          id="country-select-demo"
                          sx={{ width: 300 }}
                          options={this.state.CountryList}
                          autoHighlight
                          getOptionLabel={(option) => {
                            if (option.hasOwnProperty("Name")) {
                              return option.Name;
                            }
                            return option;
                          }}
                          onChange={this.onTagsChangeForCountry}
                          onKeyPress={this.handleKeypress}
                          value={this.state.CountryArray.Name}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.Name}
                            </Box>
                          )}
                          renderInput={(params) =>
                            this.state.CountryArray.Id ? (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            ) : (
                              <TextField
                                {...params}
                                label="Choose a country"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )
                          }
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="State">
                          State 
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>

                        <Autocomplete
                          key={new Date()}
                          style={{ width: "100%" }}
                          id="country-select-demo"
                          sx={{ width: 300 }}
                          options={this.state.StateList}
                          autoHighlight
                          getOptionLabel={(option) => {
                            if (option.hasOwnProperty("Name")) {
                              return option.Name;
                            }
                            return option;
                          }}
                          onChange={this.onTagsChangeForState}
                          onKeyPress={this.handleKeypress}
                          disabled={this.state.stateedit ? true : false}
                          value={this.state.StateArray.Name}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option.Name}
                            </Box>
                          )}
                          renderInput={(params) =>
                            this.state.StateArray.Id ? (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            ) : (
                              <TextField
                                {...params}
                                label="Choose a state"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )
                          }
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="email">
                          Email Id <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="email"
                          aria-describedby="email"
                          className="form-input"
                          placeholder="Enter Email"
                          id="email"
                          name="Email"
                          value={this.state.Email}
                          onChange={this.onChange}
                          onKeyPress={this.handleKeypress}
                          style={{ cursor: "pointer" }}
                          maxLength={150}
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="mobileno">
                          Mobile No <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-input text-14"
                          type="text"
                          placeholder="Enter Phone Number"
                          id="MobileNo"
                          name="MobileNo"
                          onChange={(e) => {
                            const re = /^[0-9\b]+$/;
                            if (
                              e.target.value === "" ||
                              (re.test(e.target.value) &&
                                e.target.value.length <= 10)
                            ) {
                              this.setState({
                                [e.target.name]: e.target.value,
                              });
                            }
                          }}
                          onKeyPress={this.handleKeypress}
                          value={this.state.MobileNo}
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="email">
                          Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          aria-describedby="password"
                          className="form-input"
                          placeholder="Password"
                          id="password"
                          name="Password"
                          value={this.state.Password}
                          onChange={(e) => {
                            this.setState({
                              [e.target.name]: e.target.value.trim(),
                            });
                          }}
                          onKeyPress={this.handleKeypress}
                          style={{ cursor: "pointer" }}
                          maxLength={30}
                        />
                      </div>
                      <div class="form-group form-group-design text-left">
                        <label for="email">
                          Confirm Password{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="password"
                          aria-describedby="password"
                          className="form-input"
                          placeholder="Confirm Password"
                          id="password"
                          name="ConfirmPassword"
                          value={this.state.ConfirmPassword}
                          onChange={(e) => {
                            this.setState({
                              [e.target.name]: e.target.value.trim(),
                            });
                          }}
                          onKeyPress={this.handleKeypress}
                          style={{ cursor: "pointer" }}
                          maxLength={150}
                        />
                      </div>
                      <div className="col-lg-12">
                        <div className=" ">
                          {this.state.SuccessMessage != "" ? (
                            <div
                              class="alert alert-success d-flex align-items-center justify-content-center"
                              role="alert"
                            >
                              <div className="text-14">
                                {/* {this.state.SuccessMessage} */}
                                <Dialog
                                  header="Success!"
                                  draggable={false}
                                  resizable={false}
                                  footer={this.renderFooter()}
                                  visible={this.state.SuccessMessage}
                                  onHide={() => this.onHide()}
                                  className="successdialog"
                                >
                                  <b>
                                    <h2 className="fontSize">
                                      {this.state.SuccessMessage}
                                    </h2>
                                  </b>
                                </Dialog>
                              </div>
                            </div>
                          ) : null}
                          {this.state.ErrorMessage != "" ? (
                            <>
                              <div
                                class="alert alert-danger d-flex align-items-center justify-content-center"
                                role="alert"
                              >
                                <div className="text-14">
                                  {this.state.ErrorMessage}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <Button
                        input
                        type="button"
                        label="Sign Up"
                        className="btn btn-primary btn-lg btn-sign-in"
                        onClick={this.onSaveClick}
                      ></Button>
                    </>
                  )}
                </div>
                <br />
                <br />

                <Link
                  to="/Login"
                  className="forgot-psw-link text-16 text-blue font-semibold"
                  title="Sign In"
                >
                  Sign In
                </Link>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(UserRegistration);
