import axios from "axios";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { Redirect } from "react-router-dom";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import refreshicon from "../assets/images/Refresh Icon.svg";
import AddUser from "./AddUser";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SuccessMessage: "",
      UserList: [],
      ExportList: [],
      UserData: {},
      EditClickUserId: 0,
      Loading: false,
      SearchValue: "",
      showModal: false,
      ToggleSideBar: true,
      Mode: "",
      EventDashboardValue: 2,
      sidebarvalue: 3,
      IsDeactivated: false,
      headers: [
        { label: "First Name", key: "FirstName" },
        { label: "Last Name", key: "LastName" },
        { label: "Email", key: "Email" },
        { label: "Role", key: "RoleName" },
      ],
      exportHeaders: [
        { label: "First Name", key: "FirstName" },
        { label: "Last Name", key: "LastName" },
        { label: "Email", key: "Email" },
        { label: "Event Name", key: "EventName" },
        { label: "Event Location", key: "EventLocation" },
        { label: "Role", key: "RoleName" },
        { label: "DOB", key: "DOB" },
        { label: "Country", key: "CountryName" },
        { label: "State", key: "StateName" },
      ],
      CSVData: [],
    };
    console.log(this.state.exportHeaders, "sgdus");
    this.csvLinkEl = React.createRef();
    this.intervalId = null;
  }

  componentDidMount = () => {
    // setInterval( () =>{
    //   window.location.reload();
    // }, 5000);
    this.getAllUsers();
    this.intervalId = setInterval(() => {
      this.getAllUsers();
    }, 15000);
    this.getAllExportUsersList();
    document.body.classList.remove("login-page");
    document.body.classList.add("dashboard-page");
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      this.setState(
        {
          ToggleSideBarNav: !this.state.ToggleSideBarNav,
        },
        () => {
          localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
          if (this.state.ToggleSideBarNav) {
            document.body.classList.remove("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
          } else {
            document.body.classList.add("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
          }
          this.setState({
            ToggleSideBarNav: !this.state.ToggleSideBarNav,
          });
        }
      );
    } else {
      localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
      if (this.state.ToggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
      }
      this.setState({
        ToggleSideBarNav: !this.state.ToggleSideBarNav,
      });
    }
  };
  onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav === 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
    }
    this.setState({
      ToggleSideBarNav: !ToggleSideBarNav,
    });
  };

  onRefreshClick = () => {
    this.setState(
      {
        SearchValue: "",
        UserList: [],
      },
      () => {
        this.getAllUsers();
      }
    );
  };

  getAllUsers = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetAllUsers`,
    };
    this.setState({
      Loading: true,
    });
    axios(config)
      .then((response) => {
        this.setState({
          UserList: response.data,
          Loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while getting all users",
        });
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  getAllExportUsersList = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetAllExportUsersList`,
    };
    axios(config)
      .then((response) => {
        this.setState({
          ExportList: response.data,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while getting all getAllExportUsersList",
        });
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onInputChange = (e) => {
    this.setState({
      SearchValue: e.target.value,
    });
  };

  toogleAddClickPopup = (val) => {
    this.setState({
      showModal: val,
      Mode: "C",
      UserData: {},
      EditClickUserId: 0,
    });
  };

  editClickHandler = (id, val) => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetUserDetails?id=${id}`,
    };

    axios(config)
      .then((response) => {
        this.setState({
          UserData: response.data,
          Loading: false,
          Mode: "E",
          showModal: val,
          EditClickUserId: id,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while getting users detail by id",
        });
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onCloseClick = () => {
    this.setState({
      showModal: false,
    });
  };

  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };
  onDeleteClick = (UserId) => {
    var config = {
      method: "put",
      url: process.env.REACT_APP_API + `User/DeleteUser?UserId=${UserId}`,
      headers: {},
    };
    this.setState({
      LoadingMessage: "Delete Event",
      Loading: true,
    });
    axios(config)
      .then((response) => {
        // this.onClick();
        this.setState({
          Loading: false,
          SuccessMessage: "User deleted successfully!!!",
        });
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onDeactivateClick = (UserId, IsDeactivated) => {
    const token = CommonValues.GetAuth0Token();
    var data = JSON.stringify({
      UserId: UserId,
      IsDeactivated: !IsDeactivated,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API + `User/ActivateDeactivateUser`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  rejectFunc = () => {};

  confirmDelete = (user) => {
    confirmDialog({
      message: `Do you want to delete this user?`,
      header: "Delete Confirmation",
      icon: "pi pi-trash",
      draggable: false,
      resizable: false,
      className: "errorpopup",
      accept: () => this.onDeleteClick(user.UserId),
      reject: () => this.rejectFunc(),
    });
  };

  confirmDeactivate = (user) => {
    if (user.IsDeactivated === true) {
      confirmDialog({
        message: `Do you want to activate ${user.FirstName} ${user.LastName}?`,
        header: "Activate Confirmation",
        icon: "pi pi-info-circle",
        draggable: false,
        resizable: false,
        accept: () => this.onDeactivateClick(user.UserId, user.IsDeactivated),
        reject: () => this.rejectFunc(),
      });
    } else {
      confirmDialog({
        message: `Do you want to deactivate ${user.FirstName} ${user.LastName}?`,
        header: "Deactivate Confirmation",
        icon: "pi pi-info-circle",
        draggable: false,
        resizable: false,
        accept: () => this.onDeactivateClick(user.UserId, user.IsDeactivated),
        reject: () => this.rejectFunc(),
      });
    }
  };
  downloadEventsClickHandler = () => {
    const data = this.state.ExportList;
    this.setState({ CSVData: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  };

  render() {
    const RoleId = CommonValues.GetRoleId();
    if (RoleId === 2) {
      return <Redirect to="/" />;
    }

    let spinnerDiv = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="border" variant="warning" />
          <b>Loading Users</b>
        </div>
      </div>
    );
    const actionBodyTemplate = (user) => {
      return (
        <React.Fragment>
          <Button icon="pi pi-pencil" className="p-button-warning mr-1" onClick={() => this.editClickHandler(user.UserId, true)}>
            <i></i>
          </Button>
          {user.IsDeactivated ? (
            <Button
              id="ActivateButton"
              icon="pi pi-times-circle"
              className="p-button-danger mr-1"
              title="Temparary deactivated"
              onClick={() => this.confirmDeactivate(user)}
            ></Button>
          ) : (
            <Button
              id="DeactivateButton"
              icon="pi pi-check-circle"
              className="p-button-success mr-1"
              onClick={() => this.confirmDeactivate(user)}
            ></Button>
          )}
          <Button id="DeleteButton" icon="pi pi-trash" className="p-button-danger mr-1" onClick={() => this.confirmDelete(user)}></Button>
        </React.Fragment>
      );
    };
    let tableData = (
      <DataTable
        value={this.state.UserList}
        paginator
        rows={20}
        removableSort
        selectionMode="single"
        globalFilter={this.state.SearchValue}
        className="table table-striped table-hover bg-white-table p-3 event-list user-table table-responsive"
      >
        <Column field="FirstName" header="First Name" sortable></Column>
        <Column field="LastName" header="Last Name" sortable></Column>
        <Column field="Email" header="Email" sortable></Column>
        <Column field="RoleName" header="Role" sortable></Column>
        <Column header="Action" body={actionBodyTemplate} />
      </DataTable>
    );

    return (
      <>
        <div>
          <main className="main-content-wrapper">
            <div className="page-body-wrapper">
              <Sidebar ToggleSideBar={this.ToggleSideBar} sidebarvalue={this.state.sidebarvalue} />
              <header></header>
              <div class="page-body" style={{ zIndex: 0 }}>
                <div class="row bg-white mobile-navigation align-items-center box-shadow-header pt-3 pb-3 ">
                  <div class="toggle-sidebar">
                    <a class="toggle-icon" href="#" onClick={this.onSideBarToggle}>
                      <span class="one"></span>
                      <span class="two"></span>
                      <span class="three"></span>
                    </a>
                  </div>

                  <img src={Logo} width="" height="" alt="Participic" class="img-fluid participic-logo" />
                </div>
                <Header
                  onToggleButtonClick={this.onToggleButtonClick}
                  onSideBarToggle={this.onSideBarToggle}
                  EventDashboardValue={this.state.EventDashboardValue}
                />

                <div className="container-fluid">
                  <div className="row mt-4 button-tabs event-management-btn-row">
                    <div className="col-lg-5 d-flex pull-left">
                      <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"></div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"></div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"></div>
                      </div>
                    </div>{" "}
                    <div className="col-lg-7 d-flex justify-content-end align-items-center">
                      <Button
                        label="Export to CSV"
                        className="btn btn-primary add-event-btn text-11 font-semibold exporttocsv"
                        onClick={this.downloadEventsClickHandler}
                      ></Button>
                      <CSVLink headers={this.state.exportHeaders} filename="UserData.csv" data={this.state.CSVData} ref={this.csvLinkEl} />
                      {/* <button
                        class="btn btn-primary  add-event-btn text-11 font-semibold ml-2"
                        type="submit"
                        onClick={() => this.toogleAddClickPopup(true)}
                      >
                        <span>
                          <img src={plusicon} className="event-image pr-1" />
                        </span>
                        <span>Add User</span>
                      </button> */}

                      <button class="btn btn-primary refresh-btn text-11 font-semibold ml-2 " type="submit" onClick={this.onRefreshClick}>
                        <span>
                          <img src={refreshicon} className=" event-image pr-1" alt="" />
                        </span>
                        Refresh
                      </button>
                    </div>
                  </div>
                  <br />{" "}
                  <section className="content bg-white pt-2 pb-3 event-table">
                    <div className="card-outline card-primary">
                      <div className="box">
                        <div className="box-body">
                          {this.state.UserList.length === 0 && this.state.Loading === false ? (
                            <p style={{ textAlign: "center", paddingTop: "5px" }}>
                              <b> Oops ! No user found to display. Kindly create one by clicking on the create button displayed on screen</b>
                            </p>
                          ) : (
                            <>
                              {this.state.Loading === true ? null : (
                                <div className="p-input-icon-right bg-white-table" style={{ padding: "20px", width: "100%" }}>
                                  <i className="pi pi-search" />
                                  <InputText type="search" onInput={this.onInputChange} placeholder="Search" style={{ width: "100%" }} />
                                </div>
                              )}
                              <div className="">{this.state.Loading === true ? spinnerDiv : tableData}</div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <Footer></Footer>
                </div>
                <Modal show={this.state.showModal} dialogClassName="modal-dialog modal-dialog-centered">
                  <AddUser
                    EditClickUserId={this.state.EditClickUserId}
                    onCloseClick={this.onCloseClick}
                    onRefreshClick={this.onRefreshClick}
                    Mode={this.state.Mode}
                    UserData={this.state.UserData}
                  />
                </Modal>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default User;
