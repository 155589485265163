import React, { useEffect } from "react";
import { isAndroid } from "react-device-detect";
import NewWindow from "react-new-window";
const Appstore = (props) => {
  const [newwindow, setnewwindow] = React.useState(false);
  useEffect(() => {
    const {
      match: { params },
    } = props;

    window.location.href = `https://mediaparticipic.page.link/qrscan?promocode=${params.promocode}/eventid=${params.eventid}`;
    // if (isAndroid) {
    //   const {
    //     match: { params },
    //   } = props;
    //   window.location.href = `app://demo/qrscan/${params.promocode}/${params.eventid}`;
    //   setTimeout(
    //     () =>
    //       (window.location.href =
    //         "https://play.google.com/store/apps/details?id=com.app.org.participic"),
    //     1000
    //   );
    // } else if (isIOS) {
    //   const {
    //     match: { params },
    //   } = props;

    //   const redirectURL = `app://demo/qrscan/${params.promocode}/${params.eventid}`;
    //   window.location.href = redirectURL;
    //   setTimeout(
    //     () =>
    //       (window.location.href = `https://apps.apple.com/us/app/participic/id1637458675`),
    //     1000
    //   );
    // } else {
    //   const {
    //     match: { params },
    //   } = props;
    //   //window.location.href = process.env.REACT_APP_WEB_URL;
    //   window.location.href = `https://mediaparticipic.page.link/qrscan?promocode=${params.promocode}&eventid=${params.eventid}`;
    //   // setTimeout(
    //   //   () =>
    //   //     (window.location.href = `https://mediaparticipic.page.link/qrscan?promocode=${params.promocode}&eventid=${params.eventid}`),
    //   //   10000
    //   // );
    // }
  }, [props]);

  return (
    <div className="App">
      {newwindow ? (
        <NewWindow
          url="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.google.android.youtube"
          target="_top"
          features={{
            popup: "yes",
          }}
        ></NewWindow>
      ) : null}

      <a
        id="appid"
        href={
          isAndroid
            ? "https://play.google.com/store/apps/details?id=com.google.android.youtube"
            : "https://apps.apple.com/app/youtube-watch-listen-stream/id544007664"
        }
        target="_top"
      >
        App link
      </a>
      <br></br>
      <a id="appid1" href={isAndroid ? "app://demo" : "https://apps.apple.com/app/youtube-watch-listen-stream/id544007664"} target="_top">
        open app
      </a>
    </div>
  );
};

export default Appstore;
