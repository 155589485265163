class CommonValues {
  static GetAuth0Token = () => {
    return localStorage.getItem("access_token");
  };
  static GetUserId = () => {
    return localStorage.getItem("UserId");
  };


  static GetUserName = () => {
    return localStorage.getItem("UserName");
  };

  static GetMobile = () => {
    return localStorage.getItem("MobileNo");
  };

  static GetLastName = () => {
    return localStorage.getItem("LastName");
  };

  static GetEmail = () => {
    return localStorage.getItem("Email");
  };

  static GetRoleId = () => {
    return localStorage.getItem("RoleId");
  };

  static GetRoleName = () => {
    return localStorage.getItem("RoleName");
  };

  static GetProfileImageURL = () => {
    return localStorage.getItem("ProfileImageURL");
  };

  static GetCanDownloadPhoto = () => {
    return localStorage.getItem("CanDownloadPhoto");
  };

  static GetEncryptionUserInfo = () => {
    return localStorage.getItem("EncryptionUserInfo");
  };

  static getLocation= ()=>{
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(true);
        },
        (error) => {
          if (error.code === 1) {
            resolve(false);
          } else {
            resolve(false);
          }
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );
    })
  }

  static SetValues = (
    userId,
    UserName,
    LastName,
    RoleId,
    RoleName,
    ProfileImage
  ) => {
    // let newProfileimage =
    // process.env.REACT_APP__PROFILE_IMAGE + `${userId}/${ProfileImage}`;

    localStorage.setItem("UserId", userId);

    localStorage.setItem("UserName", UserName);

    localStorage.setItem("LastName", LastName);

    localStorage.setItem("RoleId", RoleId);
    localStorage.setItem("RoleName", RoleName);
    localStorage.setItem("ProfileImageURL", ProfileImage);
  };

  static ClearValues = () => {
    localStorage.removeItem("isAuthenticated");

    localStorage.removeItem("UserId");

    localStorage.removeItem("Token");

    localStorage.removeItem("UserName");

    // localStorage.removeItem("Email");

    localStorage.removeItem("LastName");

    localStorage.removeItem("MobileNo");

    localStorage.removeItem("ProfileImage");

    localStorage.removeItem("PromoCode");

    localStorage.removeItem("RoleId");
    localStorage.removeItem("EventId");
    localStorage.removeItem("CanDownloadPhoto");
    localStorage.removeItem("ProfileImageURL");
    localStorage.removeItem("EventName");
    localStorage.removeItem("sidebar");

    localStorage.removeItem("EncryptionUserInfo");

    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("UserId");
    localStorage.removeItem("RoleName");
  };
}

export default CommonValues;