import axios from "axios";
import { Button } from "primereact/button";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import validator from "validator";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
// import { Checkbox } from "primereact/checkbox";
import Logo from "./../assets/images/Participic_logo.png";
import Auth from "../Auth/Auth";
import { Spinner } from "react-bootstrap";
class Login extends Component {
  constructor(props) {
    super(props);
    this.auth = Auth;
    this.onResetPasswordClickHandler =
      this.onResetPasswordClickHandler.bind(this);
    this.state = {
      isChecked: false,
      Email: "",
      Password: "",
      loading: false,
      LoggedInUserData: {},
      ErrorMessage: "",
      ButtonLabel: "Sign In",
      ShowLoginAndForgetPassword: false,
      ForgotPasswordEmail: "",
      NewImageValue: "",
      ReturnUrl: "",
    };
    this.onSingInClick = this.onSingInClick.bind(this);
  }

   componentDidMount() {
    document.body.classList.remove("dashboard-page");
    document.body.classList.add("login-page");

    const {
      match: { params },
    } = this.props;

    this.setState({
      ReturnUrl: this.props.history.location.pathname,
    });

    //CommonValues.ClearValues();
    var access_token = localStorage.getItem("access_token");
    if (access_token != null && access_token != undefined) {
      this.props.history.push("/dashboard");
    } else {
      this.auth.login();
    }
    // let EmailName = localStorage.getItem("Email");
    // let showrememberMeLS = JSON.parse(localStorage.getItem("RememberMe")); ; 
    //   if (EmailName !== null && EmailName !== "") {
    //   this.setState(
    //     {
    //       Email: EmailName,
    //     },
    //     () => {
    //       this.setState({
    //         isChecked: true,
    //       });
    //     }
    //   );
    // } else {
    //   this.setState({
    //     isChecked: showrememberMeLS,
    //   });
    // };
    // CommonValues.ClearValues();
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSingInClick();
    }
  };

  onSingInClick = () => {
    if (this.validate()) {
      
      this.setState({
        loading: true,
        ButtonLabel: "Signing in",
      });
      var data = JSON.stringify({
        Email: this.state.Email,
        Password: this.state.Password,
      });

      var config = {
        method: "post",
        url: process.env.REACT_APP_API + `User/Authenticate`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          
          if (response.data.Token != null || response.data.Token != "") {
            localStorage.setItem("isAuthenticated", JSON.stringify(true));
            localStorage.setItem(
              "UserId",
              JSON.stringify(response.data.UserId)
            );
            localStorage.setItem(
              "UserName",
              JSON.stringify(response.data.FirstName)
            );
         
            localStorage.setItem(
              "MobileNo",
              JSON.stringify(response.data.MobileNo)
            );
            localStorage.setItem("Email", JSON.stringify(response.data.Email));
            localStorage.setItem(
              "LastName",
              JSON.stringify(response.data.LastName)
            );
            localStorage.setItem("Token", JSON.stringify(response.data.Token));
            localStorage.setItem(
              "RoleId",
              JSON.stringify(response.data.RoleId)
            );
            localStorage.setItem(
              "RoleName",
              JSON.stringify(response.data.RoleName)
            );

            localStorage.setItem(
              "ProfileImageURL",
              JSON.stringify(response.data.ImageURL)
            );
            localStorage.setItem(
              "EncryptionUserInfo",
              JSON.stringify(response.data.EncryptionUserInfo)
            );
          localStorage.setItem(
            "RememberMe",
            (this.state.isChecked)
          );
               if (this.state.isChecked == true) {
                
                 localStorage.setItem("Email", this.state.Email);
                } else {
                  
                 localStorage.setItem("Email", "");
                    this.setState({
                   Email: "",
                   isChecked: false,
                 });
               }

            this.setState({
              loading: false,
              ButtonLabel: "Sign in",
            });
            this.state.ReturnUrl = "/dashboard";
            window.location.pathname =
              this.state.ReturnUrl != "" ? this.state.ReturnUrl : "/";
          } else {
            window.location.pathname = "/login";
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
            ButtonLabel: "Sign In",
            ErrorMessage: "Email id or password is incorrect.",
            Password: "",
          });
        });
    }
  };


  validate = () => {
    let valid = true;
    if (this.state.Email.trim() == "") {
      this.setState({
        ErrorMessage: "Please enter email ID",
      });
      return false;
    }
    if (this.state.Password.trim() == "") {
      this.setState({
        ErrorMessage: "Please enter password",
      });
      return false;
    }

    if (!validator.isEmail(this.state.Email)) {
      this.setState({
        ErrorMessage: "Please enter valid email address",
      });
      return false;
    }

    if (this)
      this.setState({
        ErrorMessage: "",
      });

    return valid;
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { name, email } = this.state;
  };

  onForgotPasswordEmailChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChangeCheckbox = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  onResetPasswordClickHandler = () => {};

  render() {
    return (
      <>
 <main class="main-content">
           

        <div className="table-loading"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            transform: "translate(-50%, -50%)",
            fontFamily: "primeicon",
            fontSize:"16px",
            fontWeight:"700px",
            color:"black",
          }}
        >
          <Spinner animation="border" variant="primary" /> <b>Please wait...</b>
        </div>
  

     
        </main> 
      </>
    );
  }
}

export default withRouter(Login);
