import React, { Component } from "react";
 import Footer from "../Common/FooterPage";
 import Logo from "../../src/assets/images/logo/Participiclogo.png";
import Appstore from "../../src/assets/images/logo/app.store.png";
import Googleplay from "../../src/assets/images/logo/google play.png";
import MobileStoreButton from "react-mobile-store-button";
import { Container } from "react-bootstrap";

export default class Install extends Component {
  componentDidMount() {

  }
  render() {
    return (
      <>
        <div>
          <main className="main-content-wrapper">
            <div className="page-body-wrapper">
              <div style={{ textAlign: "center", marginTop: "23px" }}>
                <img
                  src={Logo}
                  width="500"
                  height="500"
                  alt="Participic"
                  class="img-fluid participic-logo"
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "100px" }}>
                <div style={{ textAlign: "center" }}>
                  <a href="https://play.google.com/store/apps/details?id=com.app.org.participic">
                    <img
                      src={Googleplay}
                      width="300"
                      height="300"
                      alt="Participic"
                      class="img-fluid appstore-logo"
                    />
                  </a>
                </div>
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <a href="https://apps.apple.com/us/app/participic/id1637458675">
                    <img
                      src={Appstore}
                      width="300"
                      height="300"
                      alt="Participic"
                      class="img-fluid appstore-logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>

        <div class="footer">
          <Footer></Footer>
        </div>
      </>
    );
  }
}
