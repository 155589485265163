import React, { Component, Fragment } from "react";
import { Circle, GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: this.props.places,
      center: this.props.center,
      zoomLevel: this.props.zoom,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.places !== prevProps.places && this.props.center !== prevProps.center) {
      this.setState({
        places: this.props.places,
        center: this.props.center,
      });
    }
  }

  onMarkerDragEnd = (coord) => {
    this.setState({
      places: [
        {
          id: 1,
          latitude: coord.latLng.lat(),
          longitude: coord.latLng.lng(),
          circle: {
            options: {
              strokeColor: "#ff0000",
            },
          },
        },
      ],
      center: {
        lat: coord.latLng.lat(),
        lng: coord.latLng.lng(),
      },
    });
    this.props.parentCallback(this.state.places, this.state.zoomLevel);
  };
  _handleZoomChanged() {
    const zoomLevel = this.refs.map.getZoom();
    if (zoomLevel !== this.state.zoomLevel) {
      this.setState({ zoomLevel });
    }
  }
  render() {
    return (
      <GoogleMap
        // key={new Date().getTime()}
        defaultZoom={this.state.zoomLevel}
        defaultCenter={this.state.center}
        ref="map"
        onZoomChanged={this._handleZoomChanged.bind(this)}
      >
        {this.state.places.map((place) => {
          return (
            <Fragment key={place.id}>
              <Marker
                position={{
                  lat: parseFloat(place.latitude),
                  lng: parseFloat(place.longitude),
                }}
                draggable={true}
                onDragEnd={(e) => this.onMarkerDragEnd(e)}
              />
              {place.circle && (
                <Circle
                  defaultCenter={{
                    lat: parseFloat(place.latitude),
                    lng: parseFloat(place.longitude),
                  }}
                  radius={this.props.radiusValue}
                  options={place.circle.options}
                />
              )}
            </Fragment>
          );
        })}
      </GoogleMap>
    );
  }
}
export default withScriptjs(withGoogleMap(Map));
