import React, { Component } from "react";
import participicimage from "../../src/assets/images/logo/Participiclogo.png";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import { BrowserView, MobileView, isBrowser, isMobile, isAndroid, isIOS, isChrome } from 'react-device-detect';

class ConfirmRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IdentificationCode: "",
      guid: "",
      userRegistered: false,
      messageResponce: "",
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({
      guid: params.index,
    });
    this.confirmRegistration(params.index);
  }

  confirmRegistration = (guid) => {
    var axios = require("axios");
    var data = JSON.stringify({
      IdentificationCode: guid,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API + `User/ConfirmRegistration`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if ((response.data = true))
          this.setState({
            message:
              "Thanks for registering with us. Please click here to login.",
          });
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.setState({
            message: "Sorry! This link is already expired or invalid!!",
          });
        } else {
          this.setState({
            message: "Some internal error.",
          });
        }
      });
  };
  render() {

    let singInurl = "";
    if (isAndroid) {
      singInurl = "https://play.google.com/store/apps/details?id=com.app.participic";
    } else if (isIOS) {
      singInurl = "https://apps.apple.com/us/app/participic/id1637458675";
    } else {
      singInurl = "/";
    }
    return (
      <div>
        <main class="main-content">
          <div class="login-form-wrapper">
            <div class="login-form-radius">
              <div class="login-form-content">
                <a href="/" title="Participic">
                  <img
                    src={participicimage}
                    width="320"
                    height="51"
                    alt="Participic"
                    class="logo-fit mt-0"
                  />
                </a>

                <h2 class="text-navyblue text-16 font-semibold">
                  {this.state.message}
                </h2>

                <a
                  href={singInurl}
                  class="forgot-psw-link text-16 text-blue font-semibold"
                  title="Sign In"
                >
                  Sign In
                </a>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </main>
      </div>
    );
  }
}

export default ConfirmRegistration;
