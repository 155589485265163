import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { withRouter } from "react-router-dom";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import plusicon from "../assets/images/Plus Icon.svg";
import refreshicon from "../assets/images/Refresh Icon.svg";
import ScannerCode from "./ScannerCode";
import SendEmail from "./SendEmail";
class UserEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showhidedropdowns: false,
      showScanner: false,
      showScannerCodeModal: false,
      EventId: 0,
      ToggleSideBar: true,
      Loading: true,
      loading: false,
      ButtonLabel: "Search",
      showModal: false,
      EmailClickEventId: 0,
      EventList: [],
      EventDashboardValue: 1,
      DynamicEventValue: "Event Management",
      SearchValue: "",
      sidebarvalue: 2,
      DataAvailable: true,
      Venue: "",
      CanEdit: null,
      CountryList: [],
      CountryId: 0,
      StateList: [],
      CityList: [],
      StateId: 0,
      CityId: 0,
      ErrorMessage: "",
      headers: [
        { label: "Name", key: "Name" },
        { label: "Location", key: "Location" },
        { label: "EventDateTime1", key: "EventDateTime" },
      ],
      SuccessMessage: "",
      displaySuccessMessage: false,
      CSVData: [],
      CountryArray: [],
      StateArray: [],
      CityArray: [],
      stateedit: true,
      cityedit: true,
      hideCSVButton: true,
    };
    this.onTagsChangeForCountry = this.onTagsChangeForCountry.bind(this);
    this.onTagsChangeForState = this.onTagsChangeForState.bind(this);
    this.csvLinkEl = React.createRef();
  }

  onTagsChangeForCountry = (event, values) => {
    if (values !== null && values.Id !== 0) {
      this.setState(
        {
          CountryArray: values,
          StateArray: [],
          CityArray: [],
        },
        () => {
          this.setState({
            StateList: [],
            CityList: [],
          });
          this.GetStatesByCountryId(this.state.CountryArray.Id);
          if (this.state.CountryArray.Id === 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              countryCss: 1,
              StateId: 0,
              CityId: 0,
              stateedit: false,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],
        StateList: [],
        StateArray: [],
        CityList: [],
        CityArray: [],
        CountryId: 0,
        StateId: 0,
        CityId: 0,
        stateedit: true,
        cityedit: true,
      });
    }
  };

  onTagsChangeForState = (event, values) => {
    if (values !== null) {
      this.setState(
        {
          StateArray: values,
          CityArray: [],
        },
        () => {
          this.setState({
            CityList: [],
          });
          this.GetCitiesByState(this.state.StateArray.Id);
          if (this.state.StateArray.Id === 0) {
            this.setState({
              stateCss: 0,
            });
          } else {
            this.setState({
              stateCss: 1,
              CityId: 0,
              cityedit: false,
            });
          }
        }
      );
    } else {
      this.setState({
        StateArray: [],
        CityList: [],
        CityArray: [],
        StateId: 0,
        CityId: 0,
        cityedit: true,
      });
    }
  };

  onTagsChangeForCity = (event, values) => {
    if (values !== null) {
      this.setState(
        {
          CityArray: values,
        },
        () => {
          this.setState({
            CityId: this.state.CityArray.Id,
          });
          if (this.state.CityArray.StateId === 0) {
            this.setState({
              cityCss: 0,
            });
          } else {
            this.setState({
              cityCss: 1,
            });
          }
        }
      );
    } else {
      this.setState({
        CityArray: [],
        CityId: 0,
      });
    }
  };

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  renderFooter() {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => this.onHide()} className="btn myProfile-btn text-16 font-semibold" />
      </div>
    );
  }

  onHide() {
    this.setState({
      displaySuccessMessage: false,
    });
  }

  componentDidMount() {
    this.GetCountries();
    this.getAllEvents();
    document.body.classList.remove("login-page");
    document.body.classList.add("dashboard-page");
  }

  onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      this.setState(
        {
          ToggleSideBarNav: !this.state.ToggleSideBarNav,
        },
        () => {
          localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
          if (this.state.ToggleSideBarNav) {
            document.body.classList.remove("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
          } else {
            document.body.classList.add("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
          }
          this.setState({
            ToggleSideBarNav: !this.state.ToggleSideBarNav,
          });
        }
      );
    } else {
      localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
      if (this.state.ToggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
      }
      this.setState({
        ToggleSideBarNav: !this.state.ToggleSideBarNav,
      });
    }
  };

  onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav === 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
    }
    this.setState({
      ToggleSideBarNav: !ToggleSideBarNav,
    });
  };

  validate = () => {
    let valid = true;
    if (this.state.CountryId === 0) {
      this.setState({
        ErrorMessage: "Please select a country",
      });
      return false;
    }

    if (this.state.StateId === 0) {
      this.setState({
        ErrorMessage: "Please select a state",
      });
      return false;
    }

    if (this.state.CityId === 0) {
      this.setState({
        ErrorMessage: "Please select a city",
      });
      return false;
    }
    if (this.state.Venue === "") {
      this.setState({
        ErrorMessage: "Please enter venue",
      });
      return false;
    }
    this.setState({ ErrorMessage: "" });
    return valid;
  };

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.getAllEventsBySearch();
    }
  };

  getAllEvents = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetAllEvents`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    this.setState({
      Loading: true,
    });
    axios(config)
      .then((response) => {
        this.setState({
          EventList: [],
        });
        response.data.forEach((element) => {
          this.state.EventList.push({
            Id: element.EventId,
            Name: element.Name,
            Location: element.Location,
            Description: element.Description,
            EventDateTime1: element.EventDateTime,
            EventDateTime: moment(element.EventDateTime).format("LLL"),
            CanEdit: element.CanEdit,
            PromoCode: element.PromoCode,
            EventName: element.Name,
            waterMarkSetup: element.waterMarkSetup,
            IsEventEnd: element.IsEventEnd,
          });
        });

        this.setState({
          Loading: false,
        });

        if (response.data.length <= 0) {
          this.setState({
            DataAvailable: false,
            showhidedropdowns: false,
            hideCSVButton: true,
          });
        } else {
          this.setState({
            showhidedropdowns: true,
            hideCSVButton: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          Loading: false,
          DataAvailable: false,
        });
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  GetCountries = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          CountryList: [...[...response.data]],
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while get all countries",
        });
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  handleChange = (e) => {
    this.GetStatesByCountryId(e.target.value);
    if (e.target.value === 0) {
      this.setState({
        countryCss: 0,
      });
    } else {
      this.setState({
        countryCss: 1,
      });
    }
  };

  handleStateChange = (e) => {
    this.GetCitiesByState(e.target.value);
    if (e.target.value === 0) {
      this.setState({
        stateCss: 0,
      });
    } else {
      this.setState({
        stateCss: 1,
      });
    }
  };

  handleCityChange = (e) => {
    this.setState({
      CityId: e.target.value,
    });
    if (e.target.value === 0) {
      this.setState({
        cityCss: 0,
      });
    } else {
      this.setState({
        cityCss: 1,
      });
    }
  };

  GetCitiesByState(Id) {
    this.setState({
      StateId: Id,
    });

    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetCitiesByStateId?Id=${Id}`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          CityList: [...[...response.data]],
          StateId: Id,
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting event get cities by stateId",
          });
        }
      });
  }

  GetStatesByCountryId = (id) => {
    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          StateList: [...[...response.data]],
          CountryId: id,
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting event get states by countryid",
          });
        }
      });
  };

  getAllEventsBySearch = () => {
    this.setState({
      loading: true,
      ButtonLabel: "Searching Events",
    });
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API +
        `Event/GetAllEventsBySearch?CountryId=${this.state.CountryId}&StateId=${this.state.StateId}&CityId=${this.state.CityId}&Venue=${this.state.Venue}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.setState({
      loading: true,
      ButtonLabel: "Searching Events",
    });
    axios(config)
      .then((response) => {
        const data = response.data;
        const newList = [];
        if (data.length === 0) {
          this.setState({
            DataAvailable: false,
          });
        } else {
          this.setState({
            DataAvailable: true,
          });
        }
        this.setState({
          Loading: false,
          EventList: [],
        });

        data.forEach((element) => {
          newList.push({
            Id: element.EventId,
            Name: element.Name,
            Location: element.Location,
            Description: element.Description,
            EventDateTime1: element.EventDateTime,
            EventDateTime: moment(element.EventDateTime).format("LLL"),
            CanEdit: element.CanEdit,
            IsEventEnd: element.IsEventEnd,
          });
        });
        this.setState({
          loading: false,
          EventList: newList,
          ButtonLabel: "Search",
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onInputChange = (e) => {
    this.setState({
      SearchValue: e.target.value,
    });
  };

  toogleAddClickPopup = () => {
    window.location.pathname = "/AddUserEvent/C/0";
  };

  onCloseClickScannerCodeModal = () => {
    this.setState({
      showScannerCodeModal: false,
    });
  };

  onCloseClick = () => {
    this.setState({
      showModal: false,
    });
  };

  onEditClick = (EventId) => {
    this.props.history.push(`/AddUserEvent/E/${EventId}`);
  };

  onEmailClick = (rowData) => {
    localStorage.setItem("EventName", rowData.EventName);
    localStorage.setItem("PromoCode", rowData.PromoCode);
    localStorage.setItem("EventId", rowData.Id);
    this.setState({
      showModal: true,
    });
  };

  onPrintClick = (rowData) => {
    this.setState({
      PromoCode: rowData.PromoCode,
      EventName: rowData.Name,
      showScannerCodeModal: true,
    });
  };

  onExitClick = (id, IsEventEnd) => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "put",
      url: process.env.REACT_APP_EVENT_API + `Event/EventEnd?eventId=${id}&&IsEventEnd=${IsEventEnd}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.onClick();
        this.setState({
          Loading: false,
          SuccessMessage: response.data,
        });
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onDeleteClick = (EventId) => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "put",
      url: process.env.REACT_APP_EVENT_API + `Event/DeactivateEvent?EventId=${EventId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.setState({
      LoadingMessage: "Deactivate Event",
      Loading: true,
    });
    axios(config)
      .then((response) => {
        this.onClick();
        this.setState({
          Loading: false,
          SuccessMessage: "Event deleted successfully!!!",
        });
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onNotificationClick = (EventId) => {
    var axios = require("axios");
    var data = "";
    var config = {
      method: "post",
      url: process.env.REACT_APP_EVENT_API + `Event/NotifyEmailInvitation?eventId=${EventId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.onClick();
        this.setState({
          Loading: false,
          SuccessMessage: "Notification Mail sent successfully!!!",
        });
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  onRefreshClick = () => {
    this.setState(
      {
        CountryId: 0,
        countryCss: 0,
        StateId: 0,
        stateCss: 0,
        CityId: 0,
        cityCss: 0,
        Venue: "",
        showModal: false,
        EventList: [],
        SearchValue: "",
        CountryArray: [],
        StateArray: [],
        CityArray: [],
        DataAvailable: true,
      },
      () => {
        this.getAllEvents();
      }
    );
  };

  rejectFunc = () => {};

  confirm = (EventId) => {
    confirmDialog({
      message: "Do you want to delete this event?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      draggable: false,
      resizable: false,
      accept: () => this.onDeleteClick(EventId),
      reject: () => this.rejectFunc(),
    });
  };

  confirmExit = (id, data) => {
    if (data.IsEventEnd === false) {
      confirmDialog({
        message: `Do you want to end ${data.Name} event?`,
        header: "End Confirmation",
        icon: "pi pi-info-circle",
        draggable: false,
        resizable: false,
        accept: () => this.onExitClick(id, true),
        reject: () => this.rejectFunc(),
      });
    } else {
      confirmDialog({
        message: `Do you want to open ${data.Name} event?`,
        header: "Open Confirmation",
        icon: "pi pi-info-circle",
        draggable: false,
        resizable: false,
        accept: () => this.onExitClick(id, false),
        reject: () => this.rejectFunc(),
      });
    }
  };

  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  downloadEventsClickHandler = () => {
    const data = this.state.EventList;
    this.setState({ CSVData: data }, () => {
      setTimeout(() => {
        this.csvLinkEl.current.link.click();
      });
    });
  };

  render() {
    // const RoleName = CommonValues.GetRoleName().replace(/^"|"$/g, "");
    // {
    //   if (this.state.EventList === []) {
    //   }
    // }
    let spinnerDiv = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="border" variant="warning" />
          <b>Loading Events</b>
        </div>
      </div>
    );
    const actionBodyTemplate = (rowData) => {
      return (
        <React.Fragment>
          <div className="d-flex">
            <Button icon="pi pi-pencil" className="p-button-warning mr-1" onClick={() => this.onEditClick(rowData.Id)} title="Edit Event">
              <i></i>
            </Button>

            <Button
              id="DeleteButton"
              icon="pi pi-trash"
              title="Deactivate event"
              className="p-button-warning mr-1"
              onClick={() => this.confirm(rowData.Id)}
              disabled={rowData.CanEdit ? false : true}
            ></Button>
            {rowData.IsEventEnd ? (
              <Button
                id="ActivateButton"
                icon="pi pi-times-circle"
                className="p-button-danger mr-1"
                title="Event completed"
                onClick={() => this.confirmExit(rowData.Id, rowData)}
              ></Button>
            ) : (
              <Button
                id="CreateButton"
                icon="pi pi-times-circle"
                className="p-button-success mr-1"
                title="Event running"
                onClick={() => this.confirmExit(rowData.Id, rowData)}
                disabled={rowData.CanEdit ? false : true}
              ></Button>
            )}
            <Button
              id="SendMailButton"
              icon="pi pi-envelope"
              title="Send email"
              className="btn Create-user-cancle-btn text-16 bg-lightblue text-button-grey envelope-border mr-1"
              onClick={() => this.onEmailClick(rowData)}
            ></Button>
            <Button
              id="SendNotificationButton"
              icon="pi pi-bell"
              title="Notification email"
              className="btn Create-user-cancle-btn text-16 bg-lightblue text-button-grey envelope-border mr-1"
              onClick={() => this.onNotificationClick(rowData.Id)}
            ></Button>
          </div>

          <div style={{ display: "none" }}>
            <ScannerCode className="barcode-popup" ref={(el) => (this.componentRef = el)} PromoCode={rowData.PromoCode} EventName={rowData.Name} />
          </div>
        </React.Fragment>
      );
    };

    let tableData = (
      <DataTable
        value={this.state.EventList}
        paginator
        rows={20}
        removableSort
        selectionMode="single"
        globalFilter={this.state.SearchValue}
        stripedRows
        className="table table-striped table-hover   bg-white-table p-3 event-list table-responsive"
      >
        <Column field="Name" header="Event Name" sortable></Column>
        <Column field="Location" header="Location" sortable></Column>
        <Column field="EventDateTime" header="Event Date" sortable></Column>
        <Column header="Action" body={actionBodyTemplate} />
      </DataTable>
    );
    return (
      <div>
        <main className="main-content-wrapper">
          <div className="page-body-wrapper">
            <Sidebar ToggleSideBar={this.ToggleSideBar} sidebarvalue={this.state.sidebarvalue} />
            <header></header>
            <div className="page-body">
              <div class="row bg-white mobile-navigation align-items-center box-shadow-header pt-3 pb-3">
                <div class="toggle-sidebar">
                  <a class="toggle-icon" onClick={this.onSideBarToggle}>
                    <span class="one"></span>
                    <span class="two"></span>
                    <span class="three"></span>
                  </a>
                </div>

                <img src={Logo} width="" height="" alt="Participic" class="img-fluid participic-logo" />
              </div>
              <Header
                onToggleButtonClick={this.onToggleButtonClick}
                onSideBarToggle={this.onSideBarToggle}
                EventDashboardValue={this.state.EventDashboardValue}
                DynamicEventValue={this.state.DynamicEventValue}
              />
              <Dialog
                header="Confirmation"
                draggable={false}
                resizable={false}
                visible={this.state.displaySuccessMessage}
                footer={this.renderFooter()}
                onHide={() => this.onHide()}
              >
                <p>{this.state.SuccessMessage}</p>
              </Dialog>
              <div className="container-fluid">
                <div className="row align-items-center mt-4">
                  <div className="col-lg-12 col-md-12 col-12 pull-left">
                    {this.state.showhidedropdowns === false ? (
                      <></>
                    ) : (
                      <div class="userevent-flex createeventmanagepage">
                        <div className="mb-3 w-100 mr-1">
                          <label className="form-label text-14 font-semibold text-grey">Country</label>
                          <Autocomplete
                            className="countrybox"
                            key={new Date()}
                            style={{ width: "100%" }}
                            id="country-select-demo"
                            sx={{ width: 300 }}
                            options={this.state.CountryList}
                            autoHighlight
                            getOptionLabel={(option) => {
                              if (option.hasOwnProperty("Name")) {
                                return option.Name;
                              }
                              return option;
                            }}
                            onChange={this.onTagsChangeForCountry}
                            onKeyPress={this.handleKeypress}
                            value={this.state.CountryArray.Name}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                                {option.Name}
                              </Box>
                            )}
                            renderInput={(params) =>
                              this.state.CountryArray.Id ? (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              ) : (
                                <TextField
                                  className="input-placeholder"
                                  {...params}
                                  label="Choose a country"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="mb-3 w-100 mr-1">
                          <label className="form-label text-14 font-semibold text-grey">State</label>
                          <Autocomplete
                            key={new Date()}
                            style={{ width: "100%" }}
                            id="country-select-demo"
                            sx={{ width: 300 }}
                            options={this.state.StateList}
                            autoHighlight
                            getOptionLabel={(option) => {
                              if (option.hasOwnProperty("Name")) {
                                return option.Name;
                              }
                              return option;
                            }}
                            disabled={this.state.stateedit ? true : false}
                            onChange={this.onTagsChangeForState}
                            onKeyPress={this.handleKeypress}
                            value={this.state.StateArray.Name}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option.Name}
                              </Box>
                            )}
                            renderInput={(params) =>
                              this.state.StateArray.Id ? (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  }}
                                />
                              ) : (
                                <TextField
                                  {...params}
                                  label="Choose a state"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="mb-3  w-100 mr-1">
                          <label className="form-label text-14 font-semibold text-grey">City </label>
                          <Autocomplete
                            key={new Date()}
                            style={{ width: "100%" }}
                            id="country-select-demo"
                            sx={{ width: 300 }}
                            options={this.state.CityList}
                            autoHighlight
                            getOptionLabel={(option) => {
                              if (option.hasOwnProperty("Name")) {
                                return option.Name;
                              }
                              return option;
                            }}
                            disabled={this.state.cityedit ? true : false}
                            onChange={this.onTagsChangeForCity}
                            onKeyPress={this.handleKeypress}
                            value={this.state.CityArray.Name}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option.Name}
                              </Box>
                            )}
                            renderInput={(params) =>
                              this.state.CityArray.Id ? (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              ) : (
                                <TextField
                                  {...params}
                                  label="Choose a city"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )
                            }
                          />
                        </div>
                        <div className="mb-3  w-100 mr-1">
                          <label className="form-label text-14 font-semibold text-grey">Venue </label>
                          <input
                            type="name"
                            className="form-control-new text-14 text-lightgrey font-semibold"
                            name="Venue"
                            onChange={this.onChange}
                            onKeyPress={this.handleKeypress}
                            value={this.state.Venue}
                            placeholder="Enter venue"
                            id
                            aria-describedby
                          />
                        </div>
                        <div className="SearchButtonEventPage text-center ml-1 ">
                          <Button
                            className="btn btn-primary add-event-btn text-12 font-semibold"
                            type="button"
                            label={this.state.ButtonLabel}
                            loading={this.state.loading}
                            onClick={this.getAllEventsBySearch}
                          ></Button>
                        </div>
                      </div>
                    )}
                  </div>{" "}
                </div>
                <div className="row event-management-btn-row">
                  <div className="col-lg-12 mt-3 d-flex createeventmng ">
                    <>
                      {" "}
                      {this.state.showhidedropdowns === false && this.state.hideCSVButton ? (
                        <></>
                      ) : (
                        <>
                          {" "}
                          <Button
                            label="Export to CSV"
                            className="btn btn-primary add-event-btn text-11 font-semibold"
                            onClick={this.downloadEventsClickHandler}
                          ></Button>
                          <CSVLink headers={this.state.headers} filename="EventData.csv" data={this.state.CSVData} ref={this.csvLinkEl} />
                        </>
                      )}
                    </>

                    <button class="btn btn-primary add-event-btn text-11 font-semibold  ml-2" type="submit" onClick={this.toogleAddClickPopup}>
                      <span>
                        <img src={plusicon} className="event-image pr-1" alt="" />
                      </span>
                      <span>Add Event</span>
                    </button>

                    <button
                      class="btn btn-primary refresh-btn text-11  font-semibold ml-2"
                      type="submit"
                      Loading={this.state.Loading}
                      onClick={this.onRefreshClick}
                    >
                      <span>
                        <img src={refreshicon} className="pr-1 event-image" alt="" />
                      </span>
                      Refresh
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <div className=" alertMessage">
                    {this.state.ErrorMessage !== "" ? (
                      <>
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                          <div className="text-14">{this.state.ErrorMessage}</div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <br />{" "}
                <section className="content bg-white pt-2 pb-3 event-table">
                  <div className="card-outline card-primary">
                    <div className="box">
                      <div className="box-body">
                        {this.state.DataAvailable === false ? (
                          <p
                            style={{
                              textAlign: "center",
                              paddingTop: "5px",
                              color: "black",
                            }}
                          >
                            <b> Oops ! No events found. Kindly create one by clicking on the Add Event button displayed on screen</b>
                          </p>
                        ) : (
                          <>
                            {this.state.Loading === true ? null : (
                              <div className="p-input-icon-right bg-white-table" style={{ padding: "10px 20px", width: "100%" }}>
                                <i className="pi pi-search" />
                                <InputText
                                  type="search"
                                  onInput={this.onInputChange}
                                  placeholder="Search by Event name, Location or Event date"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            )}
                            <div className="">{this.state.Loading === true ? spinnerDiv : tableData}</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <Modal className="event-modal" show={this.state.showModal}>
                  <SendEmail onRefreshClick={this.onRefreshClick} onCloseClick={this.onCloseClick} />
                </Modal>
                <Modal className="event-modal" show={this.state.showScannerCodeModal}>
                  <ScannerCode
                    className="barcode-popup"
                    PromoCode={this.state.PromoCode}
                    EventName={this.state.EventName}
                    onCloseClick={this.onCloseClickScannerCodeModal}
                  />
                </Modal>
                <Footer></Footer>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(UserEvent);
