import axios from "axios";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import PhotoSlider from "../Event/PhotoSlider";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import EventCalenderCard from "../assets/images/Events calender card.svg";
import Photoscard from "../assets/images/Photos card.svg";
import plusicon from "../assets/images/Plus Icon.svg";
import Refresh from "../assets/images/Refresh Icon.svg";
import SearchImage from "../assets/images/Search.png";
import Userscard from "../assets/images/Users card.svg";
import VideosIco from "../assets/images/VideosIco.png";
import ViewEventsIcon from "../assets/images/eye.svg";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      EventPhotoDetails: [],
      ToggleSideBar: true,
      sidebarvalue: 1,
      Loading: false,
      EventList: [],
      DashboardList: [],
      DashboardPageList: [],
      GetTopThreeEvent: [],
      SearchValue: "",
      ToggleSideBarNav: false,
      noDataAvailable: false,
      showOnlyImage: false,
    };
  }
  onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      this.setState(
        {
          ToggleSideBarNav: !this.state.ToggleSideBarNav,
        },
        () => {
          localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
          if (this.state.ToggleSideBarNav) {
            this.setState({
              ToggleSideBarNav: !this.state.ToggleSideBarNav,
            });
            document.body.classList.remove("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
          } else {
            document.body.classList.add("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
          }
        }
      );
    } else {
      localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
      if (this.state.ToggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
      }
      this.setState({
        ToggleSideBarNav: !this.state.ToggleSideBarNav,
      });
    }
  };
  onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav == 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
    }
  };
  componentDidMount() {
    let sidebar = localStorage.getItem("sidebar");
    if (sidebar == undefined || sidebar == null) {
      sidebar = false;
    }

    let windowWidth = window.innerWidth;
    if (sidebar == "false") {
      this.onSideBarToggle1(0);
    } else {
      if (windowWidth < 767.98) {
        this.onSideBarToggle1(0);
      } else {
        this.onSideBarToggle1(1);
      }
    }
    this.getDashboardEvents();
    this.dashboardUser();
    this.getEventTopThree();

    document.body.classList.remove("login-page");
    document.body.classList.add("dashboard-page");
  }

  getDashboardEvents = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetDashboardEvents`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    this.setState({
      Loading: true,
    });

    axios(config)
      .then((response) => {
        this.setState({
          EventList: [],
        });
        response.data.forEach((element) => {
          this.state.EventList.push({
            Id: element.EventId,
            Name: element.Name,
            Location: element.Location,
            Description: element.Description,
            EventDateTime: moment(element.EventDateTime).format("LLL"),
          });
        });
        this.setState({
          Loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          DataAvailable: false,
        });
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  getEventTopThree = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetEventTopThree`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.length == 0) {
          this.setState({
            noDataAvailable: true,
          });
        }
        this.setState({
          GetTopThreeEvent: response.data,
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  dashboardUser = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/Dashboard`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          DashboardPageList: response.data[0],
        });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };
  toogleAddClickPopup = () => {
    window.location.pathname = "/AddUserEvent/C/0";
  };

  onRowClick = (e) => {
    this.props.history.push(`/AddUserEvent/E/${e.Id}`);
  };

  onInputChange = (e) => {
    this.setState({
      SearchValue: e.target.value,
    });
  };

  onRefreshClick = () => {
    this.setState({ SearchValue: "" });
    this.getDashboardEvents();
  };

  ToggleSideBar = () => {
    let sidebar = localStorage.getItem("sidebar");
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };

  ViewEvents = () => {
    this.props.history.push("/UserEvent");
  };

  tooglePhotoSliderPopup = (showHide) => {
    this.setState({
      showSlider: showHide,
      playshowSlider: showHide,
      ErrorMessage: "",
    });
    if (this.state.playshowSlider) {
      this.setState({
        playbuttonname: "Play",
      });
    }
  };

  onImageClick = (photo) => {
    var strWindowFeatures = "location=yes,height=1000,width=3000,scrollbars=yes,status=yes";
    window.open(`${photo.highresolution}`, "_blank", strWindowFeatures);
  };

  render() {
    const objvalues = Object.values(this.state.DashboardPageList);
    // const token = CommonValues.GetAuth0Token();
    // if (token == null || token == "") {
    //   return <Redirect to="/" />;
    // }
    let spinnerDiv = (
      <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
        <Spinner animation="border" variant="warning" /> <b>Loading Events</b>
      </div>
    );

    let tableData = (
      <DataTable
        value={this.state.EventList}
        paginator
        rows={10}
        removableSort
        selectionMode="single"
        globalFilter={this.state.SearchValue}
        className="p-datatable-striped p-datatable-hoverable-rows table table-striped table-hover  user-table"
        onRowClick={(e) => {
          this.onRowClick(e.data);
        }}
      >
        <Column field="Name" header="Event Name" sortable></Column>
        <Column field="Location" header="Event Place" sortable></Column>
        <Column field="EventDateTime" header="Event Date" sortable></Column>
      </DataTable>
    );

    return (
      <>
        <main class="main-content-wrapper dashboard">
          <div class="page-body-wrapper">
            <Sidebar ToggleSideBar={this.ToggleSideBar} sidebarvalue={this.state.sidebarvalue} />

            <div className="page-body">
              <div class="row bg-white mobile-navigation align-items-center logo-wrapper box-shadow-header pt-3 pb-3">
                <div class="toggle-sidebar">
                  <a class="toggle-icon" onClick={this.onSideBarToggle}>
                    <span class="one"></span>
                    <span class="two"></span>
                    <span class="three"></span>
                  </a>
                </div>

                <img src={Logo} width="" height="" alt="Participic" class="img-fluid participic-logo" />
              </div>

              <Header
                onToggleButtonClick={this.onToggleButtonClick}
                onSideBarToggle={this.onSideBarToggle}
                EventDashboardValue={this.state.EventDashboardValue}
              />

              <div class="container-fluid">
                <div class="row">
                  <div className="text-right">
                    <button
                      className="btn btn-primary add-event-btn create-event-btn text-11 font-semibold  mt-2"
                      type="submit"
                      onClick={this.toogleAddClickPopup}
                    >
                      <span>
                        <img src={plusicon} className="event-image" />
                      </span>
                      <span>Create Event</span>
                    </button>
                  </div>
                  <div class="col-lg-12 col-12">
                    <div class="row mt-lg-3 mt-2">
                      <div class="col-lg-3 col-sm-6 col-12 mt-lg-0 mt-2">
                        <div class="about-detail-card bg-blue">
                          <div class="about-detail-left">
                            <img src={EventCalenderCard} />
                          </div>
                          <div class="about-detail-right">
                            <span className="text-white text-14">{objvalues[0]}</span>
                            <h3 class="text-34 mt-2">+{objvalues[1]}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12 mt-lg-0 mt-2">
                        <div class="about-detail-card bg-greenblue">
                          <div class="about-detail-left">
                            <img src={Userscard} />
                          </div>
                          <div class="about-detail-right">
                            <span className="text-white text-14">{objvalues[2]}</span>
                            <h3 class="text-26 mt-2">+{objvalues[3]}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12 mt-lg-0 mt-2">
                        <div class="about-detail-card bg-purple">
                          <div class="about-detail-left">
                            <img src={Photoscard} />
                          </div>
                          <div class="about-detail-right">
                            <span className="text-white text-14">{objvalues[4]}</span>
                            <h3 class="mt-2">+{objvalues[5]}</h3>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-6 col-12 mt-lg-0 mt-2">
                        <div class="about-detail-card bg-lightgreenblue">
                          <div class="about-detail-left">
                            <img src={VideosIco} />
                          </div>
                          <div class="about-detail-right">
                            <span className="text-white text-14">{objvalues[6]}</span>
                            <h3 class="text-26 mt-2">+{objvalues[7]}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 mt-4">
                    <div class="bg-white p-3 event-table">
                      <div class="row align-items-center mobile-m">
                        <div className="Recent-event">
                          <div class="recent-profile-wrap">
                            <h2 class="text-darkgrey">Recent Events</h2>
                            <div class="form-group has-search w-100">
                              <img src={SearchImage} class="form-control-feedback" />
                              <input
                                type="text"
                                class="form-control text-16"
                                onInput={this.onInputChange}
                                placeholder="Search event by Name, Place & Date"
                                name="SearchValue"
                                value={this.state.SearchValue}
                              />
                            </div>
                          </div>
                        </div>

                        <div class="refresh-btn-wrapper">
                          <div class="bttn-group-space">
                            <button class="btn btn-primary dash-refresh-btn text-11 font-semibold " type="submit" onClick={this.onRefreshClick}>
                              <span>
                                <img src={Refresh} className="refresh-icon" />
                              </span>
                              Refresh
                            </button>
                          </div>
                        </div>
                      </div>
                      {this.state.DataAvailable ? (
                        <p style={{ textAlign: "center", paddingTop: "5px" }}>
                          {" "}
                          <b>
                            <p style={{ textAlign: "center", paddingTop: "5px" }}>
                              {" "}
                              <b>Oops ! No Events found to display. Kindly create one by clicking on the create button displayed on screen</b>
                            </p>
                          </b>
                        </p>
                      ) : (
                        <>
                          <div className="card-body p-0 mt-4 table-align table-responsive" style={{ textAlign: "center" }}>
                            {this.state.Loading === true ? spinnerDiv : tableData}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div class="col-lg-3 mt-4">
                    <div class="dash-top-event p-3">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="text-darkgrey  font-semibold ">Top Photos / Video</div>
                        </div>
                      </div>
                      <div class="row">
                        {this.state.noDataAvailable ? (
                          <div class="my-profile-user-info profile h-100">
                            <div class="row align-items-center mt-4">
                              <b
                                style={{
                                  textAlign: "center",
                                  color: "black",
                                }}
                              >
                                {" "}
                                Oops ! No Top Photos / Video are found.
                              </b>
                            </div>

                            <button class="btn btn-primary view-events-btn text-11 font-semibold mt-3 mb-2" type="submit" onClick={this.ViewEvents}>
                              <span>
                                <img src={ViewEventsIcon} className="pr-1 eyeicondashboard" />
                              </span>
                              View events
                            </button>
                          </div>
                        ) : (
                          <></>
                        )}
                        {this.state.GetTopThreeEvent.map((photo, index) => {
                          return (
                            <>
                              <div class="col-sm-4 col-lg-12" style={{ color: "red" }} key={index}>
                                {photo.ImageType == "V" ? (
                                  <video class="img-fluid event-image-height mb-4" controls>
                                    <source
                                      src={photo.PhotoUrl}
                                      type="video/mp4"
                                      onClick={() => {
                                        this.onImageClick(photo);
                                      }}
                                    />
                                  </video>
                                ) : (
                                  <img
                                    class="dashboard-top-event-img img-fluid"
                                    src={photo.PhotoUrl}
                                    onClick={() => this.onImageClick(photo)}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </div>
                            </>
                          );
                        })}
                        <Modal
                          show={this.state.showSlider}
                          onHide={() => this.tooglePhotoSliderPopup(false)}
                          dialogClassName="modal-dialog modal-fullscreen overflow-hidden"
                        >
                          <PhotoSlider
                            PhotoObject={this.state.PhotoObject}
                            EventPhotoDetails={this.state.EventPhotoDetails}
                            tooglePhotoSliderPopup={this.tooglePhotoSliderPopup}
                            showOnlyImage={this.state.showOnlyImage}
                          />
                          <div class="modal-footer"></div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer></Footer>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Dashboard;
