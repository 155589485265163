import axios from "axios";
import moment from "moment";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import React, { Component } from "react";
import { Badge, Modal, Spinner } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { isAndroid, isIOS } from "react-device-detect";
import UserIcon from "../../src/assets/images/user.png";
import CommonValues from "../Common/Util";
import EmptyLikeIcon from "../assets/images/EmptyLike1.png";
import LikeIcon from "../assets/images/Like.png";
import Refresh from "../assets/images/Refresh Icon.svg";
import ThreeDots from "../assets/images/Three_Dots.png";
import PhotoSlider from "./PhotoSlider";
class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RoleId: 0,
      showLoadMore: true,
      EventPhotoDetails: [],
      CreateDownloadPhotoZip: [],
      checked: false,
      EventPhotoId: "",
      onCheckboxChange: false,
      isAllSelect: false,
      Loading: false,
      LoadingMessage: "",
      showSlider: false,
      playshowSlider: false,
      playbuttonname: "Play",
      PhotoObject: {},
      showApproveRejectButton: false,
      CanDownloadPhoto: false,
      LoadingValue: 0,
      showActionButton: true,
      ErrorMessage: "",
      showOnlyImage: false,
      noOfImages: 12,
      pageNumber: 1,
      count: 12,
      TotalRowCount: 0,
      noDataAvailable: false,
      LoadMoreLoading: false,
      playLoading: false,
      exportLoading: false,
      IsEventHost: false,
      sortingList: [
        {
          Id: 0,
          ShortName: "A",
          Name: "--All--",
        },
        {
          Id: 1,
          ShortName: "UTA",
          Name: "Uploaded Time Ascending",
        },
        {
          Id: 2,
          ShortName: "UTD",
          Name: "Uploaded Time Descending",
        },
        {
          Id: 3,
          ShortName: "LRA",
          Name: "Likes Ranking Ascending",
        },
        {
          Id: 4,
          ShortName: "LRD",
          Name: "Likes Ranking Descending",
        },
      ],
      filterList: [
        {
          Id: 0,
          ShortName: "NN",
          Name: "--All--",
        },
        {
          Id: 1,
          ShortName: "A",
          Name: "Approved",
        },
        {
          Id: 2,
          ShortName: "R",
          Name: "Rejected",
        },
      ],
      UserList: [
        {
          UserId: 0,
          FirstName: "",
        },
      ],
      sortById: 0,
      uploadedById: 0,
      filterById: 0,
      showLoading: false,
      showExportToCSV: true,
      CanApprove: null,
      CSVData: [],
      headers: [
        { label: "FirstName", key: "FirstName" },
        { label: "LastName", key: "LastName" },
        { label: "EmailId", key: "EmailId" },
        { label: "MobileNo", key: "MobileNo" },
        { label: "DOB", key: "DOB" },
        { label: "CountryName", key: "CountryName" },
        { label: "StateName", key: "StateName" },
      ],
    };
    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    const RoleId = CommonValues.GetRoleId();
    this.setState(
      {
        RoleId: RoleId,
      },
      () => {
        if (RoleId === null) {
          this.setState({ RoleId: 1 });
        }
      }
    );
    this.GetEventPhotoByEventId(1, this.props.EventId, this.state.sortById, this.state.uploadedById, this.state.filterById, 0);
    this.getUploadedByUsers();
    this.setState({
      CanDownloadPhoto: this.props.IsZipDownload,
    });
  }
  getUploadedByUsers = () => {
    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetUploadUser?EventId=${this.props.EventId}`,
    };
    this.setState({
      Loading: true,
    });
    axios(config)
      .then((response) => {
        const user = [];
        user.push({
          UserId: 0,
          FirstName: "--All--",
        });
        const userList = [];
        response.data.forEach((element) => {
          userList.push(element);
        });
        const finalUserList = [...user, ...userList];
        if (finalUserList.length === 1) {
          this.setState({
            showExportToCSV: false,
          });
        }
        this.setState({
          UserList: finalUserList,
          Loading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting all users",
          });
        }
      });
  };

  newwindowopen = () => {
    this.setState({
      playshowSlider: false,
      playbuttonname: "Play",
    });
    window.focus();
  };

  onPlayClick = () => {
    // this.setState({
    //   playshowSlider: true,
    // });
    window.open(`/photoslider/${this.props.EventId}`, "_blank");

    // var strWindowFeatures =
    //   "location=yes,height=1000,width=3000,scrollbars=yes,status=yes";
    // window.open(
    //   `/photoslider/${this.props.EventId}`,
    //   "_blank",
    //   strWindowFeatures
    // );
  };

  LoadMore = () => {
    this.setState({
      noOfImages: this.state.noOfImages + 12,
      LoadMoreLoading: true,
      showLoading: false,
    });
    this.setState(
      {
        pageNumber: this.state.pageNumber + 1,
      },
      () => {
        this.GetEventPhotoByEventId(
          this.state.pageNumber,
          this.props.EventId,
          this.state.sortById,
          this.state.uploadedById,
          this.state.filterById,
          0
        );
      }
    );
    this.setState({
      Loading: false,
    });
  };

  CheckToShowHideButton = () => {
    this.state.EventPhotoDetails.forEach((photo) => {
      if (photo.Status === null) {
        this.setState({
          showApproveRejectButton: true,
        });
      }
    });
  };

  GetEventPhotoByEventId = (pageNumber, EventId, sortById, uploadedById, filterById, showLoading) => {
    var config = {
      method: "get",
      url:
        process.env.REACT_APP_EVENT_API +
        `Event/GetEventPhotoByEventIdTopTen?PageNumber=${pageNumber}&Count=${this.state.count}&eventId=${EventId}&sortById=${sortById}&uploadedById=${uploadedById}&filterById=${filterById}`,
      headers: {},
    };

    if (this.state.pageNumber === 1 && showLoading === 0) {
      this.setState({
        Loading: true,
        LoadingMessage: "Getting Photos",
      });
    } else {
      if (showLoading !== 0) {
        this.setState({
          showLoading: true,
        });
      }
    }

    axios(config)
      .then((response) => {
        if (response.data.length > 0) {
          this.setState(
            {
              TotalRowCount: response.data[0].TotalRowCount,
              showActionButton: true,
              IsEventHost: response.data[0].IsEventHost,
            },
            () => {
              if (this.state.TotalRowCount === 0) {
                this.setState({
                  showLoadMore: false,
                  noDataAvailable: false,
                });
              }
            }
          );
        }
        if (this.state.EventPhotoDetails.length === 0 && response.data.length === 0) {
          this.setState({
            showActionButton: false,
            Loading: false,
            LoadingMessage: "",
            LoadMoreLoading: false,
            sortById: sortById,
            uploadedById: uploadedById,
            filterById: filterById,
            showLoading: false,
            noDataAvailable: true,
            showLoadMore: false,
          });
        }
        if (this.state.EventPhotoDetails.length > 0 && response.data.length === 0) {
          this.setState({
            showActionButton: false,
            noDataAvailable: true,
          });
        }
        if (this.state.EventPhotoDetails.length > 0 && response.data.length < 12) {
          this.setState({
            showLoadMore: false,
          });
        } else {
          if (this.state.EventPhotoDetails.length === 0 && response.data.length === 0) {
            this.setState({
              noDataAvailable: true,
              showLoadMore: false,
            });
          } else if (this.state.EventPhotoDetails.length > 0 && response.data.length > 0) {
            if (response.data[0].TotalRowCount === 0) {
              this.setState({
                showLoadMore: false,
              });
            } else {
              this.setState({
                showLoadMore: true,
              });
            }
          } else if (this.state.EventPhotoDetails.length === 0 && response.data.length > 0) {
            if (response.data[0].TotalRowCount === 0) {
              this.setState({
                showLoadMore: false,
              });
            } else {
              this.setState({
                showLoadMore: true,
              });
            }
          } else {
            this.setState({
              showLoadMore: true,
            });
          }
        }
        if (this.state.EventPhotoDetails.length === 0 && response.data.length > 0)
          if (this.state.EventPhotoDetails.length === 0 && response.data.length < 12) {
            this.setState({
              showLoadMore: false,
            });
          } else {
            this.setState({
              noDataAvailable: false,
            });
          }
        this.setState(
          {
            EventPhotoDetails: this.state.pageNumber === 1 ? response.data : [...this.state.EventPhotoDetails, ...response.data],
            Loading: false,
            LoadingMessage: "",
            LoadMoreLoading: false,
            sortById: sortById,
            uploadedById: uploadedById,
            filterById: filterById,
            showLoading: false,
          },
          () => {}
        );
        if (this.state.isAllSelect) {
          var eventState = [...this.state.EventPhotoDetails];
          eventState.forEach((photo) => {
            photo.IsSelected = this.state.isAllSelect;
          });
          this.setState({ EventPhotoDetails: eventState });
        } else {
          var eventState1 = [...this.state.EventPhotoDetails];
          this.setState({ EventPhotoDetails: eventState1 });
        }
        this.CheckToShowHideButton();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get all event data",
            LoadMoreLoading: false,
          });
        }
      });
  };
  CreateCSVDataForUploadedByUser = () => {
    this.setState({
      exportLoading: true,
    });

    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    var axios = require("axios");

    var config = {
      method: "get",
      url: process.env.REACT_APP_EVENT_API + `Event/GetUserCSV?EventId=${this.props.EventId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({ CSVData: response.data }, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  CreateDownloadPhotoZip = () => {
    const token = CommonValues.GetAuth0Token();
    let ChangingURL = `${process.env.REACT_APP_MEDIA_API}`;
    this.setState({
      Loading: true,
      LoadingMessage: "Photos downloading",
    });
    var config = {
      method: "get",
      url: `${ChangingURL}api/Event/CreateDownloadPhotoZip?EventId=${this.props.EventId}&UploadedById=${this.state.uploadedById}&StatusById=${this.state.filterById}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.setState({
          CreateDownloadPhotoZip: response.data,
          Loading: false,
        });

        window.location.href = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onCheckboxChange = (e, photo) => {
    var eventState = [...this.state.EventPhotoDetails];
    eventState.forEach((ph) => {
      if (photo === ph) {
        photo.IsSelected = e.target.checked;
      }
    });

    this.setState({ EventPhotoDetails: eventState });
  };

  onApproveClick = (EventPhotoId, index) => {
    this.setState({
      Loading: true,
      LoadingMessage: "Approving",
    });
    var data = JSON.stringify({
      EventPhotoId: EventPhotoId,
      Status: "A",
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_EVENT_API + `Event/EventPhotoApproveReject?EventId=${this.props.EventId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.state.EventPhotoDetails.map((element, index) => {
          if (element.EventPhotoId === EventPhotoId) {
            this.state.EventPhotoDetails[index].Status = "A";
            element.IsSelected = false;
          }
        });
        this.setState({
          Loading: false,
          LoadingMessage: "",
          isAllSelect: false,
          ErrorMessage: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while Approving Photo",
          });
        }
      });

    this.props.componentDidMount();
  };

  onRejectClick = (EventPhotoId, index) => {
    this.setState({
      Loading: true,
      LoadingMessage: "Rejecting",
    });
    var data = JSON.stringify({
      EventPhotoId: EventPhotoId,
      Status: "R",
    });
    var config = {
      method: "post",
      url: process.env.REACT_APP_EVENT_API + `Event/EventPhotoApproveReject?EventId=${this.props.EventId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.state.EventPhotoDetails.map((element, index) => {
          if (element.EventPhotoId === EventPhotoId) {
            this.state.EventPhotoDetails[index].Status = "R";
            element.IsSelected = false;
          }
        });

        this.setState({
          Loading: false,
          LoadingMessage: "",
          isAllSelect: false,
          ErrorMessage: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while Rejecting Photo",
          });
        }
      });

    this.props.componentDidMount();
  };

  onCheckAll = (e, eventPhotoDetails) => {
    var eventState = [...eventPhotoDetails];
    eventState.forEach((photo) => {
      photo.IsSelected = e.target.checked;
    });
    this.setState({
      EventPhotoDetails: eventState,
      isAllSelect: e.target.checked,
    });
  };

  onApproveRejectClick = (val, eventPhotoDetails) => {
    var eventState = [...eventPhotoDetails];
    var selectedData = eventState.filter((event) => event.IsSelected === true) || [];

    if (val === "A") {
      this.onApproveAllClick(selectedData);
    } else {
      this.onRejectAllClick(selectedData);
    }
  };

  onApproveAllClick = (data) => {
    var config = {
      method: "put",
      url: process.env.REACT_APP_EVENT_API + `Event/EventPhotoApproveRejectAll?status=A&EventId=${this.props.EventId}`,
      headers: {},
      data: data,
    };

    this.setState({
      Loading: true,
      LoadingMessage: "Approving",
    });
    axios(config)
      .then((response) => {
        this.setState({
          Loading: false,
          LoadingMessage: "",
        });
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            Loading: false,
            LoadingMessage: "",
          });
          this.onRefreshClick();
        }
      });
  };

  onRejectAllClick = (data) => {
    var config = {
      method: "put",
      url: process.env.REACT_APP_EVENT_API + `Event/EventPhotoApproveRejectAll?status=R&EventId=${this.props.EventId}`,
      headers: {},
      data: data,
    };

    this.setState({
      Loading: true,
      LoadingMessage: "Rejecting",
    });
    axios(config)
      .then((response) => {
        this.setState({
          Loading: false,
          LoadingMessage: "",
        });
        this.onRefreshClick();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            Loading: false,
            LoadingMessage: "",
          });
        }
      });
  };

  tooglePhotoSliderPopup = (showHide) => {
    this.setState({
      showSlider: showHide,
      playshowSlider: showHide,
      ErrorMessage: "",
    });
    if (this.state.playshowSlider) {
      this.setState({
        playbuttonname: "Play",
      });
    }
  };

  onImageClick = (photo) => {
    console.log("photo", photo.highresolution);
    var strWindowFeatures = "location=yes,height=1000,width=3000,scrollbars=yes,status=yes";
    window.open(`${photo.highresolution}`, "_blank", strWindowFeatures);
  };
  confirmSetCoverPhoto = (EventID, photo) => {
    {
      confirmDialog({
        message: `Would you like to use this image as your cover photo?`,
        header: "Set As cover photo",
        icon: "pi pi-images",
        draggable: false,
        resizable: false,
        accept: () => this.SetCoverphotoClick(EventID, photo),
        reject: () => this.rejectFunc(),
      });
    }
  };
  rejectFunc = () => {};

  confirmApproveOrReject = (photo, val) => {
    this.setState({
      LoadingValue: photo.EventPhotoId,
    });
    if (val === "A") {
      confirmDialog({
        message: `Do you want to approve?`,
        header: "Approve Confirmation",
        icon: "pi pi-info-circle",
        className: "errorpopup",
        draggable: false,
        resizable: false,
        accept: () => this.onApproveClick(photo.EventPhotoId),
        reject: () => this.rejectFunc(),
      });
    } else {
      confirmDialog({
        message: `Do you want to reject?`,
        header: "Reject Confirmation",
        icon: "pi pi-info-circle",
        className: "errorpopup",
        draggable: false,
        resizable: false,
        accept: () => this.onRejectClick(photo.EventPhotoId),
        reject: () => this.rejectFunc(),
      });
    }
  };
  handleChangeSortBy = (e) => {
    this.setState({ ErrorMessage: "" });
    if (e.target.value === 0) {
      this.setState({
        sortCss: 0,
      });
    } else {
      this.setState({
        sortCss: 1,
      });
    }
    this.setState(
      {
        pageNumber: 1,
        sortById: e.target.value,
      },
      () => {
        this.GetEventPhotoByEventId(
          this.state.pageNumber,
          this.props.EventId,
          this.state.sortById,
          this.state.uploadedById,
          this.state.filterById,
          1
        );
      }
    );
  };
  handleChangeUploadedBy = (e) => {
    this.setState({ ErrorMessage: "" });
    if (e.target.value === 0) {
      this.setState({
        uploadedCss: 0,
      });
    } else {
      this.setState({
        uploadedCss: 1,
      });
    }
    this.setState(
      {
        pageNumber: 1,
        uploadedById: e.target.value,
      },
      () => {
        this.GetEventPhotoByEventId(
          this.state.pageNumber,
          this.props.EventId,
          this.state.sortById,
          this.state.uploadedById,
          this.state.filterById,
          1
        );
      }
    );
  };
  handleChangeFilterBy = (e) => {
    this.setState({ ErrorMessage: "" });
    if (e.target.value === 0) {
      this.setState({
        filterCss: 0,
      });
    } else {
      this.setState({
        filterCss: 1,
      });
    }
    this.setState(
      {
        pageNumber: 1,
        filterById: e.target.value,
      },
      () => {
        this.GetEventPhotoByEventId(
          this.state.pageNumber,
          this.props.EventId,
          this.state.sortById,
          this.state.uploadedById,
          this.state.filterById,
          1
        );
      }
    );
  };
  onClearClick = () => {
    this.setState(
      {
        isAllSelect: false,
        sortById: 0,
        sortCss: 0,
        uploadedById: 0,
        uploadedCss: 0,
        filterById: 0,
        filterCss: 0,
        pageNumber: 1,
      },
      () => {
        this.GetEventPhotoByEventId(1, this.props.EventId, this.state.sortById, this.state.uploadedById, this.state.filterById, 0);
      }
    );
  };

  onRefreshClick = () => {
    this.setState(
      {
        pageNumber: 1,
        ErrorMessage: "",
      },
      () => {
        this.GetEventPhotoByEventId(
          this.state.pageNumber,
          this.props.EventId,
          this.state.sortById,
          this.state.uploadedById,
          this.state.filterById,
          0
        );
      }
    );
  };

  SetCoverphotoClick = (EventID, photo) => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");

    var axios = require("axios");
    var config = {
      method: "put",
      url:
        process.env.REACT_APP_EVENT_API + `Event/UpdateCoverPhoto?EventId=${EventID}&EventphotoId=${photo.EventPhotoId}&StatusType=${photo.Status}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response) => {
        this.onRefreshClick();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let showExportToCSV = true;
    if (this.state.UserList.length === 1) {
      showExportToCSV = false;
    }
    let spinnerDivForNoDataAvailble = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div style={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="border" variant="warning" /> <b>{this.state.LoadingMessage}</b>
        </div>
      </div>
    );
    let spinnerDiv = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="border" variant="warning" /> <b>{this.state.LoadingMessage}</b>
        </div>
      </div>
    );
    let spinnerDivForUploading = (
      <div className="img-fluid event-image-height" style={{ textAlign: "center" }}>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: "20%",
          }}
        >
          <Spinner animation="border" variant="warning" /> <b>Uploading</b>
        </div>
      </div>
    );
    let PhotoDetailsData = (
      <div class="create-event-wrapper py-4 px-3 mt-4">
        {this.state.noDataAvailable ? (
          <div class="row align-items-center mt-4">
            <b style={{ textAlign: "center" }}> Oops ! No photos found.</b>
          </div>
        ) : (
          <></>
        )}

        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12"></div>
            {this.state.EventPhotoDetails.map((photo, index) => {
              let UserPhotoImage = false;
              let str = photo.ProfileImageURL;
              let items = [".jpeg", ".jpg", ".png", ".svg", ".JPEG", ".JPG", ".PNG", ".SVG"];
              for (var i in items) {
                var item = items[i];
                if (str.includes(item)) {
                  UserPhotoImage = true;
                }
              }
              return (
                <>
                  <div class="col-lg-3 col-md-6 mb-4 " key={new Date().toString()}>
                    <div>
                      <div
                        className={
                          photo.Status === "R"
                            ? "important-info-start-bg-stick-Rejected"
                            : photo.Status === "A"
                            ? "important-info-start-bg-stick-Approved"
                            : "important-info-start-bg-stick"
                        }
                      >
                        <div>
                          <input
                            hidden={photo.IsCoverPhoto}
                            key={photo.EventPhotoId}
                            id={photo.EventPhotoId}
                            type="checkbox"
                            name="IsSelected"
                            checked={photo.IsSelected}
                            value={photo.EventPhotoId}
                            onChange={(event) => this.onCheckboxChange(event, photo)}
                            style={{
                              marginRight: "15px",
                              cursor: "pointer",
                            }}
                          />
                          {photo.Status === null ? (
                            "Pending for approval"
                          ) : (
                            <>{photo.Status === "A" ? "Approved" : photo.Status === "R" ? "Rejected" : null}</>
                          )}
                          {this.state.LoadingValue === photo.EventPhotoId ? this.state.Loading === true ? spinnerDiv : <></> : <></>}
                        </div>
                        <div class="pending-for-approval">
                          {photo.Status === null ? (
                            <>
                              <button
                                hidden={photo.IsCoverPhoto}
                                className="p-mr-2 photo-gallery-approve-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.confirmApproveOrReject(photo, "A")}
                              >
                                <i class="pi pi-check" title="Approve"></i>
                              </button>
                              <button
                                hidden={photo.IsCoverPhoto}
                                className="photo-gallery-approve-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.confirmApproveOrReject(photo, "R")}
                              >
                                <i hidden={photo.IsCoverPhoto} class="pi pi-times" title="Reject"></i>
                              </button>
                              {(this.state.RoleId === 1 || this.state.IsEventHost) && photo.Status === "A" && photo.ImageType === "P" ? (
                                <img
                                  alt=""
                                  class="like-img ml-2"
                                  color="black"
                                  title="Set cover photo"
                                  src={ThreeDots}
                                  onClick={() => this.confirmSetCoverPhoto(this.props.EventId, photo)}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <>
                              <div class="edit-icon">
                                {" "}
                                {this.props.CanApprove === true ? (
                                  photo.Status === "A" ? (
                                    <button
                                      hidden={photo.IsCoverPhoto}
                                      className="photo-gallery-approve-icon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.confirmApproveOrReject(photo, "R")}
                                    >
                                      <i class="pi pi-times" title="Reject"></i>
                                    </button>
                                  ) : (
                                    <button
                                      className="p-mr-2 photo-gallery-approve-icon"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.confirmApproveOrReject(photo, "A")}
                                    >
                                      <i class="pi pi-check" title="Approve"></i>
                                    </button>
                                  )
                                ) : null}
                              </div>
                              <div className="like-button">
                                <p className="mb-0 text-white">
                                  {photo.LikeCount > 0 ? (
                                    <Badge bg="danger" className="like-counter">
                                      {photo.LikeCount}
                                    </Badge>
                                  ) : (
                                    <></>
                                  )}

                                  <img class="like-img ml-2" src={photo.IsLike ? LikeIcon : EmptyLikeIcon} alt="" />
                                </p>
                              </div>
                              {(this.state.RoleId === 1 || this.state.IsEventHost) && photo.Status === "A" && photo.ImageType === "P" ? (
                                <img
                                  alt=""
                                  class="like-img ml-2"
                                  color="black"
                                  title="Set cover photo"
                                  src={ThreeDots}
                                  onClick={() => this.confirmSetCoverPhoto(this.props.EventId, photo)}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {photo.Uploaded ? (
                        photo.ImageType === "V" ? (
                          <video class="img-fluid event-image-height" controls>
                            <source src={photo.lowresolution} type="video/mp4" />
                          </video>
                        ) : (
                          <div className="event-image-div">
                            <img
                              alt=""
                              class="img-fluid event-image-height"
                              src={photo.lowresolution}
                              onClick={() => this.onImageClick(photo)}
                              style={{
                                cursor: "pointer",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        )
                      ) : (
                        spinnerDivForUploading
                      )}

                      <div
                        className={
                          photo.Status === "R"
                            ? "image-footer important-info-start-bg-stick-Rejected bottom-header-rejected"
                            : photo.Status === "A"
                            ? "image-footer important-info-start-bg-stick-Approved bottom-header-Approved"
                            : "image-footer important-info-start-bg-stick bottom-header"
                        }
                      >
                        <div className=" d-flex align-items-center  ">
                          <img class="user-header-img" src={UserPhotoImage ? photo.ProfileImageURL : UserIcon} alt="" />
                          <div>
                            <p className="mb-0 text-white ">{photo.UserName === "" ? "Anonymous" : photo.UserName}</p>

                            {photo.IsCoverPhoto ? <p className="cover-photo-text"> Cover Photo</p> : null}
                          </div>
                        </div>

                        <div className=" text-right">
                          {" "}
                          <p className="mb-0 text-white text-11 para-width">{moment.utc(photo.UploadTime).local().format("LLL")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <div className="row justify-content-center">
              <div className="col-md-12 text-center">
                {this.state.showLoadMore ? (
                  <Button
                    label="Load More"
                    loading={this.state.LoadMoreLoading}
                    className="btn btn-primary btn-sm btn-loadmore"
                    onClick={this.LoadMore}
                  ></Button>
                ) : null}
              </div>
            </div>
          </div>
          {this.state.playshowSlider ? (
            isAndroid || isIOS ? (
              <Modal
                show={this.state.playshowSlider}
                onHide={() => this.tooglePhotoSliderPopup(false)}
                dialogClassName="modal-dialog modal-fullscreen overflow-hidden"
              >
                <PhotoSlider
                  EventId={this.props.EventId}
                  PhotoObject={this.state.PhotoObject}
                  EventPhotoDetails={this.state.EventPhotoDetails}
                  tooglePhotoSliderPopup={() => this.tooglePhotoSliderPopup(false)}
                  showOnlyImage={this.state.showOnlyImage}
                />
                <div class="modal-footer"></div>
              </Modal>
            ) : (
              <PhotoSlider
                EventId={this.props.EventId}
                PhotoObject={this.state.PhotoObject}
                EventPhotoDetails={this.state.EventPhotoDetails}
                tooglePhotoSliderPopup={() => this.tooglePhotoSliderPopup(false)}
                showOnlyImage={this.state.showOnlyImage}
              />
            )
          ) : null}
          <Modal
            show={this.state.showSlider}
            onHide={() => this.tooglePhotoSliderPopup(false)}
            dialogClassName="modal-dialog modal-fullscreen overflow-hidden"
          >
            <PhotoSlider
              key={new Date().toString()}
              EventId={this.props.EventId}
              PhotoObject={this.state.PhotoObject}
              EventPhotoDetails={this.state.EventPhotoDetails}
              tooglePhotoSliderPopup={() => this.tooglePhotoSliderPopup(false)}
              showOnlyImage={this.state.showOnlyImage}
            />
            <div class="modal-footer"></div>
          </Modal>
          {this.props.showTab ? (
            <>
              <div className="row"></div>
              <br />
            </>
          ) : null}
        </div>
      </div>
    );

    return (
      <>
        {this.state.Loading ? (
          <p
            style={{
              textAlign: "center",
              paddingTop: "5px",
              color: "black",
            }}
          >
            {spinnerDiv}
          </p>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                {this.state.ErrorMessage !== "" ? (
                  <>
                    <div class="alert alert-danger d-flex align-items-center mr-3" role="alert">
                      <div className="text-14">{this.state.ErrorMessage}</div>
                    </div>
                  </>
                ) : null}
                <div id="container"></div>
              </div>
            </div>

            <div class="row align-items-center mt-4">
              {this.state.showActionButton ? (
                <div class="col-md-12 col-lg-6 d-flex col-12 mt-2 mobile-center">
                  <div class=" bd-highlight ">
                    <button
                      class="btn btn-primary dash-refresh-btn text-13 font-semibold mr-2 "
                      type="submit"
                      onClick={() => this.onApproveRejectClick("R", this.state.EventPhotoDetails)}
                    >
                      Reject All{" "}
                    </button>
                  </div>
                  <div class="bd-highlight">
                    <button
                      class="p-button p-component p-button-primary mr-1 text-13 font-500"
                      type="submit"
                      onClick={() => this.onApproveRejectClick("A", this.state.EventPhotoDetails)}
                    >
                      {" "}
                      Approve All
                    </button>
                  </div>
                  {this.state.showActionButton ? (
                    this.props.IsZipDownload ? (
                      <div class=" bd-highlight mr-2">
                        <button className="btn btn-primary dash-refresh-btn text-14 font-semibold mr-2" onClick={this.CreateDownloadPhotoZip}>
                          Download
                        </button>
                      </div>
                    ) : null
                  ) : null}
                </div>
              ) : null}
              {this.state.showActionButton ? <></> : <div className="col-md-6"></div>}

              <div class="col-md-12 col-lg-6  d-flex align-items-center col-12 mt-2 mobile-center-exportbutton">
                {this.state.showActionButton ? (
                  <div class=" align-items-center photogallery-check d-none d-md-block">
                    <div className=" bd-highlight mr-2">
                      <Button
                        label="Refresh"
                        className="p-button p-component p-button-primary dash-refresh-btn mr-1 text-13"
                        onClick={this.onRefreshClick}
                        title="Refresh Events"
                      >
                        <span>
                          <img src={Refresh} className="refresh-icon" alt="" />
                        </span>
                      </Button>
                    </div>
                    {this.props.CanCsvExport && showExportToCSV ? (
                      <>
                        <div className=" bd-highlight mr-2">
                          <Button
                            label="Export to CSV"
                            className="p-button p-component p-button-primary text-13"
                            onClick={this.CreateCSVDataForUploadedByUser}
                            title="CSV data of uploaded by user"
                          ></Button>
                          <CSVLink headers={this.state.headers} filename="Uploaded User Data.csv" data={this.state.CSVData} ref={this.csvLinkEl} />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <div className=" bd-highlight mr-2">
                      <Button
                        label={this.state.playbuttonname}
                        loading={this.state.playLoading}
                        className="p-button p-component p-button-primary btnplay mr-1 text-13"
                        style={{ cursor: "pointer" }}
                        onClick={this.onPlayClick}
                      ></Button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-lg-12 d-md-none d-block">
                <div class="align-items-center photogallery-check mobile-center-playbutton text-13">
                  {this.props.CanCsvExport && showExportToCSV && this.state.showActionButton ? (
                    <>
                      <Button label="Refresh" className="p-button-primary text-13 mr-2" onClick={this.onRefreshClick} title="Refresh Events"></Button>
                      <Button
                        label="Export to CSV"
                        className="p-button-primary mr-2 text-13"
                        onClick={this.CreateCSVDataForUploadedByUser}
                        title="CSV data of uploaded by user"
                      ></Button>
                      <CSVLink headers={this.state.headers} filename="Uploaded User Data.csv" data={this.state.CSVData} ref={this.csvLinkEl} />
                    </>
                  ) : (
                    <></>
                  )}
                  {this.state.showActionButton ? (
                    <>
                      <Button
                        label={this.state.playbuttonname}
                        loading={this.state.playLoading}
                        className="p-mr-2  text-13  photo-gallery-approve-icon play-photogallery"
                        style={{ cursor: "pointer" }}
                        onClick={this.onPlayClick}
                      ></Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {/* {this.state.noDataAvailable ? (
              <></>
            ) : ( */}
            <div className="container-fluid">
              <div class="row align-items-center mt-4 ">
                <>
                  <div class="col-md-12 col-lg-3 col-12">
                    <label className="form-label text-14 font-semibold text-dark">Sort By</label>
                    <select
                      className={this.state.sortCss ? "form-control-new text-14 font-semibold" : "form-control-new text-14 font-semibold"}
                      value={this.state.sortById}
                      onChange={this.handleChangeSortBy}
                      aria-label=""
                      required
                    >
                      {this.state.sortingList.map((type) => {
                        return <option value={type.Id}>{type.Name}</option>;
                      })}
                    </select>
                  </div>
                  {/* <div className="col-md-1"></div> */}
                  <div class="col-md-12 col-lg-3 col-12">
                    <label className="form-label text-14 font-semibold text-dark">Uploaded By</label>
                    <select
                      className={
                        this.state.uploadedCss
                          ? "form-control-new text-14 font-semibold"
                          : // : "form-control-new1"
                            "form-control-new text-14 font-semibold"
                      }
                      value={this.state.uploadedById}
                      onChange={this.handleChangeUploadedBy}
                      aria-label=""
                      required
                    >
                      {this.state.UserList.map((type) => {
                        return <option value={type.UserId}>{type.FirstName}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 col-lg-3 col-12">
                    <label className="form-label text-14 font-semibold text-dark">Status</label>
                    <select
                      className={
                        this.state.filterCss
                          ? "form-control-new text-14 font-semibold"
                          : // : "form-control-new1"
                            "form-control-new text-14 font-semibold"
                      }
                      value={this.state.filterById}
                      onChange={this.handleChangeFilterBy}
                      aria-label=""
                      required
                    >
                      {this.state.filterList.map((type) => {
                        return <option value={type.Id}>{type.Name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-2">
                    <button class="btn clear-btn mr-2 mt-4" onClick={this.onClearClick}>
                      Clear
                    </button>
                  </div>
                </>
              </div>
            </div>
            {/* )} */}
            {this.state.showLoading ? spinnerDivForNoDataAvailble : PhotoDetailsData}
          </>
        )}
      </>
    );
  }
}
export default PhotoGallery;
