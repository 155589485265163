import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import validator from "validator";
import Footer from "../Common/Footer";
import CommonValues from "../Common/Util";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.state = {
      Newpassword: "",
      Confirmpassword: "",
      Id: 0,
      onSuccess: false,
      ParamsGuid: "",
      email: "",
      Loading: false,
      ErrorMessage: "",
      SuccessMessage: "",
      LoadingMessage: "",
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({
      ParamsGuid: params.guid,
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  validate = () => {
    let valid = true;

    if (this.state.email.trim() == "") {
      this.setState({
        ErrorMessage: "Please enter email address ",
      });
      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      this.setState({
        ErrorMessage: "Please enter email in correct format",
        SuccessMessage: "",
      });
      return false;
    }
    return valid;
  };

  onSaveClick = () => {
    if (this.validate()) {
      var axios = require("axios");

      var data = JSON.stringify({
        email: this.state.email,
      });

      var config = {
        method: "post",
        url: process.env.REACT_APP_API + `User/ForgotPassword`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      this.setState({
        Loading: true,
        ErrorMessage: "",
        LoadingMessage: "please wait",
      });

      axios(config)
        .then((response) => {
          this.setState({
            Loading: false,
            email: "",
            SuccessMessage:
              "A password reset link has been sent successfully to your email address. Simply check your email and follow the procedure.",
            ErrorMessage: "",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    return (
      <body class="login-page">
        <main className="main-content">
          <div className="login-form-wrapper">
            <div className="login-form-radius">
              <div className="login-form-content">
                <a href="/" title="Participic">
                  <img
                    src={Logo}
                    width={320}
                    height={51}
                    alt="Participic"
                    className="logo-fit mt-0"
                  />
                </a>
                <h2 className="text-navyblue text-16 font-semibold">
                  Have you Forgotten your Password? Don’t Worry! Enter Your
                  Email Below to Get Your New Password.
                </h2>
                <div className="login-form">
                  {this.state.Loading === true ? (
                    <div
                      style={{ verticalAlign: "center", textAlign: "center",
                      fontFamily: "primeicon",
                      fontWeight:"700px",
                      color:"black",
                    }}
                    >
                      <Spinner animation="border" variant="secondary" />{" "}
                      <b>{this.state.LoadingMessage}</b>
                    </div>
                  ) : (
                    <>
                      <div className="form-group form-group-design text-left">
                        <label htmlFor="emailid">Email ID</label>
                        <input
                          type="email"
                          className="form-input"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={this.onChange}
                          onKeyPress={this.handleKeypress}
                          value={this.state.email}
                        />
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary btn-lg btn-sign-in"
                        onClick={this.onSaveClick}
                      >
                        Request new password
                      </button>
                      <br />
                      <br />

                      <div className=" ">
                        {this.state.SuccessMessage != "" ? (
                          <div
                            class="alert alert-success d-flex align-items-center justify-content-center"
                            role="alert"
                          >
                            <div className="text-14">
                              {this.state.SuccessMessage}
                            </div>
                          </div>
                        ) : null}
                        {this.state.ErrorMessage != "" ? (
                          <>
                            <div
                              class="alert alert-danger d-flex align-items-center justify-content-center"
                              role="alert"
                            >
                              <div className="text-14">
                                {this.state.ErrorMessage}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
                <Link
                  to="/Login"
                  className="forgot-psw-link text-16 text-blue font-semibold"
                  title="Sign In"
                >
                  Sign In
                </Link>
                <Link
                  to="/UserRegistration"
                  className="new-mbrshp-link text-16 text-blue font-semibold"
                  title="Register a new Membership"
                >
                  Not a Member Yet ? Sign Up
                </Link>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </main>
      </body>
    );
  }
}

export default withRouter(ForgotPassword);
