import React from "react";
import { Redirect, Route } from "react-router-dom";
import Auth from "../Auth/Auth";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  const isAuthenticated = Auth.isAuthenticated();
  return (
    <Route
      {...restOfProps}
      render={(props) => {
        return isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/login" + props.history.location.pathname} />
        );
      }}
    />
  );
}

export default ProtectedRoute;
