import axios from "axios";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import validator from "validator";
import participicimage from "../../src/assets/images/logo/Participiclogo.png";
import Footer from "../Common/Footer";
class Updatepassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      Password: "",
      NewPassword: "",
      ConfirmPassword: "",
      UserId: 0,
      ErrorMessage: "",
      guid: "",
      SuccessMessage: "",
      Loading: false,
      LoadingMessage: "",
      LinkExpired: false,
      Updatepassword: false,
      UpdatepasswordMSG: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({
      guid: params.index,
    });
    this.CheckGuidForForgotPassword(params.index);
  }

  CheckGuidForForgotPassword = (guid) => {
    var data = JSON.stringify({
      IdentificationCode: guid,
    });

    var config = {
      method: "post",
      url: process.env.REACT_APP_API + `User/CheckGuidForForgotPassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.data) {
          this.setState({
            LinkExpired: false,
            Updatepassword: true,
          });
        } else {
          this.setState({
            LinkExpired: true,
            Updatepassword: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.setState({
            LinkExpired: true,
            Updatepassword: false,
          });
        }
      });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleKeypress = (e) => {
    if (e.key == "Enter") {
      this.onSaveClick();
    }
  };

  onSaveClick = () => {
    if (this.validate()) {
      this.setState({ SuccessMessage: "" });
      var axios = require("axios");
      var data = JSON.stringify({
        IdentificationCode: this.state.guid,
        password: this.state.Password,
      });

      var config = {
        method: "post",
        url: process.env.REACT_APP_API + `User/UpdatePassword`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      this.setState({
        Loading: true,
        ErrorMessage: "",
        LoadingMessage: "Your password will be reset shortly.",
      });

      axios(config)
        .then((response) => {
          this.setState({
            SuccessMessage: "Password updated successfully",
            ErrorMessage: "",
            Password: "",
            ConfirmPassword: "",
            Loading: false,
            LoadingMessage: "",
            Updatepassword: false,
            UpdatepasswordMSG: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  validate = () => {
    let valid = true;
    if (this.state.Password == "") {
      this.setState({
        ErrorMessage: "Please enter password",
      });
      return false;
    }

    if (
      validator.isStrongPassword(this.state.Password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      this.setState({
        ErrorMessage:
          "Note: Password must contain minimum of 8 characters and it should have atleast one uppercase, lowercase, number and special character.",
      });
    } else {
      this.setState({
        ErrorMessage:
          "Note: Password must contain minimum of 8 characters and it should have atleast one uppercase, lowercase, number and special character.",
      });
      return false;
    }
    if (
      this.state.ConfirmPassword == "" ||
      this.state.ConfirmPassword == null
    ) {
      this.setState({
        ErrorMessage: "Please enter confirm password",
      });
      return false;
    }

    if (this.state.Password != this.state.ConfirmPassword) {
      this.setState({
        ErrorMessage: "Password do not match",
        SuccessMessage: "",
      });
      return false;
    }
    this.setState({
      ErrorMessage: "",
    });

    return valid;
  };

  render() {
    return (
      <>
        <div class="login-page">
          <main class="main-content">
            <div class="login-form-wrapper">
              <div class="login-form-radius">
                <div class="login-form-content">
                  <a href="/" title="Participic">
                    <img
                      src={participicimage}
                      width="320"
                      height="51"
                      alt="Participic"
                      class="logo-fit mt-0"
                    />
                  </a>
                  {this.state.LinkExpired ? (
                    <b>
                      <h1>Sorry! This link is already expired or invalid!!</h1>
                    </b>
                  ) : null}

                  {this.state.UpdatepasswordMSG ? (
                    <div class="alert alert-success">
                      <div class="text-14">Password updated successfully</div>
                    </div>
                  ) : null}
                  <div>
                    <a
                      href="/"
                      class="forgot-psw-link text-16 text-blue font-semibold"
                      title="Sign In"
                    >
                      Sign In
                    </a>
                  </div>
                  {this.state.Updatepassword ? (
                    <>
                      <h2 class="text-navyblue text-16 font-semibold">
                        You are only one step a way from your new password,
                        Recover your password now.
                      </h2>

                      <div class="login-form">
                        <div class="form-group form-group-design text-left">
                          <label for="password">Password</label>
                          <input
                            type="password"
                            className="form-input"
                            id="Password"
                            name="Password"
                            onChange={(e) => {
                              this.setState({
                                [e.target.name]: e.target.value.trim(),
                              });
                            }}
                            onKeyPress={this.handleKeypress}
                            value={this.state.Password}
                            placeholder="Enter password"
                          />
                        </div>
                        <div class="form-group form-group-design text-left">
                          <label for="password">Confirm Password</label>
                          <input
                            type="password"
                            className="form-input"
                            id="NewPassword"
                            name="ConfirmPassword"
                            onChange={(e) => {
                              this.setState({
                                [e.target.name]: e.target.value.trim(),
                              });
                            }}
                            onKeyPress={this.handleKeypress}
                            value={this.state.ConfirmPassword}
                            placeholder="Enter Confirm password"
                          />
                        </div>
                        <div className="col-md-8"></div>
                        {this.state.Loading == true ? (
                          <div
                            style={{
                              verticalAlign: "center",
                              textAlign: "center",
                            }}
                          >
                            <Spinner animation="border" variant="warning" />{" "}
                            <b>{this.state.LoadingMessage}</b>
                          </div>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-lg btn-sign-in"
                              onClick={this.onSaveClick}
                            >
                              Reset password
                            </button>
                            <br />
                            <br />
                          </>
                        )}

                        <div className="row">
                          <div className="col-md-12">
                            <div className=" ">
                              {this.state.SuccessMessage != "" ? (
                                <div
                                  class="alert alert-success d-flex align-items-center justify-content-center"
                                  role="alert"
                                >
                                  <div className="text-14">
                                    {this.state.SuccessMessage}
                                  </div>
                                </div>
                              ) : null}
                              {this.state.ErrorMessage != "" ? (
                                <>
                                  <div
                                    class="alert alert-danger d-flex align-items-center justify-content-center"
                                    role="alert"
                                  >
                                    <div className="text-14">
                                      {this.state.ErrorMessage}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <Footer></Footer>
            </div>
          </main>
        </div>
      </>
    );
  }
}

export default Updatepassword;
