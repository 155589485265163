import axios from "axios";
import { Component } from "react";
import "../App.css";
import QRCode from "qrcode.react";
class QRCodeViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      temp: "",
      word: "",
      size: 200,
      bgColor: "ffffff",
      qrCode: "",
      promocodeURL: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_EVENT_API}Event/GenerateDynamicLink?PromoCode=${nextProps.PromoCode}&EventId=${nextProps.EventId}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        this.setState({
          promocodeURL: response.data.shortLink,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="card-uploadfile">
        <div className="output-box">
          <QRCode
            value={this.state.promocodeURL}
            // style={{ marginRight: 50 }}
            renderAs="svg"
          />
        </div>
      </div>
    );
  }
}

export default QRCodeViewer;
