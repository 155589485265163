import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { ContentState, EditorState, RichUtils, SelectionState } from "draft-js";

import htmlToDraft from "html-to-draftjs";

import moment from "moment";

import Multiselect from "multiselect-react-dropdown";

import { Button } from "primereact/button";

import { confirmDialog } from "primereact/confirmdialog";

import { Dialog } from "primereact/dialog";

import { Toast } from "primereact/toast";

import React, { Component } from "react";

import { Spinner, Tab, Tabs } from "react-bootstrap";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { geocodeByLatLng } from "react-google-places-autocomplete";

import InputMask from "react-input-mask";

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import { Redirect } from "react-router-dom";

import CommonValues from "../Common/Util";

import Header from "../Header/Header";

import Sidebar from "../Sidebar/Sidebar";

import Map from "./maps";

import PhotoGallery from "./PhotoGallery";

import QRCodeViewer from "./QRCodeViewer";

import Geocode from "react-geocode";
class AddUserEvent extends Component {
  constructor(props) {
    super(props);
    this.onSaveClick = this.onSaveClick.bind(this);

    this.state = {
      isInValidPlacesLocation: false,

      myImage: "",

      srcLink: "",

      link: null,

      src: null,

      saveLoading: false,

      name: "",

      Mode: "",

      ToggleSideBar: true,

      EventId: 0,

      Name: "",

      Description: "",

      Location: "",

      EventDateTime: null,

      EventMinDate: null,

      MinDate: new Date(),

      PromoCode: "",

      CreatedBy: "",

      CreatedDateTime: new Date(),

      ModifiedBy: "",

      ModifiedDateTime: new Date(),

      IsActive: "",

      Loading: false,

      CheckboxLoader: false,

      LoadingMessage: "",

      ModalTitle: "",

      showModal: false,

      ErrorImageMessage: "",

      ErrorMessage: "",

      SuccessMessage: "",

      EventHostList: [],

      EventTime: "",

      selectradius: "Miles",

      CountryList: [],

      EventHostName: "",

      CountryId: 0,

      StateList: [],

      stateedit: true,

      CityList: [],

      IsZipDownload: false,

      cityedit: true,

      StateId: 0,

      CityId: 0,

      sidebarvalue: 2,

      EventDashboardValue: 1,

      DurationTime: "",

      //Photo Gallary Variables

      EventPhotoDetails: [],

      checked: false,

      EventPhotoApproved: "",

      EventPhotoId: "",

      IsApprovalRequired: false,

      CanDownloadPhoto: true,

      CanCsvExport: false,

      isAllSelect: false,

      selectedValues: [],

      showingInfoWindow: false,

      selectedEventPhotoDetails: [],

      showTab: false,

      countryCss: 0,

      stateCss: 0,

      cityCss: 0,

      eventdateCss: 0,

      displaySuccessMessage: false,

      showEventDateError: true,

      useCurrentLocation: true,

      selectPlaceField: false,

      CanEdit: true,

      disableFields: true,

      hidePlaceField: false,

      radiusValue: parseFloat(20000),

      txtradiusValue: "20000",

      address: "",

      waterMarkSetup: {
        WaterMarkColor: "",

        WaterMarkLocation: "",

        WaterMarkText: "",
      },

      selectlocation: "",

      selectcolor: "",

      zoomlevel: 18,

      PhotoCount: 0,

      mapcng: false,

      coordinates: {
        lat: null,

        lng: null,
      },

      mapData: [
        {
          id: 1,

          name: "Park Slope",

          latitude: "40.6710729",

          longitude: "-73.9988001",

          circle: {
            radius: parseFloat(0),

            options: {
              strokeColor: "#ff0000",
            },
          },
        },
      ],

      centerValue: { lat: 40.64, lng: -73.96 },

      ConfigValue: false,

      selectedCheckboxes: [],

      ConfigSetup: [
        {
          Id: 4,

          Name: "Video",

          ConfigValue: false,
        },
      ],

      notfound: false,

      CanApprove: false,

      isImageSizeValid: true,

      EditorDescriptionState: EditorState.createWithContent(ContentState.createFromText(``)),

      CountryArray: [],

      StateArray: [],

      CityArray: [],
      DeactivateComment: false,
      IsEventLocationValid: false,
      IsSubscribed: false,
    };

    this.handleChange = this.handleChange.bind(this);

    this.onTagsChangeForCountry = this.onTagsChangeForCountry.bind(this);

    this.onTagsChangeForState = this.onTagsChangeForState.bind(this);
  }

  setCurrentLocation() {
    Geocode.setApiKey("AIzaSyCjBuA6lcuf0ymkuyIEebbJfzCsoc4gLu8");

    Geocode.setLanguage("en");

    Geocode.setRegion("es");

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          Geocode.fromLatLng(
            position.coords.latitude,

            position.coords.longitude
          ).then((response) => {
            const address = response.results[0].formatted_address;
            localStorage.setItem("initialAddress", address);

            this.setState({
              address: address,

              mapData: [
                {
                  id: 1,

                  name: address,

                  latitude: position.coords.latitude,

                  longitude: position.coords.longitude,

                  circle: {
                    radius: parseFloat(20000),

                    options: {
                      strokeColor: "#ff0000",
                    },
                  },
                },
              ],

              centerValue: {
                lat: position.coords.latitude,

                lng: position.coords.longitude,
              },

              mapcng: false,
            });
          });
        },

        (error) => {
          this.setState({
            ErrorMessage: "Please enable the device location and pop-up window location, and then refresh the page",

            SuccessMessage: "",

            hidePlaceField: true,

            selectPlaceField: true,

            disableFields: true,

            address: "",
          });
        }
      );
    }
  }

  handleCallback = (childData, zoomlevel) => {
    this.setState({
      mapData: childData,

      mapcng: true,

      centerValue: {
        lat: childData[0].latitude,

        lng: childData[0].longitude,
      },

      zoomlevel: zoomlevel,
    });

    geocodeByLatLng({ lat: childData[0].latitude, lng: childData[0].longitude })
      .then((results) =>
        this.setState({
          address: results[0].formatted_address,
        })
      )

      .catch((error) => console.error(error));
  };

  handleAutocompleteChange = (e) => {
    this.setState({ address: e, mapcng: true });
  };

  handelError = () => {
    this.setState({
      isInValidPlacesLocation: true,
    });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))

      .then((latLng) =>
        this.setState({
          address: address,
          isInValidPlacesLocation: false,
          mapData: [
            {
              id: 1,

              name: address,

              latitude: latLng.lat,

              longitude: latLng.lng,

              circle: {
                radius: this.state.radiusValue,

                options: {
                  strokeColor: "#ff0000",
                },
              },
            },
          ],

          centerValue: {
            lat: latLng.lat,

            lng: latLng.lng,
          },

          mapcng: false,
        })
      )

      .catch((error) => console.error("Error", error));
  };

  onClick() {
    this.setState({
      displaySuccessMessage: true,
    });
  }

  onHide() {
    this.props.history.push("/UserEvent");
  }

  onHideError() {
    this.setState({
      ErrorMessage: false,
    });
  }

  onHideErrorMessage() {
    this.setState({
      ErrorImageMessage: false,
    });
  }

  renderFooterError() {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => this.onHideError()} className="btn myProfile-btn text-16 font-semibold" />
      </div>
    );
  }

  renderFooterErrorMessage() {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => this.onHideErrorMessage()} className="btn myProfile-btn text-16 font-semibold" />
      </div>
    );
  }

  renderFooter() {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => this.onHide()} className="btn myProfile-btn text-16 font-semibold" />
      </div>
    );
  }

  GetUserDetails = () => {
    const UserId = CommonValues.GetUserId();
    var config = {
      method: "get",
      url: process.env.REACT_APP_API + `User/GetUserDetails?id=${UserId}`,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
        this.setState({
          IsSubscribed: response.data.IsSubscribed,
        });
      })
      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while getting users detail by id",
        });
        if ((error.response.status = 401)) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      Mode: params.mode,

      EventId: params.id,
    });

    this.GetUserDetails();
    this.GetCountries();

    if (params.mode === "E") {
      this.getEventHost(params.id);

      this.setState({
        showTab: true,

        LoadingMessage: "Getting Event",

        showEventDateError: false,
      });

      this.setState({
        countryCss: 1,

        stateCss: 1,

        cityCss: 1,

        eventdateCss: 1,
      });

      this.getEventDetail(params.id);

      document.body.classList.remove("login-page");

      document.body.classList.add("dashboard-page");
    } else if (params.mode === "C") {
      this.onClickPromoCode();

      this.setCurrentLocation();

      document.body.classList.remove("login-page");

      document.body.classList.add("dashboard-page");

      this.setState({
        EventMinDate: moment(new Date()).format("YYYY-MM-DD"),
      });
    } else {
      this.setState({
        notfound: true,
      });
    }
    this.getLocation();
  };

  onCancelClick = () => {
    this.props.history.push("/UserEvent");
  };

  OnCloseImage = () => {
    this.setState({
      srcLink: "",

      ErrorImageMessage: "",
    });

    this.setState({
      waterMarkSetup: {
        WaterMarkColor: this.state.selectcolor,

        WaterMarkLocation: this.state.selectlocation,

        WaterMarkText: "",

        ErrorImageMessage: "",
      },
    });
  };

  getEventDetail = (EventId) => {
    this.setState({
      Loading: true,
    });

    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetEventDetails?eventId=${EventId}`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        if (response.status === "204") {
          this.setState({
            notfound: true,
          });
        } else {
          this.setState(
            {
              EventId: response.data.EventId,

              Name: response.data.Name,

              Description: response.data.Description,

              Location: response.data.Location,

              EventHost: response.data.EventHost,

              EventDateTime: response.data.EventDateTime,

              EventMinDate: moment(response.data.EventDateTime).format("YYYY-MM-DD"),

              IsApprovalRequired: response.data.IsApprovalRequired,

              ConfigValue: response.data.ConfigValue,

              CanDownloadPhoto: response.data.CanDownloadPhoto,

              CanCsvExport: response.data.CanCsvExport,

              CanApprove: response.data.CanApprove,

              DurationTime: response.data.DurationTime,

              EventDetailsById: response.data,

              PromoCode: response.data.PromoCode,

              IsActive: response.data.IsActive,

              CanEdit: response.data.CanEdit,

              CountryId: response.data.Country.Id,

              StateId: response.data.State.Id,

              CityId: response.data.City.Id,

              IsZipDownload: response.data.IsZipDownload,

              mode: "E",

              PhotoCount: response.data.PhotoCount,

              Loading: false,

              selectedValues: response.data.EventHost,

              EventPhotoDetails: response.data.EventPhotos,

              EventPhotoId: response.data.EventPhotoDelete,

              selectradius: response.data.RadiusType,

              radiusValue: parseInt(response.data.Radius),

              txtradiusValue: response.data.Radius,

              zoomlevel: response.data.ZoomLevel,

              ConfigSetup: response.data.ConfigSetup,

              waterMarkSetup: response.data.waterMarkSetup,

              selectlocation: response.data.waterMarkSetup["WaterMarkLocation"],

              selectcolor: response.data.waterMarkSetup["WaterMarkColor"],

              CountryArray: response.data.Country,

              StateArray: response.data.State,

              CityArray: response.data.City,

              srcLink: response.data.waterMarkSetup["WaterMarkText"],
              DeactivateComment: response.data.DeactivateComment,
            },

            () => {
              this.onTagsChangeForCountry(1, this.state.CountryArray);

              this.onTagsChangeForState(1, this.state.StateArray);

              this.onTagsChangeForCity(1, this.state.CityArray);
            }
          );

          const blocksFromHtml = htmlToDraft(response.data.waterMarkSetup["WaterMarkText"]);

          const { contentBlocks, entityMap } = blocksFromHtml;

          const contentState = ContentState.createFromBlockArray(
            contentBlocks,

            entityMap
          );

          this.setState({
            EditorDescriptionState: EditorState.createWithContent(contentState),
          });

          if (response.data.MapAddress === null) {
            this.setState({
              address: "",
            });
          } else if (response.data.IsCurrentLocation === false) {
            this.setState({
              hidePlaceField: true,
              disableFields: false,
              address: response.data.MapAddress,
              useCurrentLocation: response.data.IsCurrentLocation,

              mapData: [
                {
                  id: 1,
                  name: response.data.MapAddress,
                  latitude: parseFloat(response.data.Latitude),
                  longitude: parseFloat(response.data.Longitude),
                  circle: {
                    radius: parseFloat(response.data.Radius),
                    options: {
                      strokeColor: "#ff0000",
                    },
                  },
                },
              ],
            });
          } else if (response.data.IsCurrentLocation === true) {
            this.checkLocation();

            this.setState({
              hidePlaceField: false,

              disableFields: true,

              address: "",

              useCurrentLocation: response.data.IsCurrentLocation,
            });
          }

          {
            localStorage.setItem("initialAddress", this.state.address);
          }

          if (
            response.data.Latitude === null ||
            (response.data.Latitude === "" && response.data.Longitude === null) ||
            response.data.Longitude === ""
          ) {
            this.setCurrentLocation();
          }

          localStorage.setItem(
            "CanDownloadPhoto",

            JSON.stringify(response.data.CanDownloadPhoto)
          );

          this.GetStatesByCountryId(response.data.Country.Id);

          this.GetCitiesByState(response.data.State.Id);

          const eventhostlist = response.data.EventHost.map((item) => {
            return item.UserId;
          }).join(",");

          this.setState({
            EventHostName: eventhostlist,

            zoomlevel: response.data.ZoomLevel,
          });
        }
      })

      .catch((error) => {
        if (error.response.status === 500) {
          this.setState({
            notfound: true,
          });
        }
        if (error.response.status === 400) {
          this.setState({
            notfound: true,
          });
        }

        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get event details by EventId",

            SuccessMessage: "",
          });
        }
      });
  };

  getEventHost = (EventId) => {
    var data = "";

    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetEventHost?EventId=${EventId}`,

      headers: {},

      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          EventHostList: response.data,
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while get all eventhost",

            SuccessMessage: "",
          });
        }
      });
  };

  GetCountries = () => {
    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetCountries`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        response.data.forEach((element) => {
          this.state.CountryList.push(element);
        });
      })

      .catch((error) => {
        this.setState({
          ErrorMessage: "Error while get all countries",

          SuccessMessage: "",
        });

        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onSelect = (e) => {
    const eventhostlist = e

      .map((item) => {
        return item.UserId;
      })

      .join(",");

    this.setState({
      EventHostName: eventhostlist,
    });
  };

  handleKeypress = (e) => {
    if (e.key === "Enter") {
      this.onSaveClick();
    }
  };

  onRemove = (e) => {
    const eventhostlist = e

      .map((item) => {
        return item.UserId;
      })

      .join(",");

    this.setState({
      EventHostName: eventhostlist,
    });
  };

  onSaveClick = () => {
    if (this.validate()) {
      this.setState({
        saveLoading: true,
      });

      if (isNaN(this.state.radiusValue)) {
        this.state.radiusValue = parseFloat(0);
      }

      if (isNaN(this.state.mapData[0].longitude)) {
        this.state.mapData[0].longitude = parseFloat(0);
      }

      if (isNaN(this.state.mapData[0].latitude)) {
        this.state.mapData[0].latitude = parseFloat(0);
      }

      this.setState({ SuccessMessage: "" });

      if (this.validate()) {
        if (this.state.Mode === "C") {
          this.confirmEventCreate();
        }
        if (this.state.Mode === "E") {
          this.confirmEventupdate();
        }
      }
    }
  };

  OnCreateClick = () => {
    const newDate = moment(this.state.EventDateTime).format("YYYY-MM-DD hh:mm:ss a");

    this.setState({
      Loading: true,
    });

    var data = JSON.stringify({
      EventId: 0,

      Name: this.state.Name,

      Description: this.state.Description,

      Location: this.state.Location,

      EventDateTime: newDate,

      IsApprovalRequired: this.state.IsApprovalRequired,

      CanDownloadPhoto: this.state.CanDownloadPhoto,

      DurationTime: this.state.DurationTime.split("_").join(""),

      PromoCode: this.state.PromoCode,

      Eventhost: this.state.EventHostName,

      CountryId: parseInt(this.state.CountryId),

      StateId: parseInt(this.state.StateId),

      CityId: parseInt(this.state.CityId),

      Longitude: this.state.mapData[0].longitude.toString(),

      Latitude: this.state.mapData[0].latitude.toString(),

      RadiusType: this.state.selectradius,

      Radius: this.state.radiusValue.toString(),

      MapAddress: this.state.address,

      ZoomLevel: this.state.zoomlevel,

      ConfigSetup: this.state.ConfigSetup,

      waterMarkSetup: this.state.waterMarkSetup,

      IsCurrentLocation: this.state.useCurrentLocation,
      DeactivateComment: this.state.DeactivateComment,
    });

    localStorage.setItem(
      "CanDownloadPhoto",

      JSON.stringify(this.state.CanDownloadPhoto)
    );

    var config = {
      method: "post",

      url: process.env.REACT_APP_EVENT_API + `Event/InsertEvent`,

      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };
    console.log(data, "data");
    axios(config)
      .then((response) => {
        this.onClick();

        this.setState({
          Loading: false,

          SuccessMessage: "Event created successfully..!!!",

          ErrorMessage: "",
        });
      })

      .catch((error) => {
        console.log(error.response);
        if (error.response !== null) {
          if (error.response.data === "Invalid Promo Code") {
            this.setState({
              ErrorMessage: "Event code already exist with other event. Please enter a new event code.",

              Loading: false,

              saveLoading: false,
            });
          } else if (error.response.status === 429) {
            this.setState({
              ErrorMessage: "You have reached at your max limit to create an event",
              Loading: false,

              saveLoading: false,
            });
          } else {
            console.log(error);

            this.setState({
              ErrorMessage: error,
            });
          }
        }

        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  onUpdateClick = () => {
    this.setState({
      saveLoading: true,
    });

    const newDate = moment(this.state.EventDateTime).format("YYYY-MM-DD hh:mm:ss a");

    this.setState({
      LoadingMessage: "Updating Event",
    });

    var data = JSON.stringify({
      EventId: +this.state.EventId,

      UserId: this.state.UserId,

      Name: this.state.Name,

      Description: this.state.Description,

      Location: this.state.Location,

      EventDateTime: newDate,

      IsApprovalRequired: this.state.IsApprovalRequired,

      CanDownloadPhoto: this.state.CanDownloadPhoto,

      DurationTime: this.state.DurationTime.split("_").join(""),

      PromoCode: this.state.PromoCode,

      Eventhost: this.state.EventHostName,

      CountryId: parseInt(this.state.CountryId),

      StateId: parseInt(this.state.StateId),

      CityId: parseInt(this.state.CityId),

      Longitude: this.state.mapData[0].longitude.toString(),

      Latitude: this.state.mapData[0].latitude.toString(),

      RadiusType: this.state.selectradius,

      Radius: this.state.radiusValue.toString(),

      MapAddress: this.state.address,

      ZoomLevel: this.state.zoomlevel,

      configSetup: this.state.ConfigSetup,

      waterMarkSetup: this.state.waterMarkSetup,

      IsCurrentLocation: this.state.useCurrentLocation,
      DeactivateComment: this.state.DeactivateComment,
    });

    localStorage.setItem(
      "CanDownloadPhoto",

      JSON.stringify(this.state.CanDownloadPhoto)
    );

    var config = {
      method: "post",

      url: process.env.REACT_APP_EVENT_API + `Event/UpdateEvent?EventId=${this.state.EventId}`,

      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };

    axios(config)
      .then((response) => {
        console.log(response);
        if (response.data === 200) {
          this.setState({
            saveLoading: false,

            ErrorMessage: "",

            SuccessMessage: "Event updated successfully!!!",
          });
        } else {
          this.setState({
            Loading: false,

            saveLoading: false,
          });
        }

        this.getEventDetail(this.state.EventId);
      })

      .catch((error) => {
        if (error.response.data === "Invalid Promo Code") {
          this.setState({
            ErrorMessage: "Event code already exist with other event. Please enter a new event code.",

            Loading: false,

            saveLoading: false,
          });
        } else {
          console.log(error);

          this.setState({
            ErrorMessage: error,
          });
        }
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  validate = () => {
    let valid = true;
    if (!this.state.IsEventLocationValid && this.state.useCurrentLocation) {
      this.setState({
        ErrorMessage: "Please enable the device location and pop-up window location, and then refresh the page",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.isInValidPlacesLocation) {
      this.setState({
        ErrorMessage: "Please enter valid location",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.Name === "") {
      this.setState({
        ErrorMessage: "Please enter event name",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.Location.trim() === "") {
      this.setState({
        ErrorMessage: "Please enter event location",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.Location !== null) {
      let isnum = /^\d+$/.test(this.state.Location);

      if (isnum === true) {
        this.setState({
          ErrorMessage: "Only numeric are not allowed in Location",
        });

        return false;
      }
    }

    if (this.state.EventDateTime === null) {
      this.setState({
        ErrorMessage: "Please enter event date",

        SuccessMessage: "",
      });

      return false;
    }

    let newDurationTime = this.state.DurationTime;

    if (newDurationTime.slice(3, 5) > 59) {
      this.setState({
        ErrorMessage: "Please enter valid min and sec(which should be less than 59)",

        SuccessMessage: "",
      });

      return false;
    }

    if (newDurationTime.slice(6, 8) > 59) {
      this.setState({
        ErrorMessage: "Please enter valid min and sec(which should be less than 59)",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.DurationTime === "") {
      this.setState({
        ErrorMessage: "Please enter duration",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.DurationTime.includes("Invalid")) {
      this.setState({
        ErrorMessage: "Please enter valid duration (min & sec should not be)",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.PromoCode.trim() === "") {
      this.setState({
        ErrorMessage: "Please create promo code",

        SuccessMessage: "",
      });

      return false;
    }

    if (/\s/g.test(this.state.PromoCode) === true) {
      this.setState({
        ErrorMessage: "Space is not allowed in event code",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.selectradius === "--Select Radius--") {
      this.setState({
        ErrorMessage: "Please select radius type",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.radiusValue === parseFloat(0)) {
      this.setState({
        ErrorMessage: "Please enter radius",

        SuccessMessage: "",
      });

      return false;
    }

    if (this.state.useCurrentLocation === false) {
      if (this.state.address === "") {
        this.setState({
          ErrorMessage: "Please enter places",

          SuccessMessage: "",
        });

        return false;
      }
    }
    this.setState({ ErrorMessage: "" });

    return valid;
  };

  onChangeDate = (date) => {
    console.log(date);
    this.setState({
      EventDateTime: date,

      mapcng: false,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,

      mapcng: false,
    });
  };

  onChangePromocode = (e) => {
    this.setState({
      [e.target.name]: e.target.value.trim(),

      mapcng: false,
    });
  };

  onwatermarktextchange = (e) => {
    this.setState({
      waterMarkSetup: {
        WaterMarkColor: this.state.selectcolor,

        WaterMarkLocation: this.state.selectlocation,

        WaterMarkText: e.target.value,
      },
    });
  };

  onradiuschange = (e) => {
    if (this.state.selectradius !== "--Select Radius--") {
      if (this.state.selectradius === "Sq Meter") {
        this.setState({
          txtradiusValue: e.target.value,

          radiusValue: parseFloat(e.target.value),

          mapcng: true,
        });
      } else {
        this.setState({
          txtradiusValue: e.target.value,

          radiusValue: parseFloat(e.target.value), // * 1609.34,

          mapcng: true,
        });
      }
    }
  };

  onClickPromoCode = (e) => {
    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GeneratePromoCode`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        this.setState({
          PromoCode: response.data,
        });
      })

      .catch((error) => {
        console.log(error);

        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting event generate promo code ",
          });
        }
      });
  };

  onCheckboxChange = () => {
    this.setState({
      IsApprovalRequired: !this.state.IsApprovalRequired,
    });
  };

  onCheckboxChangeConfig = (e, config) => {
    var configState = [...this.state.ConfigSetup];

    this.state.ConfigSetup.forEach((con) => {
      if (config === con) {
        config.ConfigValue = e.target.checked;
      }
    });

    this.setState({
      ConfigSetup: configState,

      ConfigValue: this.state.ConfigValue,
    });
  };

  onCanDownloadPhoto = () => {
    this.setState((prevState) => ({
      CanDownloadPhoto: !prevState.CanDownloadPhoto,
    }));
  };
  OnDeactivateComment = () => {
    this.setState((prevState) => ({
      DeactivateComment: !prevState.DeactivateComment,
    }));
  };

  ongetAllEvents = () => {
    this.props.getAllEvents();
  };

  onCloseClick = () => {
    this.props.onCloseClick(false);
  };

  GetStatesByCountryId = (id) => {
    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetStatesByCountryId?Id=${id}`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        const data = response.data;

        this.setState({
          StateList: data,

          CountryId: id,
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting event get states by countryid",
          });
        }
      });
  };

  GetCitiesByState(Id) {
    this.setState({
      StateId: Id,
    });

    var config = {
      method: "get",

      url: process.env.REACT_APP_EVENT_API + `Event/GetCitiesByStateId?Id=${Id}`,

      headers: {},
    };

    axios(config)
      .then((response) => {
        const data = response.data;

        this.setState({
          CityList: data,

          StateId: Id,
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while getting event get cities by stateId",
          });
        }
      });
  }

  onTagsChangeForCountry = (event, values) => {
    if (values !== null) {
      this.setState(
        {
          CountryArray: values,

          StateArray: [],

          CityArray: [],
        },

        () => {
          // This will output an array of objects

          // given by Autocompelte options property.

          this.setState({
            StateList: [],

            CityList: [],
          });

          this.GetStatesByCountryId(this.state.CountryArray.Id);

          if (this.state.CountryArray.Id === 0) {
            this.setState({
              countryCss: 0,
            });
          } else {
            this.setState({
              stateedit: false,

              countryCss: 1,

              StateId: 0,

              CityId: 0,
            });
          }
        }
      );
    } else {
      this.setState({
        CountryArray: [],

        StateList: [],

        StateArray: [],

        CityList: [],

        CityArray: [],

        stateedit: true,

        cityedit: true,

        CountryId: 0,

        StateId: 0,

        CityId: 0,
      });
    }
  };

  onTagsChangeForState = (event, values) => {
    if (values !== null) {
      this.setState(
        {
          StateArray: values,

          CityArray: [],
        },

        () => {
          // This will output an array of objects

          // given by Autocompelte options property.

          this.setState({
            CityList: [],
          });

          this.GetCitiesByState(this.state.StateArray.Id);

          if (this.state.StateArray.Id === 0) {
            this.setState({
              stateCss: 0,
            });
          } else {
            this.setState({
              stateCss: 1,

              CityId: 0,

              cityedit: false,
            });
          }
        }
      );
    } else {
      this.setState({
        StateArray: [],

        CityList: [],

        CityArray: [],

        StateId: 0,

        cityedit: true,

        CityId: 0,
      });
    }
  };

  onTagsChangeForCity = (event, values) => {
    if (values !== null) {
      this.setState(
        {
          CityArray: values,
        },

        () => {
          this.setState({
            CityId: this.state.CityArray.Id,
          });

          if (this.state.CityArray.StateId === 0) {
            this.setState({
              cityCss: 0,
            });
          } else {
            this.setState({
              cityCss: 1,
            });
          }
        }
      );
    } else {
      this.setState({
        CityArray: [],

        CityId: 0,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      StateList: [],

      CityList: [],
    });

    this.GetStatesByCountryId(e.target.value);

    if (e.target.value === 0) {
      this.setState({
        countryCss: 0,
      });
    } else {
      this.setState({
        countryCss: 1,

        StateId: 0,

        CityId: 0,
      });
    }
  };

  handleStateChange = (e) => {
    this.setState({
      CityList: [],
    });

    this.GetCitiesByState(e.target.value);

    if (e.target.value === 0) {
      this.setState({
        stateCss: 0,
      });
    } else {
      this.setState({
        stateCss: 1,

        CityId: 0,
      });

      this.cityInput.focus();
    }
  };

  handleCityChange = (e) => {
    this.setState({
      CityId: e.target.value,
    });

    if (e.target.value === 0) {
      this.setState({
        cityCss: 0,
      });
    } else {
      this.setState({
        cityCss: 1,
      });
    }
  };

  ToggleSideBar = () => {
    this.setState({
      ToggleSideBar: !this.state.ToggleSideBar,
    });
  };

  onApprovedCheckboxChange = (id) => {
    const NewList = [];

    this.state.EventPhotoDetails.forEach((element) => {
      if (element.EventPhotoId === id) {
        NewList.push({
          EventPhotoId: element.EventPhotoId,

          EventId: element.EventId,

          ImageName: element.ImageName,

          ImageURL: element.ImageURL,

          Status: element.Status,

          IsActive: !element.IsActive,
        });

        let selectedEventPhotoDetails = this.state.selectedEventPhotoDetails;

        selectedEventPhotoDetails.push(element.EventPhotoId);

        this.setState({
          selectedEventPhotoDetails: selectedEventPhotoDetails,
        });
      } else {
        NewList.push({
          EventPhotoId: element.EventPhotoId,

          EventId: element.EventId,

          ImageName: element.ImageName,

          ImageURL: element.ImageURL,

          Status: element.Status,

          IsActive: element.IsActive,
        });
      }
    });

    this.setState({
      EventPhotoDetails: NewList,
    });
  };

  checkAll = (val) => {
    const checkAllList = [];

    if (val.target.checked) {
      //checked

      this.state.EventPhotoDetails.forEach((element) => {
        checkAllList.push({
          EventPhotoId: element.EventPhotoId,

          EventId: element.EventId,

          ImageName: element.ImageName,

          ImageURL: element.ImageURL,

          Status: element.Status,

          IsActive: true,
        });

        let selectedEventPhotoDetails = this.state.selectedEventPhotoDetails;

        selectedEventPhotoDetails.push(element.EventPhotoId);

        this.setState({
          selectedEventPhotoDetails: selectedEventPhotoDetails,
        });
      });
    } else {
      //unchecked

      this.state.EventPhotoDetails.forEach((element) => {
        checkAllList.push({
          EventPhotoId: element.EventPhotoId,

          EventId: element.EventId,

          ImageName: element.ImageName,

          ImageURL: element.ImageURL,

          Status: element.Status,

          IsActive: false,
        });
      });
    }

    this.setState({
      EventPhotoDetails: checkAllList,
    });
  };

  // for select All

  onApproveRejectAllClick = (val) => {
    if (val === "A") {
      this.state.EventPhotoDetails.forEach((element) => {
        this.onApproveClick(element.EventPhotoId);
      });
    } else {
      this.state.EventPhotoDetails.forEach((element) => {
        this.onRejectClick(element.EventPhotoId);
      });
    }
  };

  // for multiple select

  onApproveRejectClick = (val) => {
    if (val === "A") {
      this.state.selectedEventPhotoDetails.forEach((element) => {
        this.onApproveClick(element);
      });
    } else {
      this.state.selectedEventPhotoDetails.forEach((element) => {
        this.onRejectClick(element);
      });
    }
  };

  onApproveClick = (EventPhotoId) => {
    var data = JSON.stringify({
      EventPhotoId: EventPhotoId,

      Status: "A",
    });

    var config = {
      method: "post",

      url: process.env.REACT_APP_EVENT_API + `Event/EventPhoto`,

      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          showHideButton: false,

          selectedEventPhotoDetails: [],
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while Approving Photo",
          });
        }
      });

    this.componentDidMount();
  };

  onRejectClick = (EventPhotoId) => {
    var data = JSON.stringify({
      EventPhotoId: EventPhotoId,

      Status: "R",
    });

    var config = {
      method: "post",

      url: process.env.REACT_APP_EVENT_API + `Event/EventPhoto`,

      headers: {
        "Content-Type": "application/json",
      },

      data: data,
    };

    axios(config)
      .then((response) => {
        this.setState({
          showHideButton: true,

          selectedEventPhotoDetails: [],
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          this.setState({
            ErrorMessage: "Error while Rejecting Photo",
          });
        }
      });

    this.componentDidMount();
  };

  durationHandleChange = (e) => {
    this.setState({
      DurationTime: e.target.value,
    });
  };

  locationHandleChange = (e) => {
    if (e.target.value === "--Select Location--") {
      this.setState({
        locationValue: parseFloat(0),

        selectlocation: "--Select Location--",
      });
    } else {
      this.setState({
        waterMarkSetup: {
          WaterMarkColor: this.state.selectcolor,

          WaterMarkLocation: e.target.value,

          WaterMarkText: this.state.waterMarkSetup["WaterMarkText"],
        },

        selectlocation: e.target.value,

        txtlocationValue: "",

        locationValue: parseFloat(0),
      });
    }
  };
  toggleHandleChange = (e) => {
    this.setState(
      {
        useCurrentLocation: e.target.checked,
        address: e.target.checked ? this.state.address : "",
        CheckboxLoader: true,
      },

      () => this.checkLocation()
    );
  };

  checkLocation = () => {
    const {
      match: { params },
    } = this.props;

    this.setState({
      Mode: params.mode,

      EventId: params.id,
    });

    if (params.mode === "C") {
      if (this.state.useCurrentLocation === false) {
        this.setState({
          hidePlaceField: true,

          disableFields: false,

          selectPlaceField: false,

          address: "",

          CheckboxLoader: false,
        });
      }

      if (this.state.useCurrentLocation === true) {
        this.setState({
          hidePlaceField: false,

          disableFields: true,

          selectPlaceField: false,

          CheckboxLoader: false,
        });

        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setCurrentLocation();

            this.setState({
              hidePlaceField: false,

              disableFields: true,

              selectPlaceField: false,

              CheckboxLoader: false,
            });
          },

          (error) => {
            if (error.code === 1) {
              this.setState({
                ErrorMessage: "Please enable the device location and pop-up window location, and then refresh the page",

                SuccessMessage: "",

                hidePlaceField: true,

                selectPlaceField: true,

                disableFields: true,

                address: "",
              });
            }
          },

          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
      }
    } else if (params.mode === "E") {
      if (this.state.useCurrentLocation === false) {
        this.setState({
          hidePlaceField: true,

          disableFields: false,

          selectPlaceField: false,

          address: "",

          CheckboxLoader: false,
        });
      }

      if (this.state.useCurrentLocation === true) {
        this.setState({
          hidePlaceField: false,

          disableFields: true,

          selectPlaceField: false,

          CheckboxLoader: false,
        });

        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setCurrentLocation();

            this.setState({
              hidePlaceField: false,

              disableFields: true,

              selectPlaceField: false,
            });
          },

          (error) => {
            if (error.code === 1) {
              this.setState({
                ErrorMessage: "Please enable the device location and pop-up window location, and then refresh the page",

                SuccessMessage: "",

                hidePlaceField: true,

                selectPlaceField: true,

                disableFields: true,

                address: "",
              });
            }
          },

          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
      }
    }
  };

  radiusHandleChange = (e) => {
    if (e.target.value === "--Select Radius--") {
      this.setState({
        radiusValue: parseFloat(0),

        selectradius: "--Select Radius--",

        txtradiusValue: "",
      });
    } else {
      this.setState({
        selectradius: e.target.value,

        txtradiusValue: "",

        radiusValue: parseFloat(0),
      });

      this.radiusInput.focus();
    }
  };

  onMarkerDragEnd = (e) => {
    this.setState({
      places: [],
    });
  };

  onDeleteAllPhotos = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");

    var config = {
      method: "put",

      url: process.env.REACT_APP_EVENT_API + `Event/DeactivateEventPhoto?EventId=${this.state.EventId}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response) => {
        this.onClick();

        this.setState({
          ErrorMessage: "",

          SuccessMessage: "Event photos deleted successfully!!!",
        });
      })

      .catch((error) => {
        if (error.response.status === 401) {
          window.location.pathname = "/logout";
        } else {
          console.log(error);
        }
      });
  };

  rejectFunc = () => {};

  confirm = () => {
    confirmDialog({
      message: "Do you want to delete all photos for this event?",

      header: "Delete Confirmation",

      icon: "pi pi-info-circle",
      draggable: false,
      resizable: false,
      accept: () => this.onDeleteAllPhotos(this.state.EventId),

      reject: () => this.rejectFunc(),
    });
  };

  rejectFuncUpdate = () => {
    this.setState({
      saveLoading: false,

      Loading: false,
    });
  };

  confirmEventupdate = () => {
    this.setState({
      open: false,
    });

    confirmDialog({
      message: "Do you want to update event?",

      header: "Event update confirmation",

      icon: "pi pi-info-circle",
      draggable: false,
      resizable: false,

      accept: () => this.onUpdateClick(),

      reject: () => this.rejectFuncUpdate(),
    });
  };

  confirmEventCreate = () => {
    this.setState({
      open: false,
    });

    confirmDialog({
      message: "Do you want to create event?",

      header: "Event create confirmation",

      icon: "pi pi-info-circle",
      draggable: false,
      resizable: false,
      accept: () => this.OnCreateClick(),

      reject: () => this.rejectFuncUpdate(),
    });
  };

  handleCloseClick = () => {
    this.setState({
      address: "",
    });
  };

  handleKeyCommand(command, editorState) {
    const newContent = RichUtils.handleKeyCommand(editorState, command);

    if (newContent) {
      this.onDescriptionEditorStateChange(newContent);

      return "handled";
    } else {
      return "not-handled";
    }
  }

  onDescriptionEditorStateChange = (editorState) => {
    let newEditorState = editorState;

    const content = editorState.getCurrentContent();

    const key = editorState.getSelection().getFocusKey();

    const type = content.getBlockForKey(key).getType();

    if (type === "atomic") {
      const keyAfter = content.getKeyAfter(key);

      const newSelection = SelectionState.createEmpty(keyAfter);

      newEditorState = EditorState.forceSelection(editorState, newSelection);
    }

    this.setState(
      {
        EditorDescriptionState: newEditorState,

        mapcng: false,
      },

      () => {
        this.setState({
          waterMarkSetup: {
            WaterMarkColor: this.state.selectcolor,

            WaterMarkLocation: this.state.selectlocation,

            WaterMarkText: this.state.srcLink,
          },
        });
      }
    );
  };

  handleFileUpload = (event) => {
    var u = URL.createObjectURL(event.target.files[0]);
    var img = new Image();
    img.onload = (event) => {
      if (img.width > 1024 || img.height > 1024) {
        this.setState(
          {
            ErrorImageMessage: "Image resolution should not be more than 1024 * 1024 pixels",
            isImageSizeValid: false,
          },
          () => {
            img.src = "";
          }
        );
        this.fileInput.value = "";
      } else {
        this.setState({
          ErrorImageMessage: "",
          isImageSizeValid: true,
        });
      }
    };
    if (this.state.isImageSizeValid === true) {
      this.uploadImageCallBack(event.target.files[0]);
      img.src = u;
    }
  };
  uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open("POST", "https://api.imgur.com/3/image");

      xhr.setRequestHeader("Authorization", "Client-ID 2b04d8d06a0d541");

      const data = new FormData();

      data.append("image", file);

      xhr.send(data);

      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);

        resolve(response);

        if (this.state.isImageSizeValid === true) {
          this.setState({
            srcLink: response.data.link,
          });
        } else if (this.state.isImageSizeValid === false) {
          this.setState({
            srcLink: "",
          });
        }

        this.setState({
          isImageSizeValid: true,
        });
        this.setState({
          waterMarkSetup: {
            WaterMarkColor: this.state.selectcolor,

            WaterMarkLocation: this.state.selectlocation,

            WaterMarkText: this.state.srcLink,
          },
        });
      });

      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);

        reject(error);

        console.log(error, "error");
      });
    });
  };

  onBackClick = () => {
    this.props.history.push("/UserEvent");
  };

  getLocation = () => {
    CommonValues.getLocation().then((result) => {
      this.setState({
        IsEventLocationValid: result,
      });
    });
  };

  render() {
    if (this.state.notfound) {
      return <Redirect to="/notfound" />;
    }

    const today = new Date();

    const locationData = [
      {
        id: 0,

        location: "--SelectLocation--",

        name: "--Select Location--",
      },

      {
        id: 1,

        location: "TopLeft",

        name: "Top Left",
      },

      {
        id: 2,

        location: "TopRight",

        name: "Top Right",
      },

      {
        id: 3,

        location: "BottomLeft",

        name: "Bottom Left",
      },

      {
        id: 4,

        location: "BottomRight",

        name: "Bottom Right",
      },

      {
        id: 5,

        location: "Center",

        name: "Center",
      },

      {
        id: 6,

        location: "CenterTop",

        name: "Center Top",
      },

      {
        id: 7,

        location: "CenterBottom",

        name: "Center Bottom",
      },
    ];
    const RadiusData = [
      {
        id: 0,

        radius: "--Select Radius--",

        name: "--Select Radius--",
      },

      {
        id: 1,

        radius: "Sq Meter",

        name: "Sq Meter",
      },

      {
        id: 2,

        radius: "Miles",

        name: "Miles",
      },
    ];

    let data = {
      id: 1,

      name: "Park Slope",

      latitude: "40.6710729",

      longitude: "-73.9988001",
    };

    data.circle = {
      radius: this.state.radiusValue,

      options: {
        strokeColor: "#ff0000",
      },
    };

    let spinnerDiv = (
      <div className="card-body" style={{ textAlign: "center" }}>
        <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner animation="border" variant="warning" /> <b>{this.state.LoadingMessage}</b>
        </div>
      </div>
    );

    return (
      <div>
        <main className="main-content-wrapper">
          <Toast ref={(el) => (this.toast = el)} />
          <div className="page-body-wrapper">
            <Sidebar ToggleSideBar={this.ToggleSideBar} sidebarvalue={this.state.sidebarvalue} />
            <header></header>
            <div className="page-body">
              <a class="back-btn d-block d-md-none d-lg-none text-dark pt-2 pl-2" onClick={this.onBackClick}>
                <i class="pi pi-arrow-left"></i>
              </a>
              <Header
                onToggleButtonClick={this.onToggleButtonClick}
                onSideBarToggle={this.onSideBarToggle}
                EventDashboardValue={this.state.EventDashboardValue}
                DynamicUserEvent={this.state.Name}
              />
              <Dialog
                header="Confirmation"
                visible={this.state.displaySuccessMessage}
                footer={this.renderFooter()}
                draggable={false}
                resizable={false}
                onHide={() => this.onHide()}
              >
                <p>{this.state.SuccessMessage}</p>
              </Dialog>
              {this.state.ErrorMessage !== "" ? (
                <Dialog
                  header="Error message"
                  draggable={false}
                  resizable={false}
                  visible={this.state.ErrorMessage}
                  footer={this.renderFooterError()}
                  onHide={() => this.onHideError()}
                  className="errorpopup"
                >
                  <p>{this.state.ErrorMessage}</p>
                </Dialog>
              ) : (
                <></>
              )}

              {this.state.ErrorImageMessage !== "" ? (
                <Dialog
                  header="Error message"
                  draggable={false}
                  resizable={false}
                  visible={this.state.ErrorImageMessage}
                  footer={this.renderFooterErrorMessage()}
                  onHide={() => this.onHideErrorMessage()}
                >
                  <p>{this.state.ErrorImageMessage}</p>
                </Dialog>
              ) : (
                <></>
              )}

              <Tabs defaultActiveKey="Event Details" id="uncontrolled-tab-example" className="mb-3 mt-4">
                <Tab eventKey="Event Details" title="Event Details">
                  <div>
                    {this.state.Loading === true ? (
                      spinnerDiv
                    ) : (
                      <div className="create-event-wrapper mt-4">
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="my-4 mx-4">
                                <div className="row align-items-start">
                                  <div className="mb-3 col-lg-6">
                                    <label className="form-label text-14 font-semibold ">
                                      Event Name <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="name"
                                      className="form-control-new text-14 text-lightgrey "
                                      name="Name"
                                      onChange={this.onChange}
                                      onKeyPress={this.handleKeypress}
                                      value={this.state.Name}
                                      placeholder="Enter event name"
                                      id
                                      maxLength={25}
                                      readOnly={this.state.CanEdit ? false : true}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6">
                                    <label className="form-label text-14 font-semibold  ">
                                      Event Location <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="name"
                                      className="form-control-new text-14 text-lightgrey "
                                      name="Location"
                                      onChange={this.onChange}
                                      onKeyPress={this.handleKeypress}
                                      value={this.state.Location}
                                      placeholder="Enter location"
                                      id
                                      readOnly={this.state.CanEdit ? false : true}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6">
                                    <label className="form-label text-14 font-semibold ">
                                      Event Date <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div className="form-group mb-0">
                                      <div className="input-group date" id="datetimepicker">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <DateTimePicker
                                            value={this.state.EventDateTime}
                                            onChange={this.onChangeDate}
                                            className="input-group-date"
                                            minDate={today}
                                            disabled={this.state.CanEdit ? false : true}
                                            error={this.props.EventDateTime ? false : true}
                                            helperText={this.props.EventDateTime ? "Some error message" : ""}
                                            format="MMMM dd yyyy hh:mm a"
                                            placeholder="Enter event date"
                                          />
                                        </MuiPickersUtilsProvider>
                                        <span className="input-group-addon">
                                          <span className="glyphicon glyphicon-calendar" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3 col-lg-6">
                                    <div>
                                      <label className="form-label text-14 font-semibold ">
                                        Duration (HH:MM:SS) <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <div className="form-group mb-0">
                                        <div className="input-group date d-block" id="datetimepicker">
                                          <InputMask
                                            className="form-control-new text-14 text-lightgrey "
                                            mask="99:99:99"
                                            placeholder="Enter duration"
                                            maskChar="_"
                                            value={this.state.DurationTime}
                                            onChange={this.durationHandleChange}
                                            beforeMaskedValueChange={this.beforeMaskedValueChange}
                                            disabled={this.state.CanEdit ? false : true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-3 col-lg-6 userevent-flex ">
                                    <label className="form-label text-14 font-semibold  ">Country</label>
                                    <Autocomplete
                                      key={new Date()}
                                      style={{ width: "100%" }}
                                      id="country-select-demo"
                                      sx={{ width: 300 }}
                                      options={this.state.CountryList}
                                      autoHighlight
                                      openOnFocus
                                      autoComplete
                                      getOptionLabel={(option) => {
                                        if (option.hasOwnProperty("Name")) {
                                          return option.Name;
                                        }

                                        return option;
                                      }}
                                      onChange={this.onTagsChangeForCountry}
                                      onKeyPress={this.handleKeypress}
                                      disabled={this.state.CanEdit ? false : true}
                                      value={this.state.CountryArray.Name}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                          }}
                                          {...props}
                                        >
                                          <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${option.ShortName.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${option.ShortName.toLowerCase()}.png 2x`}
                                            alt=""
                                          />
                                          {option.Name}
                                        </Box>
                                      )}
                                      renderInput={(params) =>
                                        this.state.CountryArray.Id ? (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password",
                                            }}
                                          />
                                        ) : (
                                          <TextField
                                            {...params}
                                            label="Choose a country"
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6 userevent-flex">
                                    <label className="form-label text-14 font-semibold  ">State</label>

                                    <Autocomplete
                                      key={new Date()}
                                      style={{ width: "100%" }}
                                      id="country-select-demo"
                                      sx={{ width: 300 }}
                                      options={this.state.StateList}
                                      autoHighlight
                                      openOnFocus
                                      autoComplete
                                      getOptionLabel={(option) => {
                                        if (option.hasOwnProperty("Name")) {
                                          return option.Name;
                                        }

                                        return option;
                                      }}
                                      onChange={this.onTagsChangeForState}
                                      onKeyPress={this.handleKeypress}
                                      disabled={this.state.CanEdit ? (this.state.stateedit ? true : false) : true}
                                      value={this.state.StateArray.Name}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                          }}
                                          {...props}
                                        >
                                          {option.Name}
                                        </Box>
                                      )}
                                      renderInput={(params) =>
                                        this.state.StateArray.Id ? (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password",
                                            }}
                                          />
                                        ) : (
                                          <TextField
                                            {...params}
                                            label="Choose a state"
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        )
                                      }
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6 userevent-flex">
                                    <label className="form-label text-14 font-semibold ">City</label>
                                    <Autocomplete
                                      key={new Date()}
                                      style={{ width: "100%" }}
                                      id="country-select-demo"
                                      sx={{ width: 300 }}
                                      options={this.state.CityList}
                                      autoHighlight
                                      openOnFocus
                                      autoComplete
                                      getOptionLabel={(option) => {
                                        if (option.hasOwnProperty("Name")) {
                                          return option.Name;
                                        }

                                        return option;
                                      }}
                                      onChange={this.onTagsChangeForCity}
                                      onKeyPress={this.handleKeypress}
                                      disabled={this.state.CanEdit ? (this.state.cityedit ? true : false) : true}
                                      value={this.state.CityArray.Name}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                          }}
                                          {...props}
                                        >
                                          {option.Name}
                                        </Box>
                                      )}
                                      renderInput={(params) =>
                                        this.state.CityArray.Id ? (
                                          <TextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        ) : (
                                          <TextField
                                            {...params}
                                            label="Choose a city"
                                            inputProps={{
                                              ...params.inputProps,

                                              autoComplete: "new-password", // disable autocomplete and autofill
                                            }}
                                          />
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-6"></div>
                                  <div className=" col-lg-12">
                                    <label className="form-check-label text-14 font-semibold " htmlFor>
                                      Event Options
                                    </label>
                                  </div>
                                  <div className=" col-lg-4">
                                    <input
                                      type="checkbox"
                                      name="IsApprovalRequired"
                                      checked={this.state.IsApprovalRequired}
                                      onChange={this.onCheckboxChange}
                                      disabled={this.state.CanEdit ? false : true}
                                    />
                                    &nbsp; &nbsp;
                                    <label className="form-check-label text-14 font-semibold  mt-1" htmlFor>
                                      Approval required to share photos
                                    </label>
                                  </div>
                                  <div className=" col-lg-4">
                                    <input
                                      type="checkbox"
                                      name="CanDownloadPhoto"
                                      checked={this.state.CanDownloadPhoto}
                                      onChange={this.onCanDownloadPhoto}
                                      disabled={this.state.CanEdit ? false : true}
                                    />
                                    &nbsp; &nbsp;
                                    <label className="form-check-label text-14 font-semibold  mt-1" htmlFor>
                                      Can download photos
                                    </label>
                                  </div>
                                  <div className=" col-lg-4">
                                    <input
                                      type="checkbox"
                                      name="DeactivateComment"
                                      checked={this.state.DeactivateComment}
                                      onChange={this.OnDeactivateComment}
                                      disabled={this.state.CanEdit ? false : true}
                                    />
                                    &nbsp; &nbsp;
                                    <label className="form-check-label text-14 font-semibold  mt-1" htmlFor>
                                      Deactivate comments
                                    </label>
                                  </div>
                                  {this.state.IsSubscribed ? (
                                    <>
                                      <div className="col-lg-12 mt-3">
                                        <label className="form-check-label text-14 font-semibold " htmlFor>
                                          Video Options
                                        </label>
                                      </div>
                                      {this.state.ConfigSetup.map((checkbox) => (
                                        <div className=" col-lg-12">
                                          <input
                                            key={checkbox.Id}
                                            id={checkbox.Id}
                                            type="checkbox"
                                            name="ConfigValue"
                                            checked={checkbox.ConfigValue}
                                            value={checkbox.Id}
                                            onChange={(event) =>
                                              this.onCheckboxChangeConfig(
                                                event,

                                                checkbox
                                              )
                                            }
                                            disabled={this.state.CanEdit ? false : true}
                                          />
                                          &nbsp; &nbsp;
                                          <label className="form-check-label text-14 font-semibold  mt-1" htmlFor>
                                            {checkbox.Name}
                                          </label>
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="mb-3 col-lg-12 mt-3">
                                    <label className="form-label text-14 font-semibold ">Event Host</label>
                                    <Multiselect
                                      options={this.state.EventHostList} // Options to display in the dropdown
                                      selectedValues={this.state.selectedValues} // Preselected value to persist in dropdown
                                      onSelect={this.onSelect} // Function will trigger on select event
                                      onRemove={this.onRemove} // Function will trigger on remove event
                                      displayValue="Name" // Property name to display in the dropdown options
                                      value={this.state.EventHostList}
                                      classsName="form-control"
                                      disable={this.state.Mode === "E" ? false : true}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6">
                                    <label className="form-label text-14 font-semibold  ">
                                      Event Code <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="inputPassword6"
                                      className="form-control-new text-14 text-lightgrey"
                                      aria-describedby="passwordHelpInline"
                                      onChange={this.onChangePromocode}
                                      name="PromoCode"
                                      value={this.state.PromoCode}
                                      placeholder="Enter event code"
                                      disabled={this.state.CanEdit ? false : true}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-6 mt-4">
                                    <button
                                      type="button"
                                      className="btn code-change-btn text-13 font-semibold bg-lightblue text-button-grey mt-2"
                                      onClick={this.onClickPromoCode}
                                      disabled={this.state.CanEdit ? false : true}
                                    >
                                      {this.state.PromoCode === "" ? "Create a code" : "Change the code"}
                                    </button>
                                  </div>
                                  {this.state.Mode === "C" ? null : (
                                    <>
                                      <div className="mb-3 col-lg-12">
                                        <label className="form-label text-14 font-semibold  mt-3">QR Code</label>
                                      </div>
                                      <div className="mb-3 col-lg-12 ">
                                        <QRCodeViewer PromoCode={this.state.PromoCode} EventId={this.state.EventId} />
                                      </div>
                                    </>
                                  )}

                                  <div className="mb-3 col-lg-6">
                                    <label className="form-label text-14 font-semibold ">Watermark configuration </label>

                                    <div className="mb-3 col-lg-12 editor pl-0 pr-0">
                                      <input
                                        type="file"
                                        id=""
                                        data-max-width="1024"
                                        data-max-height="1024"
                                        autocomplete="off"
                                        accept=".jpg,.png,.jpeg,.svg"
                                        onEditorStateChange={this.onDescriptionEditorStateChange}
                                        ref={(ref) => (this.fileInput = ref)}
                                        onChange={this.handleFileUpload}
                                      />
                                    </div>
                                    <div>
                                      {!this.state.srcLink ? (
                                        <></>
                                      ) : (
                                        <div className="card-uploadfile">
                                          <>
                                            <button type="submit" class="close" onClick={this.OnCloseImage}>
                                              <span>×</span>
                                            </button>
                                          </>
                                          <img
                                            alt=""
                                            value={this.state.isImageSizeValid ? this.state.srcLink : ""}
                                            src={this.state.isImageSizeValid ? this.state.srcLink : ""}
                                            style={{
                                              maxHeight: "100px",

                                              maxWidth: "100px",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="mb-3 col-lg-6 userevent-flex">
                                    <label className="form-label text-14 font-semibold ">Location </label>
                                    <select
                                      className={
                                        this.state.countryCss ? "form-control-new text-14 font-semibold" : "form-control-new text-14 font-semibold"
                                      }
                                      value={this.state.selectlocation}
                                      onChange={this.locationHandleChange}
                                      onKeyPress={this.handleKeypress}
                                      aria-label=""
                                      required
                                      disabled={this.state.CanEdit ? false : true}
                                    >
                                      {locationData.map((location) => {
                                        return <option value={location.location}>{location.name}</option>;
                                      })}
                                    </select>
                                  </div>

                                  <div className="col-lg-6"></div>
                                  <div className="mb-3 col-lg-12">
                                    <label className="form-label text-14 font-semibold ">Description</label>
                                    <textarea
                                      type="name"
                                      className="form-control-new text-14 text-lightgrey font-semibold"
                                      placeholder="Enter Description"
                                      name="Description"
                                      autocomplete="off"
                                      onChange={this.onChange}
                                      value={this.state.Description}
                                      style={{ height: "83px" }}
                                      readOnly={this.state.CanEdit ? false : true}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-3">
                                    <label className="form-label text-14 font-semibold  ">
                                      Select Radius Type <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                      className={
                                        this.state.countryCss ? "form-control-new text-14 font-semibold" : "form-control-new text-14 font-semibold"
                                      }
                                      value={this.state.selectradius}
                                      onChange={this.radiusHandleChange}
                                      onKeyPress={this.handleKeypress}
                                      aria-label=""
                                      required
                                      disabled={this.state.CanEdit ? false : true}
                                    >
                                      {RadiusData.map((radius) => {
                                        return <option value={radius.radius}>{radius.name}</option>;
                                      })}
                                    </select>
                                  </div>
                                  <div className="mb-3 col-lg-3">
                                    <label className="form-label text-14 font-semibold ">Use current location</label>
                                    {this.state.CheckboxLoader ? (
                                      <Spinner style={{ display: "block" }} animation="border" variant="warning" />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        name="useCurrentLocation"
                                        checked={this.state.useCurrentLocation}
                                        onChange={(e) => this.toggleHandleChange(e)}
                                        className="largerCheckbox"
                                      />
                                    )}
                                  </div>
                                  <div className="mb-3 col-lg-2">
                                    <label className="form-label text-14 font-semibold  ">
                                      Radius <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="radiusfield"
                                      className="form-control text-14 text-lightgrey "
                                      onChange={this.onradiuschange}
                                      maxLength="5"
                                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                      name="radius"
                                      value={this.state.txtradiusValue}
                                      placeholder="Enter radius value"
                                      required
                                      disabled={this.state.CanEdit ? false : true}
                                      ref={(input) => {
                                        this.radiusInput = input;
                                      }}
                                    />
                                  </div>
                                  <div className="mb-3 col-lg-4" style={{}}>
                                    <label className="form-label text-14 font-semibold  ">
                                      Places <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Current location being used"
                                      className="form-control text-14 text-lightgrey "
                                      disabled={this.state.disableFields}
                                      hidden={this.state.hidePlaceField}
                                    ></input>
                                    <PlacesAutocomplete
                                      value={this.state.address}
                                      onChange={this.handleAutocompleteChange}
                                      onSelect={this.handleSelect}
                                      onError={this.handelError}
                                    >
                                      {({
                                        getInputProps,

                                        suggestions,

                                        getSuggestionItemProps,

                                        loading,
                                      }) => (
                                        <div>
                                          <input
                                            {...getInputProps({
                                              placeholder: "Search Places ...",

                                              className: "form-control text-14 text-lightgrey font-semibold",
                                            })}
                                            disabled={this.state.selectPlaceField}
                                            hidden={!this.state.hidePlaceField}
                                          />

                                          <button
                                            class="close-icon"
                                            type="reset"
                                            hidden={!this.state.hidePlaceField}
                                            onClick={this.handleCloseClick}
                                          ></button>

                                          <div className="dropdown-map">
                                            {loading && <div>Loading...</div>}

                                            {suggestions.map((suggestion) => {
                                              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";

                                              // inline style for demonstration purpose

                                              const style = suggestion.active
                                                ? {
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    cursor: "pointer",
                                                  };

                                              return (
                                                <div
                                                  {...getSuggestionItemProps(
                                                    suggestion,

                                                    {
                                                      className,

                                                      style,
                                                    }
                                                  )}
                                                >
                                                  <strong>{suggestion.formattedSuggestion.mainText}</strong>
                                                  <small>{suggestion.formattedSuggestion.secondaryText}</small>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                  <div className="mb-3 col-lg-12">
                                    <Map
                                      parentCallback={this.handleCallback}
                                      center={this.state.centerValue}
                                      zoom={this.state.zoomlevel}
                                      places={this.state.mapData}
                                      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCjBuA6lcuf0ymkuyIEebbJfzCsoc4gLu8"
                                      loadingElement={<div style={{ height: `100%` }} />}
                                      containerElement={<div className="map-height" />}
                                      mapElement={<div style={{ height: `100%` }} />}
                                      radiusValue={this.state.radiusValue}
                                    />
                                  </div>
                                  {this.state.CanEdit ? (
                                    <div className="mb-3 col-lg-12">
                                      <div class="row align-items-center">
                                        <div className="col-md-12 col-lg-6 display-l">
                                          <div className="">
                                            {this.state.Mode !== "C" && this.state.PhotoCount ? (
                                              <div className="p-2">
                                                <button
                                                  className="btn delete-all-photo text-14 font-semibold bg-lightblue text-button-grey"
                                                  type="delete"
                                                  onClick={this.confirm}
                                                >
                                                  Delete All Photos
                                                </button>
                                              </div>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                          <div className="p-2">
                                            <button
                                              className="btn delete-all-photo text-14 font-semibold bg-lightblue text-button-grey"
                                              type="submit"
                                              onClick={this.onCancelClick}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                          <div className="p-2">
                                            <Button
                                              disabled={this.state.ErrorImageMessage ? true : false}
                                              label={this.state.Loading ? "Saving event" : "Save"}
                                              loading={this.state.saveLoading}
                                              className="btn Create-event-save-btn text-14 font-semibold"
                                              onClick={this.onSaveClick}
                                            ></Button>
                                          </div>
                                        </div>
                                        <div className="p-2 col-md-6 col-lg-4">
                                          <div className=" alertMessage ml-2"></div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="col-lg-4 col-md-4"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* )} */}
                </Tab>
                <Tab eventKey="Photo Gallery" title="Photo Gallery" tabClassName={this.state.showTab ? "d-block" : "d-none"}>
                  {this.state.EventId !== 0 ? (
                    <PhotoGallery
                      showTab={this.state.showTab}
                      componentDidMount={() => this.componentDidMount()}
                      EventId={this.state.EventId}
                      CanDownloadPhoto={this.state.CanDownloadPhoto}
                      CanApprove={this.state.CanApprove}
                      CanCsvExport={this.state.CanCsvExport}
                      IsZipDownload={this.state.IsZipDownload}
                    />
                  ) : null}
                  &nbsp;
                </Tab>
              </Tabs>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default AddUserEvent;
