import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../src/assets/images/logo/Participiclogo.png";
import Auth from "../Auth/Auth";
import CommonValues from "../Common/Util";
import DashboardIcon from "./../assets/images/Dashboard-eventscreenImg.png";
import EventManagementIcon from "./../assets/images/EventManagementimg.png";
import MyProfileIcon from "./../assets/images/MyProfile.png";
import MyProfileActiveIcon from "./../assets/images/MyProfileFocus.png";
import LogoIcon from "./../assets/images/Participiclogo.png";
import UsersActiveIcon from "./../assets/images/UserManagementFocus.png";
import Users from "./../assets/images/Users.png";
import boxArrowDown from "./../assets/images/box-arrow-down.png";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.auth = Auth;
    this.state = {
      ToggleSideBar: true,
      ToggleSideBarNav: false,
      avaliableindex: null,
      RoleId: 0,
    };
  }

  onToggleButtonClick = () => {
    this.props.ToggleSideBar();
  };

  onSideBarToggle = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 767.98) {
      this.setState(
        {
          ToggleSideBarNav: !this.state.ToggleSideBarNav,
        },
        () => {
          localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
          if (this.state.ToggleSideBarNav) {
            document.body.classList.remove("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
          } else {
            document.body.classList.add("toggleClass");
            document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
          }
          this.setState({
            ToggleSideBarNav: !this.state.ToggleSideBarNav,
          });
        }
      );
    } else {
      localStorage.setItem("sidebar", this.state.ToggleSideBarNav);
      if (this.state.ToggleSideBarNav) {
        document.body.classList.remove("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
      } else {
        document.body.classList.add("toggleClass");
        document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
      }
      this.setState({
        ToggleSideBarNav: !this.state.ToggleSideBarNav,
      });
    }
  };

  onSideBarToggle1 = (ToggleSideBarNav) => {
    if (ToggleSideBarNav === 0) {
      localStorage.setItem("sidebar", "false");
    } else {
      localStorage.setItem("sidebar", "true");
    }
    if (ToggleSideBarNav) {
      document.body.classList.remove("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper";
    } else {
      document.body.classList.add("toggleClass");
      document.getElementsByClassName("page-body-wrapper")[0].className = "page-body-wrapper toggleNav";
    }
    this.setState({
      ToggleSideBarNav: !ToggleSideBarNav,
    });
  };
  componentDidMount() {
    let sidebar = localStorage.getItem("sidebar");
    if (sidebar === undefined || sidebar === null) {
      sidebar = false;
    }
    let windowWidth = window.innerWidth;
    if (sidebar === "false") {
      this.onSideBarToggle1(0);
    } else {
      if (windowWidth < 767.98) {
        this.onSideBarToggle1(0);
      } else {
        this.onSideBarToggle1(1);
      }
    }

    this.setState({
      avaliableindex: this.props.sidebarvalue,
    });
    const RoleId = CommonValues.GetRoleId();
    this.setState(
      {
        RoleId: RoleId,
      },
      () => {
        if (RoleId === null) {
          this.setState({ RoleId: 2 });
        }
      }
    );
  }

  render() {
    return (
      <>
        <div class="sidebar-wrapper">
          <div class="logo-wrapper">
            <a href="/dashboard" class="logo" title="Participic">
              <img src={Logo} width="" height="" alt="Participic" class="logo-desktop " />
              <img src={LogoIcon} class="logo-toggle  " width="" height="" alt="" />
            </a>
          </div>
          <div class="toggle-sidebar mobile-hide">
            <a class="toggle-icon" onClick={this.onSideBarToggle}>
              <span class="one"></span>
              <span class="two"></span>
              <span class="three"></span>
            </a>
          </div>

          <div class="sidebar-menu">
            <ul class="sidebar-links mt-1">
              <li className={this.state.avaliableindex === 1 ? "sidebar-list active" : "sidebar-list"}>
                <Link to="/dashboard" class="sidebar-link" title="Dashboard">
                  <img src={DashboardIcon} class="pr-2" width="" height="" alt="" /> <span>Dashboard</span>
                </Link>
              </li>

              <li className={this.state.avaliableindex === 2 ? "sidebar-list active" : "sidebar-list"}>
                <Link to="/UserEvent" class="sidebar-link" title="Event Management">
                  <img src={EventManagementIcon} class="pr-2" width="" height="" alt="" />
                  <span> Event Management</span>
                </Link>
              </li>

              {this.state.RoleId == 1 ? (
                <li className={this.state.avaliableindex === 3 ? "sidebar-list active" : "sidebar-list"}>
                  <Link to="/users" class="sidebar-link" title="User Management">
                    <img src={this.state.avaliableindex === 3 ? UsersActiveIcon : Users} class="pr-2" width="" height="" alt="" />
                    <span> User Management</span>
                  </Link>
                </li>
              ) : null}
              {/* <li
                className={
                  this.state.avaliableindex === 4
                    ? "sidebar-list active"
                    : "sidebar-list"
                }
              >
                <Link
                  to="/ChangePassword"
                  class="sidebar-link"
                  title="Change Password"
                >
                  <img
                    src={
                      this.state.avaliableindex === 4
                        ? ChangePasswordColored
                        : ChangePasswordWhite
                    }
                    class="pr-2"
                    width=""
                    height=""
                    alt=""
                  />
                  <span> Change Password</span>
                </Link>
              </li> */}

              <li className={this.state.avaliableindex === 5 ? "sidebar-list active" : "sidebar-list"}>
                <Link to="/MyProfile" class="sidebar-link" title="My Profile">
                  <img src={this.state.avaliableindex === 5 ? MyProfileActiveIcon : MyProfileIcon} class="pr-2" width="" height="" alt="" />
                  <span> My Profile</span>
                </Link>
              </li>
              <li class="sidebar-list">
                <Link to="/logout" className="sidebar-link logout-nav-bottom">
                  <img src={boxArrowDown} class="pr-2" width="" height="" alt="" />
                  <span> Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
