import "babel-polyfill";
import axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import CommonValues from "../src/Common/Util";
import App from "./App";
import "./index.css";
import "react-date-picker/dist/DatePicker.css";
import reportWebVitals from "./reportWebVitals";
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    if (config.url === `${process.env.REACT_APP_API}User/Authenticate`) {
      return config;
    } else if (
      config.url === `${process.env.REACT_APP_API}User/ForgotPassword`
    ) {
      return config;
    } else if (
      config.url === `${process.env.REACT_APP_API}User/UserRegistration`
    ) {
      return config;
    } else if (
      config.url === `${process.env.REACT_APP_EVENT_API}Event/GetCountries`
    ) {
      return config;
    } else if (
      config.url.includes(
        `${process.env.REACT_APP_EVENT_API}Event/GetStatesByCountryId?`
      )
    ) {
      return config;
    } else if (
      config.url.includes(`${process.env.REACT_APP_EVENT_API}Event/EventEnd?`)
    ) {
      return config;
    } else if (
      config.url.includes(
        `${process.env.REACT_APP_EVENT_API}Event/GetCitiesByStateId?`
      )
    ) {
      return config;
    } else if (
      config.url.includes(
        `${process.env.REACT_APP_EVENT_API}Event/GetAllEventsBySearch`
      )
    ) {
      return config;
    } else if (
      config.url.includes(
        `${process.env.REACT_APP_EVENT_API}Event/DeactivateEvent`
      )
    ) {
      return config;
    } else if (
      config.url === `${process.env.REACT_APP_API}User/ConfirmRegistration`
    ) {
      return config;
    } else if (config.url === `${process.env.REACT_APP_API}User/Dashboard`) {
      return config;
    } else if (
      config.url === `${process.env.REACT_APP_API}User/UpdatePassword`
    ) {
      return config;
    } else if (
      config.url ===
      `${process.env.REACT_APP_API}User/CheckGuidForForgotPassword`
    ) {
      return config;
    }
    // else if (
    //   config.url === `${process.env.REACT_APP_API}User/GetEventTopThree`
    // ) {
    //   return config;
    // } else if (
    //   config.url === `${process.env.REACT_APP_API}User/Auth0Authenticate`
    // ) {
    //   return config;
    // } else if (
    //   config.url ===
    //   `${process.env.REACT_APP_EVENT_API}Event/GetDashboardEvents`
    // ) {
    //   return config;
    // } else if (config.url === `${process.env.REACT_APP_API}User/Dashboard`) {
    //   return config;
    // } else if (
    //   config.url === `${process.env.REACT_APP_EVENT_API}Event/GetAllEvents`
    // ) {
    //   return config;
    // } else if (
    //   config.url ===
    //   `${
    //     process.env.REACT_APP_API
    //   }User/GetUserDetails?id=${CommonValues.GetUserId()}`
    // ) {
    //   return config;
    // } else if (
    //   config.url === `${process.env.REACT_APP_API}User/UpdateUserProfileDetails`
    // ) {
    //   return config;
    // } else if (
    //   config.url === `${process.env.REACT_APP_MEDIA_API}UploadProfileImage`
    // ) {
    //   return config;
    // } else if (
    //   config.url === `${process.env.REACT_APP_API}User/ChangePassword`
    // ) {
    //   return config;
    // }
    else {
      const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
