import React, { Component } from "react";
import participicimage from ".././assets/images/logo/Participiclogo.png";
import Footer from "./Footer";
import CommonValues from "./Util";
class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    if (token === null || token === "") {
      this.props.history.push("/");
    }
    document.body.classList.add("login-page");
    document.body.classList.remove("dashboard-page");
  };
  render() {
    const token = CommonValues.GetAuth0Token().replace(/^"|"$/g, "");
    let showLoginLink = false;
    if (token === null || token === "" || token !== "") {
      // showLoginLink = true;
      this.props.history.push("/login");
    }
    return (
      <div>
        <main class="main-content">
          <div class="login-form-wrapper">
            <div class="login-form-radius">
              <div class="login-form-content">
                <a href="/" title="Participic">
                  <img src={participicimage} width="320" height="51" alt="Participic" class="logo-fit participic-logo" />
                </a>

                <h2 class="text-navyblue text-16 font-semibold">Page Not Found</h2>
                {showLoginLink ? (
                  <a href="/" class="forgot-psw-link text-16 text-blue font-semibold" title="Sign In">
                    Sign In
                  </a>
                ) : (
                  <a href="/dashboard" class="forgot-psw-link text-16 text-blue font-semibold" title="Login">
                    Go to Dashboard
                  </a>
                )}
              </div>
            </div>
            <Footer></Footer>
          </div>
        </main>
      </div>
    );
  }
}

export default PageNotFound;
